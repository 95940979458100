import dateFormat from "dateformat";

const formatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const formatDate = (date: string | Date, format = "dS mmmm, yyyy") => {
  return dateFormat(date, format);
};

const removeTimeDataFromDate = (d: Date) => {
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
};

const debounce = () => {};

const convertToIntlCurSys = (number: number) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e7
    ? (Math.abs(Number(number)) / 1.0e7).toFixed(2) + "Cr"
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e5
    ? (Math.abs(Number(number)) / 1.0e5).toFixed(2) + "L"
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(number)) + "";
};

const daysDiffBetweenDates = (date1: Date, date2: Date) => {
  const diffTime = Math.abs(Number(date2) - Number(date1));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(String(text));
};

export {
  formatter,
  formatDate,
  convertToIntlCurSys,
  daysDiffBetweenDates,
  removeTimeDataFromDate,
  copyTextToClipboard,
};
