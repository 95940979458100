import { css } from "@emotion/css";
import React, { useState, useEffect } from "react";
import { useGlobal } from "../context/global";

import theme from "../util/theme";
import Iconify from "./Iconify";

interface Props {
  title: string;
  icon: string;
  description?: JSX.Element;

  customIcon?: any;
  addPaddingToChildren?: boolean;
  accordionClick?(): void;
  //   children: AccordionSubChildProp[] | [];
  children: React.ReactNode;
  titleColor?: string;
  isExpanded?: true | false;
  hideExpand?: true | false;
  handleUpdate?(key: string, value: string): void;
}

const Accordion = (props: Props) => {
  const { isMobile } = useGlobal();
  const [expanded, setExpanded] = useState(props.isExpanded || false);

  useEffect(() => {
    setExpanded(props?.isExpanded || false);
  }, [props.isExpanded]);

  return (
    <div
      style={{
        ...styles.container,
        backgroundColor: isMobile ? "transparent" : theme.color.primary_bg_2,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: 48,
          paddingLeft: 18,
          paddingRight: 18,
          alignItems: "center",
          backgroundColor: isMobile ? "transparent" : theme.color.primary_bg_2,
          //   background: 'red'
        }}
        onClick={() => {
          if (!props.hideExpand) {
            setExpanded(!expanded);
          }
          if (props.accordionClick) {
            props.accordionClick();
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {props.customIcon ? (
            props.customIcon
          ) : (
            <Iconify
              icon={props.icon}
              fontSize={18}
              color={theme.color.primary}
            />
          )}
          <span
            style={{
              ...styles.title,
              ...{
                paddingLeft: 15,
                color: props.titleColor || theme.color.primary,
              },
            }}
          >
            {props.title}
          </span>
        </div>
        {!props.hideExpand && (
          <Iconify
            icon={expanded ? "fa-solid:chevron-up" : "fa-solid:chevron-down"}
            fontSize={21}
            color={theme.color.primary}
          />
          //   <FontAwesomeIcon
          //     icon={expanded ? "chevron-down" : "chevron-right"}
          //     size={21}
          //     color={theme.color.primary}
          //   />
        )}
      </div>
      <div />

      {expanded && (
        <div
          className={
            "flex-col " +
            css`
              gap: 3px;
            `
          }
          style={props.addPaddingToChildren ? { padding: "0px 18px" } : {}}
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    // minHeight: "100%",
    backgroundColor: theme.color.primary_bg,
  },
  title: {
    fontSize: 16,
    // fontWeight:'bold',
    color: theme.color.primary,
    fontFamily: theme.font.medium,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 56,
    paddingLeft: 18,
    paddingRight: 18,
    alignItems: "center",
    backgroundColor: theme.color.primary_bg,
  },
  parentHr: {
    height: 1,
    // color: theme.color.white,
    width: "100%",
  },
  child: {
    backgroundColor: theme.color.light,
    padding: 16,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  text: {
    color: theme.color.dark,
    fontFamily: theme.font.medium,
  },
};

export default Accordion;
