import { css } from "@emotion/css";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobal } from "../context/global";
import theme from "../util/theme";
import Iconify from "./Iconify";

export const navigationItems = [
  {
    label: "Explore",
    path: "/explore",
    iconName: "ic:baseline-explore",
    icon: <Iconify icon={"ic:baseline-explore"} fontSize={30} />,
  },
  {
    label: "Investments",
    path: "/my_casets",
    iconName: "fa-solid:ticket-alt",
    icon: <Iconify icon={"fa-solid:ticket-alt"} />,
  },
  {
    label: "Wallet",
    path: "/wallet",
    iconName: "bxs:wallet",
    icon: <Iconify icon={"bxs:wallet"} />,
  },
  {
    label: "Profile",
    path: "/my_profile",
    iconName: "bxs:user",
    icon: <Iconify icon={"bxs:user"} />,
  },
];
const navItemContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const BottomBar = () => {
  const location = useLocation();
  const { isMobile } = useGlobal();
  const navigate = useNavigate();
  if (!isMobile) return <div />;

  return (
    <div
      id={"bottom_nav"}
      className={css`
        position: fixed;
        background-color: ${theme.color.primary_bg_2};
        width: 100%;
        bottom: 0;
        z-index: 100;
        height: 60px;
        display: flex;
        padding: 2px 10px;
        justify-content: center;
        backdrop-filter: blur(10px);
      `}
    >
      <div
        className={css`
          background-color: ${theme.color.primary_bg_2};
          width: 100%;
          z-index: 10;
          height: 60px;
          display: flex;
          padding: 0px 10px;
          justify-content: space-around;
        `}
      >
        {navigationItems.map((item, i) => {
          const isCurrentScreen = location.pathname.match(item.path);
          return (
            <div
              className={navItemContainer}
              onClick={() => {
                navigate(item.path);
              }}
              style={{
                color: isCurrentScreen ? theme.color.primary : theme.color.grey,
              }}
            >
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                <Iconify
                  icon={item.iconName}
                  color={
                    isCurrentScreen ? theme.color.primary : theme.color.grey
                  }
                  fontSize={22}
                />
              </div>
              <div
                style={{
                  fontSize: 13,
                  paddingBottom: 4,
                  color: isCurrentScreen
                    ? theme.color.primary
                    : theme.color.grey,
                }}
                className="medium"
              >
                {item.label}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BottomBar;
