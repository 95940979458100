import React, { useState, useEffect } from "react";
import Iconify from "../../components/Iconify";
import Loader from "../../components/Loader";
import { useAuth } from "../../context/auth";
import { UserVpa } from "../../interfaces/user";
import { UserWallet } from "../../interfaces/wallet";
import { formatter } from "../../util/helper";
import theme from "../../util/theme";
import { useNavigate, useLocation } from "react-router-dom";

const ProceedToConfirmVPA = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { amount, quantity } = location.state as {
    amount: number;
    quantity: number;
  };

  const { authRequest, authData } = useAuth();
  const user = authData?.user;
  //   const { width, height } = useWindowDimensions();

  const [showBankTransferModal, setShowBankTransferModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [walletData, setWalletData] = useState<UserWallet | null>(null);
  const [vpaData, setVpaData] = useState({
    bankAccount: "",
    ifsc: "",
    upiAddress: "",
  });

  useEffect(() => {
    // getWalletData();
    getUserVPA();
  }, []);

  if (!user) {
    return (
      <div>
        <div style={styles.container} />
      </div>
    );
  }

  const getUserVPA = () => {
    setIsLoading(true);
    return authRequest("user/vpa/cashfree", "GET", {})
      .then((data: UserVpa) => {
        setIsLoading(false);
        setVpaData(data);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  return (
    <div style={{ padding: "0px 20px" }}>
      <Loader loading={isLoading} />

      <div style={styles.container}>
        <div className={`flex-col`} style={{ paddingTop: 20 }}>
          <div
            onClick={() => {
              //   goBack(props.navigation);
              navigate(-1);
            }}
            className={`flex-col`}
            style={{
              // paddingRight: 20,
              // paddingLeft: 2,
              // backgroundColor: 'red',
              height: 30,
              width: 30,
              borderRadius: 15,
              alignContent: "center",
              justifyContent: "center",
              // alignItems: 'center',
            }}
          >
            <Iconify
              icon={"akar-icons:chevron-left"}
              color={theme.color.primary}
              fontSize={24}
            />
          </div>
          <span
          // style={[
          //   GLOBAL_STYLES.bold,
          //   GLOBAL_STYLES.heading,
          //   GLOBAL_STYLES.fontWhite,
          // ]}
          >
            {/* Confirm */}
          </span>
          <span
            className="bold white"
            style={{ ...styles.paddingTop, ...styles.text }}
          >
            Depositing{" "}
            <span style={{ color: theme.color.primary }}>
              {formatter.format(amount)}
            </span>
          </span>

          <span
            className="bold white"
            style={{
              ...styles.paddingTop,
              ...styles.text,
              ...{
                fontSize: 18,
                marginBottom: 20,
              },
            }}
          >
            We do not support UPI , cards, or netbanking for payments greater
            than 25,000. Click below to initiate NEFT, RTGS, or IMPS transaction
          </span>
          <div
            onClick={() => {
              //   vaapasNavigation(
              //     props.navigation,
              //     SCREEN_MAP.CONFIRM_VPA,
              //     props.route.params
              //   );
              navigate("/confirm_vpa", { state: location.state });
            }}
            className={"flex center"}
            style={{
              backgroundColor: theme.color.primary,
              padding: 10,
              borderRadius: 8,
            }}
          >
            <span
              className="bold white"
              style={
                // styles.paddingTop,
                // styles.text,
                {
                  fontSize: 16,
                  color: "black",
                }
              }
            >
              {" "}
              Proceed
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.color.primary_bg_2,
    paddingHorizontal: 20,
  },
  paddingTop: {
    paddingTop: 10,
  },
  text: {
    fontSize: 16,
  },
  sectionContainer: {
    paddingVertical: 20,
  },
  section: {
    backgroundColor: theme.color.primary_bg || "white",
    padding: 5,
    paddingHorizontal: 15,
    marginVertical: 10,
    paddingVertical: 15,
    borderRadius: 5,
  },
  helperText: {
    // color: theme.color.grey,
    color: "#aaa",
    // fontFamily: 16
    fontSize: 14,
  },
  sectionTitle: {
    fontSize: 20,
  },
};

export default ProceedToConfirmVPA;
