import Logo from "../components/Logo";
import { css } from "@emotion/css";
import theme from "../util/theme";
import { useAuth } from "../context/auth";
import { useEffect, useState } from "react";
import { mobileBreakPoint, useGlobal } from "../context/global";
import { useNavigate } from "react-router-dom";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import { screenHeight } from "../layout/BaseLayout";

export const rootStyle = css`
  width: 100%;
  /* min-height: 600px; */
  /* flex-grow: 1; */
  background: ${theme.color.primary_bg_2};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: ${mobileBreakPoint}px) {
    max-height: ${screenHeight};
    overflow-y: hidden;
    position: fixed;
    /* position: fixed; */
    /* min-height: 100%; */
  }
`;

const topSection = css`
  /* min-height: 300px; */
`;

const Login = () => {
  const [phoneNo, setPhoneNo] = useState("");
  const { showToast, isMobile } = useGlobal();
  const navigate = useNavigate();
  const { request } = useAuth();
  const [requestFetching, setRequestFetching] = useState(false);

  useEffect(() => {
    if (phoneNo.length === 10) {
    }
  }, [phoneNo]);

  const handleSendOTP = () => {
    const phoneRegex = /^[0-9]{10}$/;
    if (phoneNo.match(phoneRegex)) {
      setRequestFetching(true);
      request("auth/sendOTP", "POST", { phoneNumber: phoneNo })
        .then(() => {
          setRequestFetching(false);
          navigate("/confirmOtp", { state: { phoneNo } });
          // vaapasNavigation(props.navigation, SCREEN_MAP.CONFIRM_OTP, {
          //   phone_number: phoneNo,
          // });
        })
        .catch((_err: any) => {
          setRequestFetching(false);
          showToast("Check your mobile number and try again!");
        });
    } else {
      showToast("Phone number should be numeric and 10 digits");
    }
  };

  return (
    <div
      className={rootStyle}
    >
      <Loader loading={requestFetching} />
      {/* Login top section */}
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 48px;
          max-width: 100%;
          /* height: 100%; */
          flex-grow: ${isMobile ? 0 : 0};
        `}
      >
        {isMobile ? (
          <div
            className={
              " " +
              css`
                display: flex;
                flex-direction: row;
                justify-content: center;
              `
            }
          >
            {<Logo width={250} height={250} />}
          </div>
        ) : (
          ""
        )}
        <div></div>
      </div>
      {/* Login bottom section */}
      <div className={`flex-col center ${isMobile ? "" : "grow"}`}>
        {isMobile ? null : (
          <div
            className={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
            `}
          >
            {<Logo width={250} height={250} />}
          </div>
        )}
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-self: center;
            width: ${isMobile ? "100vw" : "500px"};
            min-height: 220px;
            justify-content: space-evenly;
            background-color: ${isMobile
              ? theme.color.primary_bg_2
              : theme.color.primary_bg_2};
            padding: 0 24px;
          `}
        >
          <div className="bold" style={{ fontSize: 20 }}>
            Login
          </div>
          <CustomInput
            value={phoneNo}
            onEnter={handleSendOTP}
            onChange={(text) => {
              if (!text.match(/^[0-9]{0,10}$/)) return;

              setPhoneNo(text);
            }}
            placeholder={"Enter 10 digit mobile number"}
            startAdornment={
              <span
                className="bold"
                style={{
                  color: "black",
                  alignSelf: "center",
                  fontSize: 14,
                  paddingLeft: 10,
                }}
              >
                +91
              </span>
            }
          />
          <CustomButton text="Next" onClick={handleSendOTP} />
          {isMobile ? (
            <div>
              <a
                href="https://caset.notion.site/Privacy-Policy-d96f5298e92e4f219c6d9518fe9ad3ae"
                target={"_blank"}
                rel="noreferrer"
                className={`bold ${css`
                  text-decoration: none;
                  color: white;
                  /* font-weight: bold; */
                  font-size: 0.85rem;
                `}`}
              >
                Privacy policy{" "}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Login;
