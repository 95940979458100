import { css } from "@emotion/css";
import React, { useEffect, useState } from "react";
import CustomInput from "./CustomInput";
import { useCallback } from "react";
import theme from "../util/theme";
import { formatter } from "../util/helper";

const card = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  padding: 10px;
  background: ${theme.color.primary_bg_2};
  border: 2px solid ${theme.color.primary};
  margin: 10px 0px;

  /* & * {
    color: white;
  } */
`;

const inputTitle =
  "bold " +
  css`
    /* color: ${theme.color.primary}; */
    font-size: 14px;
    margin-bottom: 4px;
  `;
const detailRow = css`
  display: flex;
  font-size: 14px;
  & .key {
  }
  & .value {
    color: ${theme.color.primary};
  }
`;

interface Props {
  noOfCasets?: number;
  unitPercentReturns: number;
  availableCasets: number;
  unitPrice: number;
  validity: number;
  onNumberOfCasetsChange?: (noOfCasets: number) => void;
  casetLiveDate: string;
  setPretaxReturns?: () => void;
  cagr: number;
}

const ReturnsCalculator = (props: Props) => {
  const [noOfCasets, setNoOfCasets] = useState(props.noOfCasets || 1);
  const [casetValidity, setCasetValidity] = useState(
    Number(props.validity.toFixed())
  );
  const [salesUpsidePercent, setSalesUpsidePercent] = useState(0);
  const [salesAmount, setSalesAmount] = useState(0);
  const [maxProfitPerCaset, setMaxProfitPerCaset] = useState(0);

  const [forecastedReturn, setForecastedReturn] = useState(0);

  const [returns, setReturns] = useState(0);

  useEffect(() => {
    if (props.cagr && props.validity) {
      const { cagr, validity, unitPrice, unitPercentReturns } = props;
      const expectedPercentInc = (cagr / 12) * validity;
      setSalesUpsidePercent(expectedPercentInc);

      const currentSales = Number(
        ((unitPrice * 100) / unitPercentReturns).toFixed(2)
      );
      const expectedSales =
        currentSales + (currentSales / 100) * expectedPercentInc;
      setSalesAmount(Number(expectedSales.toFixed(2)));
    }
  }, [props.cagr, props.validity, props.unitPrice]);

  useEffect(() => {
    if (!noOfCasets || !salesAmount) {
      return setReturns(0);
    }
    const profit = Number(
      (salesAmount / 100) * (noOfCasets * props.unitPercentReturns)
    );

    const amount = noOfCasets * props.unitPrice;
    const maxProfit = amount + (amount / 100) * (props.validity * 4);

    if (noOfCasets === 1) setMaxProfitPerCaset(maxProfit);
    if (maxProfit > profit) {
      return setReturns(profit);
    }
    setReturns(maxProfit);
  }, [noOfCasets, props.unitPercentReturns, salesAmount]);

  const handleNoOfCasetsChange = useCallback((text: string) => {
    if (isNaN(Number(text))) return;
    const number = Number(text);
    if (number > -1 && number <= props.availableCasets) {
      if (props.onNumberOfCasetsChange) {
        props.onNumberOfCasetsChange(number || 0);
      }
      setNoOfCasets(number || 0);
    }
  }, []);

  const handleSalesPercentChange = useCallback((text: string) => {
    if (isNaN(Number(text))) return;
    const number = Number(text);
    if (number > -1) setSalesUpsidePercent(number || 0);
  }, []);

  const handleSalesAmountChange = useCallback((text: string) => {
    if (isNaN(Number(text))) return;
    const number = Number(text);
    if (number > -1) setSalesAmount(number || 0);
  }, []);

  return (
    <div className={card}>
      <div
        className={css`
          font-size: 20px;
          /* color: ${theme.color.primary}; */
        `}
      >
        I want to invest
      </div>
      <div>
        <div className={inputTitle}>Amount</div>
        <CustomInput
          onChange={handleNoOfCasetsChange}
          background={"transparent"}
          borderColor={"black"}
          inputStyle={{
            background: theme.color.inputBackground,
            color: "black",
            borderRadius: 4,
          }}
          value={String(noOfCasets === 0 ? "" : noOfCasets)}
          placeholder={"Enter Number of Casets"}
        />
      </div>
      {/* <div>
        <div className={inputTitle}>
          Expected Sales for {props.validity} months
        </div>

        <CustomInput
          onChange={handleSalesAmountChange}
          background={"transparent"}
          borderColor={"black"}
          inputStyle={{
            background: theme.color.inputBackground,
            color: "black",
            borderRadius: 4,
          }}
          value={String(salesAmount === 0 ? "" : salesAmount)}
          placeholder={`Enter Sales Amount`}
        />
      </div> */}
      {/* Return outputs */}

      <div className={detailRow}>
        <div className="key">Available Casets</div>
        <div className="grow" />
        <div className="value">{props.availableCasets || 0}</div>
      </div>

      <div className={detailRow}>
        <div className="key">Yield upto</div>
        <div className="grow" />
        <div className="value">{"21.1%"}</div>
      </div>

      <div className={detailRow}>
        <div className="key">Live Date</div>
        <div className="grow" />
        <div className="value">{props.casetLiveDate}</div>
      </div>

      <div className={detailRow}>
        <div className="key">Tenure</div>
        <div className="grow" />
        <div className="value">{props.validity || 1} month(s)</div>
      </div>

      {/* <div className={detailRow}>
        <div className="key">Invested Amount</div>
        <div className="grow" />
        <div className="value">
          {formatter.format(noOfCasets * props.unitPrice)}
        </div>
      </div> */}

      {/* <div className={detailRow}>
        <div className="key">Max returns</div>
        <div className="grow" />
        <div className="value">
          {formatter.format(maxProfitPerCaset * noOfCasets)}
        </div>
      </div> */}

      {/* <div className={detailRow}>
        <div className="key">Pre Tax returns</div>
        <div className="grow" />
        <div className="value">{formatter.format(returns)}</div>
      </div> */}
    </div>
  );
};

export default ReturnsCalculator;
