const color = {
  primary: "#F47322",
  // primary: "#00df9a",
  primary_bg: "#000000", // Primary Background
  primary_bg_3: "#0C111B",
  primary_bg_2: "transparent",
  secondary_bg: "#312F2F",
  dark: "black",
  light: "#FFFFFF",
  inputBackground: "#EBEBEB",
  grey: "#6E7075",
  error: "#FF4C4C",
  bg_4: "#1C1C1C",
};

const font = {
  bold: "DMSans-Bold",
  bold_italic: "DMSans-BoldItalic",
  italic: "DMSans-Italic",
  medium: "DMSans-Medium",
  medium_italic: "DMSans-MediumItalic",
  regular: "DMSans-Regular",
  gochiHand: "GochiHand-Regular",
};

const fontSize = {
  heading: 24,

  subHeading: 18,
};
const size = {
  _r: 8,
  _r2: 16,
  _r3: 24,
};

const theme = {
  color,
  font,
  fontSize,
  size,
};

export default theme;
