import { css } from "@emotion/css";
import { CSSProperties } from "react";

interface Props {
  startAdornment?: JSX.Element;
  onChange: (text: string) => void;
  onEnter?: () => void;
  placeholder?: string;
  background?: string;
  endAdornment?: JSX.Element;
  value: string;
  inputStyle?: CSSProperties;
  borderColor?: string;
}

export const inputStyle = `flex bold ${css`
  background-color: white;
  min-height: 48px;
  padding: 5px 10px;
  justify-content: center;
  border-radius: 4px;
`}`;

const CustomInput = (props: Props) => {
  const { startAdornment, endAdornment } = props;
  return (
    <div
      className={`flex bold ${css`
        background-color: ${props.background || "white"};
        // min-height: 48px;

        // justify-content: center;
        border-radius: 4px;
        border: 1px solid ${props.borderColor || "white"};
      `}`}
    >
      {startAdornment ? startAdornment : null}
      <input
        type={"text"}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            if (props.onEnter) {
              props.onEnter();
            }
          }
        }}
        style={props.inputStyle || undefined}
        value={props.value}
        className={`bold ${css`
          min-height: 48px;
          background-color: transparent;
          border-color: transparent;
          padding: 0 20px;
          padding-left: 10px;
          flex-grow: 1;
          font-size: 14.5px;

          &:focus {
            outline: none;
          }
        `}`}
        placeholder={props.placeholder || ""}
      />
      {endAdornment ? endAdornment : null}
    </div>
  );
};

export default CustomInput;
