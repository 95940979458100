import React, { createContext, useContext, useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { User } from "../interfaces/user";
// import openMap, {ShowOptions} from 'react-native-open-maps';
import { getStoredData } from "../util/storage";
// import {Alert, Linking, Platform, ToastAndroid} from 'react-native';
// import { userDataProp } from "./userData";
// import Toast from 'react-native-root-toast';
// import Spinner from '../../components/Spinner';

interface gState {
  user: User;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  [key: string]: object | undefined;
}

interface globalContext {
  globalState: gState;
  updateGlobalState(key: string, value: any): void;
  showToast(message: string): void;
  isMobile: Boolean;
  //   showToast(message: string): void;
}

const GlobalContext = createContext<globalContext>({} as globalContext);

interface Props {
  children: React.ReactNode;
}

export const mobileBreakPoint = 425;

function isMobileWidth() {
  const width = document.body.clientWidth;
  return width <= mobileBreakPoint;
}

const GlobalProvider = ({ children }: Props) => {
  const [globalState, setGlobalState] = useState<gState>({} as gState);
  const [isMobile, setIsMobile] = useState(isMobileWidth());

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(isMobileWidth());
    });
  }, []);

  /**
   * Function to update global state
   * @param key string
   * @param value any
   */
  const updateGlobalState = (key: keyof gState, value: any) => {
    let currenState: gState = { ...(globalState || {}) };
    currenState[key] = value;
    setGlobalState(currenState);
  };

  const showToast = (message: string) => {
    // if (Platform.OS === 'android') {
    //   ToastAndroid.show(message, ToastAndroid.SHORT);
    // } else {
    //   Alert.alert(message);
    // }
    toast.success(message);
  };

  return (
    <GlobalContext.Provider
      value={{
        globalState,
        updateGlobalState,
        showToast,
        isMobile,
      }}
    >
      {/* <Toaster /> */}
      {/* <Spinner animating={Boolean(requestsFetching)} /> */}
      {children}
    </GlobalContext.Provider>
  );
};

function useGlobal(): globalContext {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error("useGlobal must be used within an GlobalProvider");
  }

  return context;
}

export { GlobalProvider, GlobalContext, useGlobal };
