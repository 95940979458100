import React, { useState, useEffect } from "react";
import Iconify from "../../components/Iconify";
import Loader from "../../components/Loader";
import { useAuth } from "../../context/auth";
import { UserVpa } from "../../interfaces/user";
import { UserWallet } from "../../interfaces/wallet";
import { formatter } from "../../util/helper";
import theme from "../../util/theme";
import { useNavigate, useLocation } from "react-router-dom";

const ConfirmVPA = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { amount, quantity, casetId } = location.state as {
    amount: number;
    quantity: number;
    casetId: string;
  };

  const { authRequest, authData } = useAuth();
  const user = authData?.user;
  //   const { width, height } = useWindowDimensions();

  const [showBankTransferModal, setShowBankTransferModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [walletData, setWalletData] = useState<UserWallet | null>(null);
  const [vpaData, setVpaData] = useState({
    bankAccount: "",
    ifsc: "",
    upiAddress: "",
  });

  useEffect(() => {
    // getWalletData();
    getUserVPA();
  }, []);

  if (!user) {
    return (
      <div>
        <div style={styles.container} />
      </div>
    );
  }

  const getUserVPA = () => {
    setIsLoading(true);
    return authRequest("user/vpa/cashfree", "GET", {})
      .then((data: UserVpa) => {
        setIsLoading(false);
        setVpaData(data);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  return (
    <div style={{ padding: "0px 20px" }}>
      <Loader loading={isLoading} />

      <div style={styles.container}>
        <div className={`flex-col`} style={{ paddingTop: 20 }}>
          <div
            onClick={() => {
              //   goBack(props.navigation);
              navigate(-1);
            }}
            className={`flex-col`}
            style={{
              // paddingRight: 20,
              // paddingLeft: 2,
              // backgroundColor: 'red',
              height: 30,
              width: 30,
              borderRadius: 15,
              alignContent: "center",
              justifyContent: "center",
              // alignItems: 'center',
            }}
          >
            <Iconify
              icon={"akar-icons:chevron-left"}
              color={theme.color.primary}
              fontSize={24}
            />
          </div>
          <span
          // style={[
          //   GLOBAL_STYLES.bold,
          //   GLOBAL_STYLES.heading,
          //   GLOBAL_STYLES.fontWhite,
          // ]}
          >
            {/* Confirm */}
          </span>
          <span
            className="bold white"
            style={{ ...styles.paddingTop, ...styles.text }}
          >
            Depositing{" "}
            <span style={{ color: theme.color.primary }}>
              {formatter.format(amount)}
            </span>
          </span>

          {/* Bank Transfer */}
          <div style={styles.sectionContainer}>
            <span className="white medium" style={styles.helperText}>
              Recommended Method
            </span>
            <div
              onClick={() => {
                // navigate('')
                // if (checkPaymentDetails()) {
                // }
                // setShowBankTransferModal(true);
                navigate("/vpa_details", {
                  state: {
                    ...vpaData,
                    casetId,
                    quantity,
                    amount,
                  },
                });
              }}
              //   activeOpacity={0.8}
              className={"flex"}
              style={styles.section}
            >
              <div className="flex-col grow" style={{ gap: 5 }}>
                <span className="bold white" style={styles.text}>
                  Bank Transfer
                </span>
                <div
                  className="flex-col grow"
                  style={{ marginTop: 10, marginBottom: 0 }}
                >
                  <span className="medium " style={styles.helperText}>
                    <div>Time: Instant | Fees: ₹0{"\n"}</div>
                    <div>Minimum ₹100 | Maximum ₹50,00,000{"\n"}</div>
                  </span>
                </div>
                <div
                  className="flex"
                  style={{ width: 200, justifyContent: "space-between" }}
                >
                  <div className="flex">
                    <Iconify
                      icon={"ci:circle-check"}
                      color={theme.color.primary}
                    />
                    <span
                      className="medium"
                      style={{ ...styles.helperText, ...{ paddingLeft: 5 } }}
                    >
                      NEFT
                    </span>
                  </div>
                  <div className="flex">
                    <Iconify
                      icon={"ci:circle-check"}
                      color={theme.color.primary}
                    />
                    <span
                      className="medium"
                      style={{ ...styles.helperText, ...{ paddingLeft: 5 } }}
                    >
                      RTGS
                    </span>
                  </div>
                  <div className="flex">
                    <Iconify
                      icon={"ci:circle-check"}
                      color={theme.color.primary}
                    />
                    <span
                      className="medium"
                      style={{ ...styles.helperText, ...{ paddingLeft: 5 } }}
                    >
                      IMPS
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex center" style={{ width: 60 }}>
                <Iconify
                  icon={"akar-icons:chevron-right"}
                  color={theme.color.primary}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.color.primary_bg_2,
    paddingHorizontal: 20,
  },
  paddingTop: {
    paddingTop: 10,
  },
  text: {
    fontSize: 16,
  },
  sectionContainer: {
    // paddingVertical: 20,
    padding: "20px 0px",
  },
  section: {
    backgroundColor: "#000000E0" || theme.color.primary_bg || "white",
    // padding: 5,
    // paddingHorizontal: 15,
    marginVertical: 10,
    margin: "10px 0px",
    // paddingVertical: 15,
    padding: "15px 15px",
    borderRadius: 5,
  },
  helperText: {
    // color: theme.color.grey,
    color: "#aaa",
    // fontFamily: 16
    fontSize: 14,
  },
  sectionTitle: {
    fontSize: 20,
  },
};

export default ConfirmVPA;
