import { memo } from "react";
import {
  Caset,
  CasetWithUserCasetInfo,
  UserCaset,
} from "../../interfaces/caset";
import { formatter } from "../../util/helper";
import { useNavigate } from "react-router-dom";

interface Props {
  item: CasetWithUserCasetInfo;
}

const CasetHistoryItem = ({ item }: Props) => {
  const navigate = useNavigate();
  if (!item.businessBranchId) {
    return <div style={{ backgroundColor: "transparent" }} />;
  }
  const misc = item.businessBranch.business.misc || {};
  const accentColor = item.businessBranch.business.accentColor;
  const outerContainerWidth = 270;
  var totalUnitsBought = 0;
  item.userCasets.forEach((u) => {
    totalUnitsBought += u.casetQuantity;
  });
  return (
    <div
      className="flex-col center"
      onClick={() => {
        navigate(`/my_casets/${item.id}`, { state: item });
      }}
      style={{
        // width: 165,
        // height: 220
        // scale: 0.9,
        cursor: "pointer",
      }}
    >
      <div
        onClick={() => {
          //   navigate(`/explore/caset/${item.id}`, { state: item });
          //   vaapasNavigation(navigation, SCREEN_MAP.EXPLORE_CASET, item);
        }}
        className={"flex-col center"}
        style={{
          width: 165,
          height: 220,
          backgroundColor: "white",
          //   marginHorizontal: 4,
          margin: "0px 4px",
          marginBottom: 25,
          padding: 12,
          borderRadius: 20,
        }}
      >
        <div
          className="flex-col center"
          style={{
            // width: '100%',
            width: 148,
            height: 148,
            backgroundColor: misc.brandImage ? "transparent" : accentColor,
            borderRadius: 20,
          }}
        >
          {misc.brandImage ? (
            <img src={misc.brandImage} width={148} height={148} alt="Brand" />
          ) : (
            // <Image
            //   source={{
            //     uri: misc.brandImage,
            //   }}
            //   style={[{ height: 230, width: 230 }]}
            //   // resizeMethod={'resize'}
            //   resizeMode={"contain"}
            // />
            <>
              {/* <Image source={STATIC_IMAGES.COIN} style={[{ height: 170 }]} /> */}
              <div className="bold" style={{ fontSize: 12.5 }}>
                {item.businessBranch.business.name}
              </div>
              <div className="regular" style={{ fontSize: 8.8 }}>
                {item.businessBranch.subLocation}
              </div>
            </>
          )}
        </div>
        <div
          className="flex-col grow"
          style={{
            width: "100%",
            height: 55,
            justifyContent: "space-around",
            padding: "0px 5px",
            // backgroundColor: 'red',
            // transform: [{scale: }],
          }}
        >
          <div className="medium" style={{ fontSize: 10 }}>
            {(item.unitPercentReturns * totalUnitsBought).toFixed(2)}% royalty
            on every sale
          </div>
          <div className="flex-row">
            <div
              className="medium"
              style={{ fontSize: 8.8, alignSelf: "center", paddingRight: 3 }}
            >
              Tenure:{" "}
            </div>
            <div
              style={{
                borderRadius: 9,
                borderColor: accentColor,
                borderWidth: 2,
                backgroundColor: misc.backgroundColor || "black",
                //   paddingHorizontal: 5,
                padding: "0px 5px",
              }}
            >
              <div
                className="medium"
                style={{ fontSize: 8.8, color: accentColor }}
              >
                {item.validity} month
              </div>
            </div>
          </div>
          <div className="bold" style={{ fontSize: 12 }}>
            {formatter.format(totalUnitsBought * item.unitPrice)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CasetHistoryItem);
