import { Navigate, useRoutes } from "react-router-dom";
import { useAuth } from "./context/auth";
import BaseLayout from "./layout/BaseLayout";
import AddPaymentDetails from "./pages/AddPaymentDetails";
import ConfirmOTP from "./pages/ConfirmOTP";
import EmailVerification from "./pages/EmailVerirification";
import Explore from "./pages/Home/Explore";
import ExploreCaset from "./pages/Home/ExploreCaset";
import History from "./pages/Home/History";
import HistoryCaset from "./pages/Home/HistoryCaset";
import Profile from "./pages/Home/Profile";
import Wallet from "./pages/Home/Wallet";
import KYCUpdateScreen from "./pages/KYC";
import Login from "./pages/Login";
import ConfirmVPA from "./pages/Misc/ConfrimVPA";
import ProceedToConfirmVPA from "./pages/Misc/ProceedToConfirmVPA";
import VPADetails from "./pages/Misc/VPADetails";
import OrdersPage from "./pages/OrdersPage";
import AccountInformation from "./pages/Profile/AccountInformtaion";
import ContactUs from "./pages/Profile/Support";
import PersonalInformation from "./pages/Profile/PersonalInformation";
import Support from "./pages/Profile/Support";

const Routes = () => {
  const { isLoggedIn, authData } = useAuth();

  const user = authData?.user;

  const protectRoute = (component: JSX.Element) => {
    if (isLoggedIn && user) {
      if (!user?.name && !user?.email) {
        return <Navigate to="/email_verification" />;
      }
    }
    return isLoggedIn || isLoggedIn === null ? component : <Navigate to="/" />;
  };

  const skipIfLoggedIn = (component: JSX.Element) => {
    // if (isLoggedIn && user) {
    //   if ((user && !user?.emailVerified) || !user?.name) {
    //     return <Navigate to="/email_verification" />;
    //   }
    // }
    return !(isLoggedIn && isLoggedIn !== null) ? (
      component
    ) : (
      <Navigate to="/explore" />
    );
  };

  // if (isLoggedIn)
  return useRoutes([
    {
      path: "/",
      element: <BaseLayout />,
      children: [
        {
          path: "",
          element: skipIfLoggedIn(<Login />),
        },
        {
          path: "confirmOTP",
          element: skipIfLoggedIn(<ConfirmOTP />),
        },
        {
          path: "/email_verification",
          element: <EmailVerification />,
        },
      ],
    },
    {
      path: "/",
      element: protectRoute(<BaseLayout />),
      children: [
        {
          path: "/explore",
          element: <Explore />,
        },
        {
          path: "/my_casets",
          element: <History />,
        },
        {
          path: "/explore/caset/:id",
          element: <ExploreCaset />,
        },
        {
          path: "/my_casets/:id",
          element: <HistoryCaset />,
        },
        {
          path: "wallet",
          element: <Wallet />,
        },
        {
          path: "/my_profile",
          element: <Profile />,
        },
        {
          path: "/kyc",
          element: <KYCUpdateScreen />,
        },
        {
          path: "/add_payment_details",
          element: <AddPaymentDetails />,
        },
        {
          path: "/orders",
          element: <OrdersPage />,
        },
        {
          path: "/proceed_to_confirm",
          element: <ProceedToConfirmVPA />,
        },
        {
          path: "/confirm_vpa",
          element: <ConfirmVPA />,
        },
        {
          path: "/vpa_details",
          element: <VPADetails />,
        },
        {
          path: "/personal_information",
          element: <PersonalInformation />,
        },
        {
          path: "/account_information",
          element: <AccountInformation />,
        },
        {
          path: "/support",
          element: <Support />,
        },
      ],
    },
  ]);
};

export default Routes;
