import { css } from "@emotion/css";
import React from "react";
import { useGlobal } from "../context/global";
import theme from "../util/theme";

const RiskFactors = () => {
  const { isMobile } = useGlobal();
  const desc = "";
  const points = [
    "All information provided in this document is based on publicly available information and disclosures made by the management of Sanjos Donuts",
    "Delay in repayment by the businesses beyond the stipulated credit cycle might cause a lag in timely payments ",
    "Such investment does not guarantee that an investor will make money, avoid losing capital, or indicate that the investment is risk-free",
  ];
  const images: string[] = [];
  return (
    <div
      id="risk_factors"
      className={css`
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        & .heading {
          font-size: 20px;
          /* color: ${theme.color.primary}; */
          color: ${isMobile ? theme.color.primary : "unset"};
        }
        & .point {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          color: #cbcbcb;
          line-height: 24px;
        }

        &.active .heading {
          color: ${theme.color.primary};
        }
      `}
    >
      <div className="heading">Risk factors</div>
      <div className="divider" style={{ transform: "scale(1)" }} />
      {desc ? <div className="desc">{desc}</div> : null}
      <ul className="point">
        {points.map((p) => {
          return <li className="regular">{p}</li>;
        })}
      </ul>
      <div
        className={css`
          display: flex;
          gap: 1rem;
          /* padding: 1rem; */
          justify-content: flex-start;
          flex-wrap: wrap;
        `}
      >
        {images.map((i, index) => {
          return <img src={i} alt={index + ""} width={200} />;
        })}
      </div>
    </div>
  );
};

export default RiskFactors;
