import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import BottomBar from "../../components/BottomBar";
import Loader from "../../components/Loader";
import Logo from "../../components/Logo";
import { useAuth } from "../../context/auth";
import { Caset, CasetWithUserCasetInfo } from "../../interfaces/caset";
import theme from "../../util/theme";
import CasetItem from "./CasetItem";
import { useNavigate } from "react-router-dom";
import CasetHistoryItem from "./CasetHistoryItem";
import { screenHeight } from "../../layout/BaseLayout";
import { useGlobal } from "../../context/global";
import Iconify from "../../components/Iconify";

const History = () => {
  const navigate = useNavigate();
  const { authRequest } = useAuth();
  const { isMobile } = useGlobal();
  const [numCols, setNumCols] = useState(2);
  const [userCasets, setUserCasets] = useState<CasetWithUserCasetInfo[] | null>(
    null
  );
  const [isRefreshing, setIsRefreshing] = useState(false);
  useEffect(() => {
    setIsRefreshing(true);
    authRequest("user/caset", "GET", {})
      .then((data: CasetWithUserCasetInfo[]) => {
        setIsRefreshing(false);
        setUserCasets(data);
      })
      .catch((_err: any) => {
        setIsRefreshing(false);
      });
  }, []);

  //   useEffect(() => {
  //     setNumCols(Number((width / ((171 + 10) * 2)).toFixed()));
  //   }, [numCols]);

  return (
    <>
      <Loader loading={isRefreshing} />
      <BottomBar />

      <div
        style={{
          // maxHeight: "100vh",
          // height: "100vh",
          position: "relative",
          paddingBottom: 60,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "2rem",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          height: isMobile ? screenHeight : "100%",
          overflowY: isMobile ? "scroll" : "unset",
        }}
      >
        {/* List of casets */}
        <div
          className={css`
            /* max-height: 300px; */
            overflow-y: ${isMobile ? "scroll" : "unset"};
            width: 100%;
            height: unset;
            /* height: 450px; */
            /* transform: scale(0.6); */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1rem;
            justify-content: ${(userCasets || []).length !== 1
              ? "center"
              : "flex-start"};
            /* padding-bottom: 20px; */
            /* padding: 0px 20px; */
          `}
        >
          {(userCasets || []).length === 0 && userCasets !== null ? (
            <div
              className={
                css`
                  display: flex;
                  flex-direction: column;
                  min-height: 200px;
                  justify-content: center;
                  align-items: center;
                  color: white;
                ` + " bold"
              }
            >
              <Iconify
                icon={"clarity:error-solid"}
                fontSize={60}
                color={theme.color.error}
              />{" "}
              No Purchase History
            </div>
          ) : null}
          {(userCasets || []).map((item) => {
            return (
              <div key={item.id} style={{ width: 165 }}>
                <CasetHistoryItem key={item.id} item={item} />
              </div>
            );
          })}
          {/* {(userCasets || []).map((item) => {
            return <CasetItem key={item.id} item={item} />;
          })} */}
        </div>

        {/* <p className="bold">Explore</p> */}
      </div>
    </>
  );
};

export default History;
