import { memo } from "react";
import { Caset } from "../../interfaces/caset";
import { formatter } from "../../util/helper";
import { useNavigate } from "react-router-dom";

interface Props {
  item: Caset;
}

const CasetItem = ({ item }: Props) => {
  const navigate = useNavigate();
  if (!item.businessBranchId) {
    return <div style={{ backgroundColor: "transparent" }} />;
  }
  const misc = item.businessBranch.business.misc || {};
  const accentColor = item.businessBranch.business.accentColor;
  const outerContainerWidth = 270;
  return (
    <div
      className="flex-col center"
      style={{
        width: outerContainerWidth,
        scale: 0.9,
        cursor: "pointer",
      }}
    >
      <div
        onClick={() => {
          // request('payment/create', 'POST').then(
          //   (data: PaymentResponse) => {
          //     console.log(data);
          //     startCheckout(data);
          //   },
          // );
        }}
        style={{ color: accentColor, paddingBottom: 7 }}
      >
        {/* {item.category.title} */}
      </div>
      <div
        onClick={() => {
          navigate(`/explore/caset/${item.id}`, { state: item });
          //   vaapasNavigation(navigation, SCREEN_MAP.EXPLORE_CASET, item);
        }}
        className={"flex-col center"}
        style={{
          width: 258,
          // marginLeft: index === 0 ? 20 : 0,
          // marginRight: index === businesses.length -1  ? 20 : 0,
          // height: 354,
          backgroundColor: "white",
          //   shadowColor: "#fff",
          //   shadowOffset: {
          //     width: 0,
          //     height: 3,
          //   },
          //   shadowRadius: 3,
          //   shadowOpacity: 0.8,
          borderRadius: 20,
          padding: 16,
          // paddingHorizontal: 10,
        }}
      >
        <div
          className="flex-col center"
          style={{
            // width: '100%',
            width: 230,
            height: 230,
            // backgroundColor: accentColor,
            borderRadius: 20,
          }}
        >
          {misc.brandImage ? (
            <img src={misc.brandImage} alt="Brand" />
          ) : (
            // <Image
            //   source={{
            //     uri: misc.brandImage,
            //   }}
            //   style={[{ height: 230, width: 230 }]}
            //   // resizeMethod={'resize'}
            //   resizeMode={"contain"}
            // />
            <>
              {/* <Image source={STATIC_IMAGES.COIN} style={[{ height: 170 }]} /> */}
              <div className="bold" style={{ fontSize: 20 }}>
                {item.businessBranch.business.name}
              </div>
              <div className="regular" style={{ fontSize: 14 }}>
                {item.businessBranch.subLocation}
              </div>
            </>
          )}
        </div>
        <div
          className="flex-col grow"
          style={{
            width: "100%",
            height: 90,
            justifyContent: "space-around",
            // paddingHorizontal: 2,
            // backgroundColor: 'red',
            // transform: [{scale: }],
          }}
        >
          <div className="medium" style={{ fontSize: 15 }}>
            {item.unitPercentReturns.toFixed(2)}% royalty on every sale
          </div>
          <div className="flex-row">
            <div
              className="medium"
              style={{ fontSize: 14, alignSelf: "center" }}
            >
              Tenure:{" "}
            </div>
            <div
              style={{
                borderRadius: 9,
                borderColor: accentColor,
                borderWidth: 2,
                backgroundColor: misc.backgroundColor || "black",
                //   paddingHorizontal: 5,
                padding: "0px 5px",
              }}
            >
              <div
                className="medium"
                style={{ fontSize: 12, color: accentColor }}
              >
                {item.validity} month
              </div>
            </div>
          </div>
          <div className="medium" style={{ fontSize: 20 }}>
            Price: {formatter.format(item.unitPrice)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CasetItem);
