import React, { useState } from "react";
import theme from "../util/theme";
import Iconify from "./Iconify";

interface Props {
  title: string;
  value: string;
  updateKey: string;
  placeholder?: string;
  uneditable?: boolean;
  handleUpdate(key: string, value: string): void;
}

const EditInput = (props: Props) => {
  const [inEditState, setInEditState] = useState(false);
  const [savedValue, setSavedValue] = useState(props.value);
  const [changedValue, setChangedValue] = useState(props.value);

  return (
    <div
      style={{
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 20,
      }}
    >
      {inEditState ? (
        <div className="flex-col">
          <div>
            {/* <span style={[styles.text, GLOBAL_STYLES.bold, {paddingBottom: 4, color: theme.color.primary}]}>
              {props.title}
            </span> */}
            <div
              style={
                // GLOBAL_STYLES.center,
                {
                  // width:'100%',
                  padding: 4,
                  height: 48,
                  borderRadius: 4,
                  borderColor: theme.color.primary,
                  borderWidth: 1,
                  marginTop: 4,
                  marginBottom: 8,
                }
              }
            >
              <input
                value={changedValue}
                className={"bold"}
                style={{
                  color: "black",
                  width: "100%",
                  fontSize: 16,
                  //   paddingHorizontal: 12,
                  padding: "0px 12px",
                  // textAlignVertical: 'center',
                  height: 40,
                }}
                // onChangespan={setChangedValue}
                onChange={(e) => {
                  setChangedValue(e.target.value);
                }}
                placeholder={props.placeholder || ""}
                // placeholderspanColor={"#BFBFBF"}
              />
            </div>
          </div>
          <div className="flex" style={{ gap: "1rem" }}>
            <div
              className="flex grow center"
              style={{
                ...styles.button,
                ...{
                  //   borderColor: "white",
                  //   background: "red",
                  marginLeft: 0,
                  border: "1px solid white",
                },
              }}
              onClick={() => {
                setInEditState(false);
                setChangedValue(savedValue);
              }}
            >
              <div>
                <span className="bold" style={styles.text}>
                  CANCEL
                </span>
              </div>
            </div>

            <div
              className="flex-col grow center"
              style={{ ...styles.greenButton }}
              onClick={() => {
                setInEditState(false);
                setSavedValue(changedValue);
                if (props.handleUpdate) {
                  props.handleUpdate(props.updateKey, changedValue);
                }
              }}
            >
              <div>
                <span className="bold" style={styles.blacktext}>
                  UPDATE
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Not in edit state
        <div className="flex gap">
          <div className="flex-col grow" style={{ gap: 5 }}>
            <span
              className="bold"
              style={{
                ...styles.text,
                ...{ paddingBottom: 4, color: theme.color.primary },
              }}
            >
              {props.title}
            </span>
            <span style={{ ...styles.text, ...{ color: theme.color.light } }}>
              {changedValue || props.placeholder}
            </span>
          </div>
          <div className="flex center">
            <div
              style={{
                //   paddingHorizontal: 5,
                padding: "0px 5px",
              }}
              onClick={() => {
                if (!props.uneditable) setInEditState(true);
              }}
            >
              {props.uneditable || (
                // <span style={[GLOBAL_STYLES.bold, styles.text]}>EDIT</span>
                <Iconify icon={"fa-solid:pen"} color={theme.color.primary} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    // minHeight: "100%",
    backgroundColor: theme.color.primary_bg_2,
  },
  title: {
    fontSize: 16,
    // fontWeight:'bold',
    color: theme.color.light,
    fontFamily: theme.font.medium,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 56,
    paddingLeft: 25,
    paddingRight: 18,
    alignItems: "center",
    backgroundColor: theme.color.primary_bg_2,
    // backgroundColor: theme.color.white,
    // backgroundColor: '#BCBCBC',
  },
  parentHr: {
    height: 1,
    // color: theme.color.white,
    width: "100%",
  },
  child: {
    backgroundColor: theme.color.light,
    padding: 16,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  text: {
    fontSize: 16,
    color: theme.color.light,
    // fontFamily: theme.font.bold,
  },
  blacktext: {
    color: theme.color.dark,
    fontFamily: theme.font.medium,
  },
  greenButton: {
    backgroundColor: theme.color.primary,
    borderRadius: 4,
    height: 48,
  },
  button: {
    borderRadius: 4,
    borderColor: theme.color.primary,
    height: 48,
    borderWidth: 1,
    marginLeft: 5,
  },
};

export default EditInput;
