import { css } from "@emotion/css";
import { CSSProperties } from "react";
import theme from "../util/theme";

interface Props {
  text: string;
  onClick: () => void;
  style?: CSSProperties;
}

const CustomButton = (props: Props) => {
  return (
    <button
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      style={props.style || undefined}
      className={
        css`
          min-height: 48px;
          /* min-width: 100px; */
          background-color: ${theme.color.primary};
          border-radius: 4px;
          /* font-weight: bolder; */
          font-size: 18px;
          /* font-size: 20px; */
          /* flex-grow: 1; */
          outline: none;
          border: none;
          /* padding: 0; */
          pointer-events: auto;
          cursor: pointer;
          padding: 0px 10px;
        ` + " bold"
      }
    >
      {props.text}
    </button>
  );
};

export default CustomButton;
