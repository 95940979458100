import React, { memo, useMemo } from "react";

import { css, cx, keyframes } from "@emotion/css";
import Logo from "../components/Logo";
import { Outlet } from "react-router-dom";
import theme from "../util/theme";
import { mobileBreakPoint, useGlobal } from "../context/global";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const sideMobileWidth = 360;

export const screenHeight = "calc(var(--vh, 1vh) * 100)";
// export const screenHeight = "";

const rootStyle = css`
  display: grid;
  flex-direction: row;
  place-content: center;
  width: 100vw;
  overflow-y: hidden;
  background-color: ${theme.color.primary_bg_2};
  z-index: 1;
  @media screen and (max-width: ${mobileBreakPoint}px) {
    min-height: ${screenHeight};
  }
`;

const logoContainer = css``;

const logoAnimation = keyframes`
    0%{
        z-index: 1000;
        width: 100vw;
        opacity: 1;
    }
    100%{
        width:  calc(100vw);
        z-index: -1;
        opacity: 0;
    }
`;

const slideUp = keyframes`
0%{
    z-index: 1000;
    transform: translateY(0vh);
}
100%{
    transform: translateY(-100vh);
    z-index: -1;
}
`;

const zoomOutAndFade = keyframes`
0%{
    z-index: 1000;
    opacity: 1;
    transform: scale(1);
}
100%{
    transform: scale(5);
    opacity: 0;
    z-index: -1;
}
`;

const BaseLayout = () => {
  const { isMobile } = useGlobal();
  const logo = useMemo(() => <Logo width={200} height={200} />, []);
  return (
    <>
      <div
        className={css`
          position: absolute;
          display: grid;
          place-content: center;
          width: 100vw;
          height: 100vh;
          z-index: -1;
          background-color: ${theme.color.primary_bg};
          /* @media screen and (min-width: 767px) {
            animation: ${logoAnimation} 2s ease-in-out 0s 1;
          }

          @media screen and (max-width: 767px) {
            animation: ${slideUp} 2s ease-in-out 0s 1;
          } */
        `}
      >
        {logo}
      </div>
      <div
        className={rootStyle}
        style={{ overflowY: isMobile ? "hidden" : "auto", overflowX: "hidden" }}
      >
        <div
          className={css`
            /* display: grid; */
            display: flex;
            flex-direction: column;
            place-content: center;
            min-height: ${isMobile ? "unset" : screenHeight};
            /* height: ${screenHeight}; */
            overflow-y: auto;
            overflow-x: hidden;
            max-width: 1200px;
          `}
        >
          {isMobile ? null : <NavBar />}

          {/* <div>
            <Outlet />
          </div> */}
          <div
            className={css`
              display: grid;
              flex-grow: 1;
              place-content: ${isMobile ? "center" : "unset"};
              background-color: ${theme.color.primary_bg_2};
              width: min(100vw, 1200px);
              /* height: ${isMobile ? "100vh" : "unset"}; */
              @media screen and (max-width: ${mobileBreakPoint}px) {
                min-height: ${screenHeight};
              }
            `}
          >
            <div
              className={css`
                /* height: ${isMobile ? "100vh" : "unset"}; */
                @media screen and (max-width: ${mobileBreakPoint}px) {
                  min-height: ${screenHeight};
                }
              `}
            >
              <div
                className={css`
                  position: relative;
                  width: min(100vw, 1200px);
                  padding: ${isMobile ? "unset" : "0px 2rem"};
                  /* height: ${isMobile ? "100vh" : "unset"}; */

                  @media screen and (max-width: ${mobileBreakPoint}px) {
                    min-height: ${screenHeight};
                  }
                `}
              >
                <Outlet />
              </div>
            </div>
          </div>

          {isMobile ? null : <Footer />}
        </div>
      </div>
    </>
  );
};

export default memo(BaseLayout);
