import { SVGProps } from "react";
import { ReactComponent as CASET_LOGO } from "../assets/images/Caset Logo-white text.svg";
import VAAPAS_LOGO from "../assets/images/logo_horizontal_whiteText.png";
import VAAPAS__MARKET_LOGO from "../assets/images/vaapas_marketplace_logo.png";

const Logo = (props: any) => {
  return (
    // <CASET_LOGO
    //   width={200}
    //   height={200}
    //   style={{ cursor: "default", userSelect: "none" }}
    //   {...props}
    // />
    <img
      src={VAAPAS__MARKET_LOGO}
      alt={"Logo"}
      width={150}
      // height={200}
      style={{ cursor: "default", userSelect: "none", padding: "10px 0" }}
      // {...props}
    />
  );
};

export default Logo;
