import React from "react";
import { css } from "@emotion/css";
import Logo from "./Logo";
import { navigationItems } from "./BottomBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobal } from "../context/global";
import theme from "../util/theme";
import Iconify from "./Iconify";
import { useAuth } from "../context/auth";

const naviItemContainer = css`
  display: flex;
  gap: 1rem;
  align-self: center;
`;

const navItem = css``;

const navHeight = 100;

const NavBar = () => {
  const location = useLocation();
  const { isMobile } = useGlobal();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  return (
    <>
      <div className="flex " style={{ height: navHeight, zIndex: -10 }} />
      <div
        id="navbar"
        className={
          "flex " +
          css`
            /* background: rgba(12, 17, 27, 0.352); */
            backdrop-filter: blur(1px);
            background: rgba(0, 0, 0, 0.85);
            width: min(100vw, 1200px);
            padding: 0px 3rem;
            z-index: 100;
          `
        }
        style={{
          minHeight: navHeight,
          position: "fixed",
          top: 0,
        }}
      >
        {/* Logo */}
        <div
          className={css`
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-self: center;
          `}
        >
          {<Logo width={120} height={60} />}
        </div>
        <div className="grow" />
        {/* Links */}
        {isLoggedIn ? (
          <div className={naviItemContainer}>
            {navigationItems.map((item, index) => {
              const isCurrentScreen = location.pathname.match(item.path);
              return (
                <div
                  key={index}
                  className={navItem}
                  onClick={() => {
                    navigate(item.path);
                  }}
                  style={{
                    color: isCurrentScreen
                      ? theme.color.primary
                      : theme.color.grey,
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      paddingBottom: 4,
                      color: isCurrentScreen
                        ? theme.color.primary
                        : theme.color.grey,
                    }}
                    className="bold"
                  >
                    {item.label}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        {/* Profile Avatar */}
      </div>
    </>
  );
};

export default NavBar;
