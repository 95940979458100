import {
  removeTimeDataFromDate,
  formatDate,
  convertToIntlCurSys,
  formatter,
} from "../../util/helper";
import { Icon, IconProps } from "@iconify/react";
import { css } from "@emotion/css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Caset, Business } from "../../interfaces/caset";
import Iconify from "../../components/Iconify";
import { useEffect, useRef, useState, useCallback } from "react";
import { Charges } from "../../interfaces/caset.charges";
import { CasetSalesStats } from "../../interfaces/caset.sales";
import { UserWallet } from "../../interfaces/wallet";
import { useAuth } from "../../context/auth";
// import { cashfreeProd, cashfreeSandbox } from "cashfree-dropjs";
import {
  VictoryChart,
  VictoryTheme,
  VictoryArea,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryTooltip,
} from "victory";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Modal } from "@mui/material";
import { useGlobal } from "../../context/global";
import Loader from "../../components/Loader";
import theme from "../../util/theme";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import toast from "react-hot-toast";
import { screenHeight } from "../../layout/BaseLayout";
import { useMemo } from "react";
import ReturnsCalculator from "../../components/ReturnsCalculator";
import RiskFactors from "../../components/RiskFactors";
import FAQs from "../../components/FAQs";
import SampleDocuments from "../../sections/SampleDocuments";

const cashfreeDropsJs = require("cashfree-dropjs");
const cashfreeProd = cashfreeDropsJs.cashfreeProd;
const cashfreeSandbox = cashfreeDropsJs.cashfreeSandbox;

interface Props {
  navigation: any;
  route: any;
}

interface CashfreePaymentResponse {
  cftoken: string;
  order: {
    orderId: string;
    orderAmount: string;
    orderCurrency: string;
  };
  order_token: string;
  notifyUrl: string;
  env: string;
  order_note: string;
  appId: string;
  payment_link: string;
}

interface CasetDetailElement {
  icon: string;
  title: string;
  value: string;
}

const currentDate: Date = new Date();

const subtractMonthFromDate = (date: Date, months: number) => {
  const d = new Date(date);
  d.setDate(1);
  d.setMonth(d.getMonth() - months + 1);
  return d;
};

const monthEnds = (d: Date) => {
  d.setDate(1);
  removeTimeDataFromDate(d);
  const startDate = new Date(d);
  d.setMonth(d.getMonth() + 1);
  d.setSeconds(d.getSeconds() - 1);
  return {
    start: startDate,
    end: d,
  };
};

export const salesDataIntervals = [
  {
    title: formatDate(subtractMonthFromDate(currentDate, 1), "mmm"),
    id: 1,
    months: 1,
    ...monthEnds(subtractMonthFromDate(currentDate, 1)),
  },
  {
    title: formatDate(subtractMonthFromDate(currentDate, 2), "mmm"),
    id: 2,
    months: 2,
    ...monthEnds(subtractMonthFromDate(currentDate, 2)),
  },
  {
    title: formatDate(subtractMonthFromDate(currentDate, 3), "mmm"),
    id: 3,
    months: 3,

    ...monthEnds(subtractMonthFromDate(currentDate, 3)),
  },
  {
    title: formatDate(subtractMonthFromDate(currentDate, 4), "mmm"),
    id: 4,
    months: 4,
    ...monthEnds(subtractMonthFromDate(currentDate, 4)),
  },
];

const primary = css`
  font-weight: bold;
  color: ${theme.color.primary};
`;

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className={css`
          background: white;
          padding: 4px 10px;
          border-radius: 8px;
          & > * {
            color: black;
            font-size: 8px;
          }
        `}
      >
        <div className="label">{`${label} : ${payload[0].value}`}</div>
      </div>
    );
  }

  return null;
};

const ExploreCaset = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { authRequest, authData } = useAuth();
  const { isMobile } = useGlobal();
  const casetData: Caset = state as any;
  const misc = casetData.businessBranch.business.misc || {};
  const { id } = useParams();
  const [noOfMonths, SetNoOfMonths] = useState(1);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [casetSalesData, setCasetSalesData] = useState<CasetSalesStats | null>(
    null
  );
  const [yAxisOffset, setYAxisOffset] = useState(0);
  const [chargesData, setChargesData] = useState<Charges[]>([]);
  const [walletData, setWalletData] = useState<UserWallet | null>(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountWithCharges, setTotalAmountWithCharges] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const cashfreePayment = useRef<HTMLDivElement>(null);
  const [showCashfreePaymentScreen, setShowCashfreepaymentScreen] =
    useState(false);
  const [orderId, setOrderId] = useState("");
  const { showToast } = useGlobal();
  const user = authData?.user;
  const [quantity, setQuantity] = useState("1");
  const [showInvestModal, setShowInvestModal] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const casetDetailsData: CasetDetailElement[] = [
    {
      icon: "fa6-solid:percent",
      title: "Royalty Share",
      value: `${(casetData.unitPercentReturns * Number(quantity)).toFixed(
        2
      )}% on every sale`,
    },
    {
      icon: "fa6-solid:money-bill-1",
      title: "Caset(s) Worth",
      value: formatter.format(casetData.unitPrice * Number(quantity)),
    },
    // {
    //   icon: "fa6-solid:clock",
    //   title: "Tenure",
    //   value: `${casetData.validity} Month`,
    // },

    // {
    //   icon: "fa-solid:calendar-check",
    //   title: "Starting Date",
    //   value: formatDate(casetData.startDate),
    // },
  ];

  useEffect(() => {
    try {
      // getCasetSalesData();
      getPageData();
    } catch (err) {}
  }, []);

  useEffect(() => {
    let ta = Number(quantity || 0) * casetData.unitPrice;
    let chargeAmount = 0;
    // chargesData.forEach((item: Charges) => {
    //   if (item.chargeType == 'percentage') {
    //     chargeAmount += Number((ta * (item.value / 100)).toFixed(2));
    //   } else if (item.chargeType == 'fixed') {
    //     chargeAmount += item.value;
    //   }
    // });
    setTotalAmount(ta);
    setTotalAmountWithCharges(ta + chargeAmount);
  }, [quantity, casetData, chargesData]);

  useEffect(() => {
    // getCasetSalesData();
  }, [noOfMonths]);

  useEffect(() => {
    if (showCashfreePaymentScreen && orderId) {
      let cashfree = new cashfreeProd.Cashfree();
      // let cashfree = new cashfreeSandbox.Cashfree();

      // cashfreeSandbox
      if (cashfreePayment.current) cashfreePayment.current.innerHTML = "";
      cashfree.initialiseDropin(cashfreePayment.current, {
        orderToken: orderId,
        onSuccess: (data: any) => {
          // alert("Success");
          setShowCashfreepaymentScreen(false);
          setOrderId("");
          toast.success("Success");
        },
        onFailure: (data: any) => {
          // alert("Failed");
          setShowCashfreepaymentScreen(false);
          setOrderId("");
          toast.error(data.order.errorText);
        },
        components: [
          "order-details",
          "card",
          "netbanking",
          "app",
          "upi",
          "paylater",
          "credicardemi",
        ],
        style: {},
      });
    }
  }, [showCashfreePaymentScreen, orderId]);

  if (!casetData) {
    navigate("/explore", { replace: true });
  }
  const business: Business = casetData.businessBranch.business;

  const getPageData = () => {
    const promises = [];
    promises.push(getCharges());
    // promises.push(getCasetSalesData());
    promises.push(
      getCasetSalesDataBetweenMonths(
        salesDataIntervals[0].start,
        salesDataIntervals[0].end
      )
    );
    promises.push(getWalletData());
    setIsRefreshing(true);
    Promise.all(promises)
      .then(() => {
        setIsRefreshing(false);
      })
      .catch((_err) => {
        setIsRefreshing(false);
      });
  };

  const getWalletData = () => {
    return authRequest("user/wallet", "GET", {})
      .then((data: UserWallet) => {
        setWalletData(data);
      })
      .catch((err: any) => {});
  };

  const getCharges = () => {
    return authRequest("charges/caset", "GET", {})
      .then((data: Charges[]) => {
        setChargesData(data);
      })
      .catch((_err: any) => {});
  };

  const getCasetSalesData = (months = 1) => {
    setIsRefreshing(true);
    return authRequest(
      `caset/${casetData.id}/sales?months=${months || noOfMonths}`,
      "GET",
      {}
    )
      .then((data: CasetSalesStats) => {
        setCasetSalesData(data);
        let minAmount =
          data.current_month_stats && data.current_month_stats.length
            ? data.current_month_stats[0].amount
            : 0;
        (data.current_month_stats || []).forEach((v) => {
          if (v.amount < minAmount) {
            minAmount = v.amount;
          }
        });
        setYAxisOffset(minAmount * (80 / 100));
        setIsRefreshing(false);
      })
      .catch((_err: any) => {});
  };

  const getCasetSalesDataBetweenMonths = useCallback(
    (start: Date, end: Date) => {
      let s: Date = new Date(start);
      s.setMonth(start.getMonth() - 4);
      let e: Date = new Date(end.toDateString());
      e.setMonth(end.getMonth() - 4);
      setIsRefreshing(true);
      return authRequest(
        `caset/${
          casetData.id
        }/sales/dates?start=${s.toISOString()}&end=${e.toISOString()}`,
        "GET",
        {}
      )
        .then((data: CasetSalesStats) => {
          setCasetSalesData(data);
          let minAmount =
            data.current_month_stats && data.current_month_stats.length
              ? data.current_month_stats[0].amount
              : 0;
          (data.current_month_stats || []).forEach((v) => {
            if (v.amount < minAmount) {
              minAmount = v.amount;
            }
          });
          setYAxisOffset(minAmount * (80 / 100));
          setIsRefreshing(false);
        })
        .catch((_err: any) => {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [casetData.id]
  );

  const hidePaymentModal = () => {
    setShowPaymentModal(false);
  };
  const renderBusinessSales = useMemo(() => {
    return (
      <>
        {/* Business sales data */}
        <div style={{ padding: "20px 0px" }}>
          <div style={{ paddingBottom: "30px" }}>Insights</div>
          <div className="flex" style={{ justifyContent: "space-evenly" }}>
            {salesDataIntervals.map((v, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    SetNoOfMonths(v.months);
                    getCasetSalesDataBetweenMonths(v.start, v.end);
                  }}
                  unselectable="on"
                  // onselectstart="return false;"
                  // onmousedown="return false;"
                  className={css`
                    background: ${noOfMonths === v.months
                      ? theme.color.primary
                      : "white"};
                    color: black;
                    padding: 5px 10px;
                    letter-spacing: 1px;
                    border-radius: 15px;
                    pointer-events: all;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    -o-user-select: none;
                    &:focus {
                      opacity: 0.7;
                    }
                  `}
                >
                  {v.title}
                </div>
              );
            })}
          </div>
        </div>
        {/* Business sales graph */}
        {casetSalesData &&
        casetSalesData.current_month_stats &&
        casetSalesData.current_month_stats.length ? (
          <div className="flex-col" style={{ gap: "2rem" }}>
            <div className="flex" style={{ justifyContent: "space-evenly" }}>
              {[
                {
                  value:
                    convertToIntlCurSys(
                      casetSalesData?.last_month_data.sale || 0
                    ) + "+",
                  key: "Bills",
                },
                {
                  value:
                    convertToIntlCurSys(
                      Number(
                        (
                          (casetSalesData?.last_month_data.amount || 0) /
                          (casetSalesData?.last_month_data.sale || 1)
                        ).toFixed(2)
                      )
                    ) + "+",
                  key: "Avg\nBill size",
                },
                {
                  value: (
                    <div className="flex" style={{ fontSize: 20 }}>
                      {convertToIntlCurSys(
                        casetSalesData?.last_month_data.amount || 0
                      ) + "+"}
                    </div>
                  ),
                  // key: `${casetData.businessBranch.rating.source} Rating`,
                  key: "Sales",
                },
              ].map((d, i) => {
                return (
                  <div
                    key={i}
                    className={
                      css`
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        background-color: #3a3a47;
                      ` + " flex-col center"
                    }
                  >
                    <div
                      className={`bold ${primary}`}
                      style={{ textAlign: "center", fontSize: 20 }}
                    >
                      {d.value}
                    </div>
                    <div
                      className={`bold ${primary}`}
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#AAAAAA",
                        textTransform: "capitalize",
                        whiteSpace: "pre",
                      }}
                    >
                      {d.key}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="bold" id="salesFeed">
              Sales Feed
            </div>
            {/* Chart */}
            <ResponsiveContainer width={"100%"} height={200}>
              <AreaChart
                width={350}
                height={200}
                data={casetSalesData.current_month_stats.map((d) => {
                  return {
                    name: d.proper_date,
                    uv: Number(d.amount.toFixed()),
                    pv: Number(d.amount.toFixed()),
                    // x: "",
                    value: Number(d.amount.toFixed()),
                    // label: `${d.proper_date}\n${Number(d.amount.toFixed())}`,
                  };
                })}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="name" tick={false} />
                <YAxis width={40} tick={{ fontSize: 8 }} />
                <Tooltip content={CustomTooltip} />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke={theme.color.primary}
                  fill="#f472221a"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        ) : null}
      </>
    );
  }, [casetSalesData, getCasetSalesDataBetweenMonths, noOfMonths]);

  const getPreTaxRevenue = useCallback(() => {
    const cagr = casetData.expectedUpside;
    const validity = casetData.validity;
    const unitPrice = casetData.unitPrice;
    const unitPercentReturns = casetData.unitPercentReturns;
    const expectedPercentInc = (cagr / 12) * validity;
    const investedAmount = Number(quantity) * unitPrice;

    return investedAmount + (investedAmount / 100) * expectedPercentInc;
  }, [
    casetData.expectedUpside,
    casetData.unitPercentReturns,
    casetData.unitPrice,
    casetData.validity,
    quantity,
  ]);

  const renderCasetBuySection = useMemo(() => {
    return (
      <div
        className={css`
          padding: 0px 10px;

          & .white {
            color: white;
          }
          & span {
            font-size: 1rem;
          }
        `}
      >
        <div>
          {/* <div className="white bold">Caset Details</div> */}
          <div
            className={
              "flex-col " +
              css`
                font-size: 16px !important;
              `
            }
            style={{ gap: "1rem", paddingTop: "1rem" }}
          >
            {casetDetailsData.map((d, i) => {
              return (
                <div
                  key={i}
                  className="flex"
                  style={{
                    alignItems: "center",
                    fontSize: 16,
                  }}
                >
                  {/* <Iconify
                    icon={d.icon}
                    color={theme.color.primary}
                    fontSize={16}
                  /> */}
                  <span
                    className="white bold"
                    style={{ paddingLeft: 0, fontSize: 16 }}
                  >
                    {" "}
                    {d.title}
                  </span>
                  <div className="grow" />
                  <span className={primary} style={{ fontSize: 16 }}>
                    {d.value}
                  </span>
                </div>
              );
            })}
            <div className="flex">
              <span className="white bold" style={{ fontSize: 16 }}>
                Pre tax revenue
              </span>
              <div className="grow" />
              <span className={primary} style={{ fontSize: 16 }}>
                {formatter.format(getPreTaxRevenue())}
                {/* Only {casetSalesData?.available_casets || 0} left */}
              </span>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            paddingTop: "1rem",
            gap: "1rem",
          }}
        >
          {/* <input
            className={`grow ${css`
              min-height: 48px;
              background-color: transparent;
              border-color: transparent;
              font-weight: bold;
              color: white;
              // padding: 0 10px;

              padding-left: 10px;
              flex: 50%;
              border: 1px solid ${theme.color.primary};
              border-radius: 4px;

              &:focus {
                outline: none;
              }
            `}`}
            value={quantity}
            placeholder="Enter number of casets"
            onChange={(e) => {
              const text = e.target.value;
              let n = Number(text);
              if (
                isNaN(n) ||
                n < 0 ||
                n > (casetSalesData?.available_casets || 0)
              )
                return;
              setQuantity(text);
            }}
          /> */}
          <button
            onClick={() => {
              if (isNaN(Number(quantity))) {
                return toast.error("Invalid number");
              }
              if (Number(quantity) > (casetSalesData?.available_casets || 0)) {
                return toast.error("Invalid number of casets");
              }
              if (totalAmount > 25000) {
                return navigate("/proceed_to_confirm", {
                  state: {
                    amount: Number(totalAmount),
                    quantity: Number(quantity),
                    casetId: casetData.id,
                  },
                });
                // return vaapasNavigation(
                //   props.navigation,
                //   SCREEN_MAP.PROCEED_TO_CONFIRM_VPA,
                //   {
                //     amount: Number(totalAmount),
                //     quantity: Number(quantity),
                //     casetId: casetData.id,
                //   }
                // );
                // return showToast(
                //   'Contact us for transactions worth more than 25,000',
                // );
                return toast.error(
                  "Contact us for transactions worth more than 25,000"
                );
              }
              if ((casetSalesData?.available_casets || 0) === 0) {
                return toast.error("No casets available");
              }

              if (Number(quantity) > 0) {
                setShowInvestModal(false);
                setShowPaymentModal(true);
              } else toast.error("Invalid quantity");
            }}
            className={`grow ${
              css`
                min-height: 40px;
                background-color: ${theme.color.primary};
                border-radius: 4px;
                flex: 50%;
                font-weight: bolder;
                outline: none;
                border: none;
                pointer-events: auto;
                padding: 0px 10px;
              ` + " bold"
            }`}
          >
            Buy Caset
          </button>
        </div>
      </div>
    );
  }, [
    casetData.id,
    casetDetailsData,
    casetSalesData?.available_casets,
    getPreTaxRevenue,
    navigate,
    quantity,
    totalAmount,
  ]);

  const casetBuySectionWithCalculator = useMemo(() => {
    return (
      <>
        {/* Calculator */}
        <ReturnsCalculator
          cagr={casetData.expectedUpside}
          validity={casetData.validity}
          unitPrice={casetData.unitPrice}
          unitPercentReturns={casetData.unitPercentReturns}
          onNumberOfCasetsChange={(number) => {
            setQuantity(String(number));
          }}
          availableCasets={casetSalesData?.available_casets || 0}
          casetLiveDate={formatDate(casetData.startDate)}
        />
        {/* Business Buy Section */}
        {renderCasetBuySection}
      </>
    );
  }, [
    casetData.expectedUpside,
    casetData.startDate,
    casetData.unitPercentReturns,
    casetData.unitPrice,
    casetData.validity,
    casetSalesData?.available_casets,
    renderCasetBuySection,
  ]);

  const [linkIds, setLinkIds] = useState<string[]>([]);

  useEffect(() => {
    const elements = linkIds.map((l) => {
      return document.querySelector(l);
    });

    const aLinks = document.querySelectorAll("#sideLinks a");
    const navbar = document.getElementById("navbar");
    let navbarHeight = 0;
    if (navbar) {
      navbarHeight = navbar.clientHeight;
    }
    const sideLink = document.getElementById("sideLinks");

    window.onscroll = (e) => {
      let isSideContainerOnTop = false;
      let currentElementId: string = "";
      elements.forEach((e) => {
        if (e) {
          const bounds = e.getBoundingClientRect();
          if (bounds.top < navbarHeight + 50) {
            isSideContainerOnTop = true;
          }
          if (bounds.top < navbarHeight) {
            currentElementId = e.id;
          }
        }
      });

      aLinks.forEach((e) => {
        if (e) {
          e.classList.remove("active");
        }
      });

      elements.forEach((e) => {
        if (e && e.id !== currentElementId) {
          e.classList.remove("active");
        }
      });

      const currentActive = document.querySelector(
        `#${currentElementId || "sr"}`
      );

      if (isSideContainerOnTop && currentElementId) {
        const link = document.querySelector(`a[href='#${currentElementId}']`);
        if (link) {
          link.classList.add("active");
        }
        if (currentActive) {
          currentActive.classList.add("active");
        }

        if (sideLink) {
          sideLink.classList.add("active");
          sideLink.style.position = "fixed";
          sideLink.style.top = navbarHeight + "px";
        }
      } else {
        if (sideLink) {
          sideLink.classList.remove("active");
          sideLink.style.position = "relative";
          sideLink.style.top = 0 + "px";
        }
      }
    };

    return () => {
      window.onscroll = null;
    };
  }, [linkIds]);

  const renderSideLinks = useMemo(() => {
    const links = [];
    const misc = casetData.businessBranch.business.misc;
    const desc = misc.desc || "";
    const points = misc.points || [];
    const images = misc.images || [];
    if (misc.desc || points.length || images.length) {
      links.push({
        title: "About",
        to: "#about",
      });
    }
    const defaultLinks = [
      {
        title: "Risk Factors",
        to: "#risk_factors",
      },
      {
        title: "FAQs",
        to: "#faq",
      },
      // {
      //   title: "Sample Documents",
      //   to: "#documents",
      // },
    ];

    const allLinks = [...links, ...defaultLinks];

    setLinkIds(allLinks.map((l) => l.to));

    return (
      <div
        id={"sideLinks"}
        className={css`
          display: flex;
          min-width: 220px;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem;
          margin-top: 1rem;
          transition: font-size ease-in-out 400ms;
          & a {
            text-decoration: none;
            color: white;
          }

          &.active {
            padding-top: 0px;
            margin-top: 0px;
          }

          & a.active {
            color: ${theme.color.primary};
            font-size: 20px;
          }
        `}
      >
        {allLinks.map((link, i) => {
          return (
            <a
              className="bold"
              href={link.to}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                var element = document.querySelector(link.to);
                if (!element) return;
                const navbar = document.getElementById("navbar");
                var headerOffset = navbar ? navbar.clientHeight : 100;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition =
                  elementPosition + window.pageYOffset - headerOffset + 6;

                window.scrollTo({
                  top: offsetPosition,
                  behavior: "smooth",
                });
              }}
            >
              {link.title}
            </a>
          );
        })}
      </div>
    );
  }, [casetData.businessBranch.business.misc]);

  const renderCasetBusinessInfoSection = useMemo(() => {
    const misc = casetData.businessBranch.business.misc;
    const desc = misc.desc || "";
    const points = misc.points || [];
    const images = misc.images || [];
    if (!(misc.desc || points.length || images.length)) {
      return null;
    }
    return (
      <div
        id="about"
        className={css`
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          & .heading {
            /* font-size: 20px; */
            color: ${isMobile ? theme.color.primary : "unset"};
            font-size: 20px;
          }

          & .desc {
            color: #cbcbcb;
            line-height: 24px;
          }

          &.active .heading {
            color: ${theme.color.primary};
          }

          & .point ul {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            & li {
              color: #cbcbcb;
              line-height: 24px;
            }
          }
        `}
      >
        <div className="heading">
          {casetData.businessBranch.business.name
            ? `About ${casetData.businessBranch.business.name || ""}`
            : ""}
        </div>
        <div className="divider" style={{ transform: "scale(1)" }} />
        {desc ? <div className="desc regular">{desc}</div> : null}
        <div className="point">
          <ul>
            {points.map((p) => {
              return <li className="regular">{p}</li>;
            })}
          </ul>
        </div>
        <div
          className={css`
            display: flex;
            gap: 1rem;
            /* padding: 1rem; */
            justify-content: center;
            flex-wrap: wrap;
          `}
        >
          {images.map((i, index) => {
            return <img src={i} alt={index + ""} width={200} />;
          })}
        </div>
      </div>
    );
  }, [
    casetData.businessBranch.business.misc,
    casetData.businessBranch.business.name,
  ]);

  const renderBuyPopup = useMemo(() => {
    return (
      <div
        className={css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          // ref={cashfreePayment}
          onClick={() => {
            setShowPaymentModal(false);
          }}
          className="grow"
        />
        <div
          className={css`
            & span {
              font-size: 20px;
            }
          `}
          style={{
            minHeight: 100,
            background: "#1C1C1C",
            padding: 20,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div className="flex" style={{ color: "white", fontWeight: "bold" }}>
            <span>Summary</span>
            <div className="grow" />{" "}
            <div
              onClick={() => {
                setShowPaymentModal(false);
              }}
            >
              <Iconify
                icon={"fa6-solid:circle-xmark"}
                color={theme.color.primary}
              />{" "}
            </div>
          </div>

          <div className="flex">
            <span className="medium" style={{ color: "white" }}>
              Quantity
            </span>
            <div className="grow" />
            <span className="medium bold" style={{ color: "white" }}>
              {quantity || 1} Caset(s)
            </span>
          </div>

          <div className="flex">
            <span className="medium" style={{ color: "white" }}>
              Total Cost
            </span>
            <div className="grow" />
            <span
              className="medium bold"
              style={{ color: "white", fontSize: 20 }}
            >
              {formatter.format(casetData.unitPrice * Number(quantity))}
            </span>
          </div>

          <div className="flex">
            <span className="medium" style={{ color: "white" }}>
              Grand Total
            </span>
            <div className="grow" />
            <span className="medium bold" style={{ color: "white" }}>
              {formatter.format(totalAmountWithCharges)}
            </span>
          </div>

          <CustomButton
            text="Make Payment"
            onClick={() => {
              if (user && !(user.isKycVerified || user.accountVerified)) {
                return navigate("/kyc");
              }
              setIsRefreshing(true);
              authRequest("caset/checkout/cashfree/web", "POST", {
                casetId: casetData.id,
                quantity: Number(quantity),
                totalAmount: totalAmount,
              })
                .then((data: CashfreePaymentResponse) => {
                  setIsRefreshing(false);
                  setShowPaymentModal(false);
                  if (data.payment_link) {
                    // window.open(data.payment_link);
                    window.location.href = data.payment_link;
                  }
                  // setOrderId(data.order_token);
                  // await
                  // setShowCashfreepaymentScreen(true);
                  // startCashFreeCheckout(data);
                })
                .catch((err) => {
                  setIsRefreshing(false);
                  toast.error(err.message || "Error");
                });

              // setIsRefreshing(true);
            }}
          />
        </div>
      </div>
    );
  }, [
    authRequest,
    casetData.id,
    casetData.unitPrice,
    quantity,
    totalAmount,
    totalAmountWithCharges,
  ]);

  const renderInvestModal = useMemo(() => {
    return (
      <div
        className={css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          // ref={cashfreePayment}
          onClick={() => {
            setShowPaymentModal(false);
          }}
          className="grow"
        />
        <div
          className={
            css`
              & span {
                font-size: 20px;
              }
            ` + " bold"
          }
          style={{
            minHeight: 100,
            background: theme.color.bg_4,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            color: "white",
            gap: "0.5rem",
          }}
        >
          <div className={"flex " + css``}>
            <div className="grow" />
            <div
              onClick={() => {
                setShowInvestModal(false);
              }}
            >
              <Iconify
                icon={"fa6-solid:circle-xmark"}
                color={theme.color.primary}
                fontSize={30}
              />{" "}
            </div>
          </div>
          {casetBuySectionWithCalculator}
        </div>
      </div>
    );
  }, [casetBuySectionWithCalculator]);

  return (
    <>
      <div
        className={
          css`
            position: fixed;
            display: flex;
            gap: 5px;
            flex-direction: column;
            width: 100vw;
            height: ${screenHeight};
            overflow-y: scroll;
            background: "black";
            top: 0;
            display: ${showCashfreePaymentScreen ? "grid" : "none"};
            z-index: 10;
            place-content: center;
          ` + ""
        }
      >
        <div
          className={css`
            display: flex;
            margin-top: ${isMobile ? "20px" : "0px"};
            position: relative;
          `}
        >
          <div className="grow" />
          <Iconify
            icon={"eva:close-circle-fill"}
            fontSize={30}
            onClick={() => {
              setOrderId("");
              setShowCashfreepaymentScreen(false);
            }}
            color={theme.color.primary}
          />
        </div>
        <div
          ref={cashfreePayment}
          style={{
            minWidth: 310,
            overflowY: window.innerHeight > 800 ? "hidden" : "auto",
            overflowX: "hidden",
          }}
        ></div>
      </div>
      <div
        className={`bold ${css`
          padding: 20px 24px;
          position: relative;
          width: 100%;
          height: ${isMobile ? screenHeight : "unset"};
          overflow-y: ${isMobile ? "scroll" : "unset"};
          color: white;
          padding-bottom: ${isMobile ? "70px" : "24px"};
        `}`}
      >
        <Loader loading={isRefreshing} />

        {/* Make Payment Modal */}
        <Modal open={showPaymentModal}>
          {isMobile ? (
            renderBuyPopup
          ) : (
            <div
              className={
                css`
                  position: fixed;
                  gap: 1rem;
                  flex-direction: column;
                  width: 100vw;
                  height: ${screenHeight};
                  background: "black";
                  left: 0;
                  display: grid;
                  z-index: 10;
                  place-content: center;
                ` + ""
              }
            >
              <div style={{ width: 500 }}>{renderBuyPopup}</div>
            </div>
          )}
        </Modal>

        {/* Invest Mobile Modal */}
        <Modal open={showInvestModal}>{renderInvestModal}</Modal>
        {/* Add Mobile fixed Button */}
        {isMobile ? (
          <div
            className={css`
              position: fixed;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 70px;
              top: calc(${screenHeight} - 70px);
              left: 0;
              padding: 0px 2rem;
              backdrop-filter: blur(4px);
              z-index: 20;
              /* padding-top: 10px; */
            `}
          >
            <button
              className={
                css`
                  padding: 10px 20px;
                  height: 55px;
                  min-width: 200px;
                  background-color: ${theme.color.primary};
                  color: black;
                  font-size: 18px;
                  line-height: 24px;
                  width: 50%;
                  font-weight: 700;
                  flex: 1 1;
                  border-radius: 6px;
                ` + " bold"
              }
              onClick={() => {
                setShowInvestModal(true);
              }}
            >
              Invest Now
            </button>
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            top: 0,
            marginBottom: "1rem",
          }}
          className={
            css`
              position: ${isMobile ? "fixed" : "relative"};
              padding-top: 1rem;
              z-index: 20;
              width: 100%;
            ` + " blur"
          }
        >
          <div
            style={{
              marginLeft: -10,
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <Iconify icon={"eva:arrow-ios-back-fill"} fontSize={30} />
          </div>
          <div className="flex-col" style={{}}>
            {misc.brandImage ? (
              <img
                src={misc.brandImage}
                width={63}
                style={{ borderRadius: "17%" }}
                alt="Brand"
              />
            ) : null}
          </div>
        </div>
        {/* business info section */}
        {!isMobile ? null : (
          <div
            style={{
              marginTop: 65,
            }}
          >
            <div className="flex center">
              <div className={"bold "} style={{ fontSize: 24 }}>
                {business.name}
              </div>
              <div className="grow"></div>
              <div style={{ fontSize: 16 }}>
                {casetData.expectedUpside ? "Exp. CAGR" : null}
              </div>
            </div>
            <div className="flex center">
              <div className="flex center">
                <div className={"bold " + primary} style={{ fontSize: 24 }}>
                  {business &&
                  business.location &&
                  business.location.mapLink ? (
                    <Iconify
                      icon={"bxs:map"}
                      color={theme.color.primary}
                      fontSize={18}
                    />
                  ) : null}
                </div>
                <div className={primary}>
                  {casetData.businessBranch.subLocation}
                </div>
              </div>
              <div className="grow"></div>
              <div className={primary}>
                {casetData.expectedUpside ? (
                  <div className="flex center" style={{ gap: 10 }}>
                    <Iconify
                      icon={"bi:arrow-up-circle-fill"}
                      color={theme.color.primary}
                      fontSize={18}
                    />{" "}
                    <span>{casetData.expectedUpside + " %"}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}

        {isMobile ? (
          <>
            {renderBusinessSales}
            {/* Business Buy Section */}
            {/* {casetBuySectionWithCalculator} */}
            {/* <ReturnsCalculator
              cagr={casetData.expectedUpside}
              validity={casetData.validity}
              unitPrice={casetData.unitPrice}
              unitPercentReturns={casetData.unitPercentReturns}
              onNumberOfCasetsChange={(number) => {
                setQuantity(String(number));
              }}
              availableCasets={casetSalesData?.available_casets || 0}
              casetLiveDate={formatDate(casetData.startDate)}
            /> */}
            {/* {renderCasetBuySection} */}
          </>
        ) : (
          <>
            <div
              className={
                css`
                  gap: 2rem;
                  flex-wrap: wrap;
                ` + " flex"
              }
            >
              <div
                className={
                  "grow " +
                  css`
                    /* width: 100%; */
                    min-width: 500px;
                    max-width: 700px;
                    align-self: center;
                    justify-content: center;
                  `
                }
              >
                <div>
                  <div className="flex center">
                    <div className={"bold "} style={{ fontSize: 24 }}>
                      {business.name}
                    </div>
                    <div className="grow"></div>
                    <div style={{ fontSize: 16 }}>
                      {casetData.expectedUpside ? "Exp. CAGR" : null}
                    </div>
                  </div>
                  <div className="flex center">
                    <div className="flex center">
                      <div
                        className={"bold " + primary}
                        style={{ fontSize: 24 }}
                      >
                        {business &&
                        business.location &&
                        business.location.mapLink ? (
                          <Iconify
                            icon={"bxs:map"}
                            color={theme.color.primary}
                            fontSize={18}
                          />
                        ) : null}
                      </div>
                      <div className={primary}>
                        {casetData.businessBranch.subLocation}
                      </div>
                    </div>
                    <div className="grow"></div>
                    <div className={primary}>
                      {casetData.expectedUpside ? (
                        <div className="flex center" style={{ gap: 10 }}>
                          <Iconify
                            icon={"bi:arrow-up-circle-fill"}
                            color={theme.color.primary}
                            fontSize={18}
                          />{" "}
                          <span>{casetData.expectedUpside}</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {renderBusinessSales}
              </div>

              <div
                className={
                  "grow " +
                  css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 1rem;
                  `
                }
                style={{ maxWidth: 320 }}
              >
                {casetBuySectionWithCalculator}
                {/* Calculator */}
                {/* <ReturnsCalculator
                  cagr={casetData.expectedUpside}
                  validity={casetData.validity}
                  unitPrice={casetData.unitPrice}
                  unitPercentReturns={casetData.unitPercentReturns}
                  onNumberOfCasetsChange={(number) => {
                    setQuantity(String(number));
                  }}
                  availableCasets={casetSalesData?.available_casets || 0}
                  casetLiveDate={formatDate(casetData.startDate)}
                /> */}
                {/* Business Buy Section */}
                {/* {renderCasetBuySection} */}
              </div>
            </div>
            <div></div>
          </>
        )}

        {isMobile ? (
          <>
            {/* Business and term Details */}
            {renderCasetBusinessInfoSection}

            {/* Risk Factor */}
            <RiskFactors />

            {/* FAQs */}
            <FAQs />

            {/* <SampleDocuments /> */}
          </>
        ) : (
          <div
            className={css`
              display: flex;
            `}
          >
            <div
              style={{
                minWidth: 220,
                position: "relative",
              }}
            >
              {renderSideLinks}
            </div>
            <div>
              {/* Business and term Details */}
              {renderCasetBusinessInfoSection}

              {/* Risk Factor */}
              <RiskFactors />

              {/* FAQs */}
              <FAQs />

              {/* <SampleDocuments /> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ExploreCaset;
