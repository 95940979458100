/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../components/CustomButton";
import Iconify from "../components/Iconify";
import Loader from "../components/Loader";
import { useAuth } from "../context/auth";
import { useGlobal } from "../context/global";
import { User } from "../interfaces/user";
import theme from "../util/theme";
import { css } from "@emotion/css";

const PaymentUpdateScreen = () => {
  const naviagte = useNavigate();
  const { authData, authRequest, updateUserData } = useAuth();
  const { showToast } = useGlobal();
  const user = authData?.user || {
    name: "",
    email: "",
    bankAccount: "",
    upiAddress: "",
    ifsc: "",
    accountVerified: false,
  };

  const [userName, setUserName] = useState((user && user.name) || "");

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(user.email || "");
  const [emailOTPSent, setEmailOTPSent] = useState(false);
  const [emailOTP, setEmailOTP] = useState("");
  const [emailOTPSentTime, setEmailOTPSentTime] = useState<Date | null>(null);
  const [kycData, setKycData] = useState({
    aadhaarNo: "",
    panNo: "",
  });
  const [paymentInfoData, setPaymentInfoData] = useState({
    bankAccount: "",
    ifsc: "",
    upiAddress: "",
  });

  useEffect(() => {
    getMe();
    setPaymentInfoData({
      bankAccount: user.bankAccount,
      ifsc: user.ifsc,
      upiAddress: user.upiAddress,
    });
  }, []);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setUserName(user.email);
    }
  }, [user]);

  const getMe = () => {
    setIsLoading(true);
    return authRequest("user/me", "GET", {})
      .then((data: User) => {
        setIsLoading(false);
        // console.log
        updateUserData(data);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
        showToast(err.message || "Error");
      });
  };

  const saveAndVerifyPaymentDetails = () => {
    setIsLoading(true);
    return authRequest("user/payment_details/verify", "POST", paymentInfoData)
      .then(async () => {
        setIsLoading(false);
        await getMe();
        // goBack(props.navigation);
      })
      .catch(async (err: any) => {
        setIsLoading(false);
        await getMe();

        showToast(err.message || "Error");
      });
  };

  // const saveAndVerifyKyc = () => {
  //   setIsLoading(true);
  //   authRequest("user/kyc/verify", "POST", kycData)
  //     .then(async () => {
  //       setIsLoading(false);
  //       getMe();
  //     })
  //     .catch(async (err: any) => {
  //       setIsLoading(false);
  //       getMe();
  //       showToast(err.message || "Error");
  //     });
  // };

  const InputField = (
    title = "",
    value = "",
    placeholder = "",
    onChange: ((text: string) => void) | undefined = undefined,
    endAdornment: JSX.Element | undefined = undefined,
    disable = false,
    startAdornment: JSX.Element | undefined = undefined
  ) => {
    return (
      <>
        {title ? (
          <span
            className="bold"
            style={{
              color: theme.color.primary,
              fontSize: 18,
              marginBottom: 8,
            }}
          >
            {title}
          </span>
        ) : null}
        <div
          className="flex center"
          style={{
            padding: "4px 10px",
            height: 48,
            borderRadius: 4,
            backgroundColor: "white",
            borderWidth: 1,
            marginTop: 4,
            marginBottom: 8,
          }}
        >
          {startAdornment || null}
          <input
            className={`bold grow  ${css`
              min-height: 48px;
              background-color: transparent;
              border-color: transparent;
              padding: 0 10px;
              flex-grow: 1;

              &:focus {
                outline: none;
              }
            `}`}
            style={{
              color: "black",
              fontSize: 16,
              // backgroundColor: 'white',
              // textAlignVertical: 'center',
              height: 40,
            }}
            // onChangespan={onChange}
            onChange={(e) => {
              if (onChange) onChange(e.target.value);
            }}
            value={value}
            disabled={disable}
            // onChangespan={setChangedValue}
            // placeholder={props.placeholder || ''}
            placeholder={placeholder}
            // placeholderspanColor={"#BFBFBF"}
          />
          {endAdornment || null}
        </div>
      </>
    );
  };

  if (!user) {
    return (
      <div
        style={{
          backgroundColor: theme.color.primary_bg_2,
          width: "100%",
          height: "100%",
        }}
      />
    );
  }

  return (
    <div>
      <Loader loading={isLoading} />
      <div
        style={{
          backgroundColor: theme.color.primary_bg_2,
          padding: "20px 16px",
          width: "100%",
          height: "100%",
        }}
      >
        <div>
          <div
            onClick={() => {
              // goBack(props.navigation);
              naviagte(-1);
              // naviagte("/my_profile", { replace: true });
              // vaapasNavigation(props.navigation, SCREEN_MAP.PROFILE, {});
            }}
            className={"flex-col"}
            style={{
              // paddingRight: 20,
              // paddingLeft: 2,
              // backgroundColor: 'red',
              height: 30,
              width: 30,
              borderRadius: 15,
              alignContent: "center",
              justifyContent: "center",
              // marginBottom: 20,
              // alignItems: 'center',
            }}
          >
            <Iconify
              icon={"akar-icons:chevron-left"}
              color={theme.color.primary}
              fontSize={24}
            />
          </div>
          {/* <span
            style={[
              GLOBAL_STYLES.bold,
              {color: theme.color.light, fontSize: 24, bottom: 8},
            ]}>
            Personal Information
          </span> */}
        </div>
        <div style={{ paddingTop: 4 }}>
          {user ? (
            <>
              {/* Payment Details */}
              <div
                className={css`
                  & > span {
                    display: block;
                  }
                `}
                style={{ marginTop: 20 }}
              >
                <span
                  className="bold"
                  style={{ color: "white", fontSize: 24, marginBottom: 8 }}
                >
                  Payment Details
                </span>
                {InputField(
                  "Account Number",
                  paymentInfoData.bankAccount,
                  "Enter account number",
                  (text) => {
                    setPaymentInfoData({
                      ...paymentInfoData,
                      ...{ bankAccount: text },
                    });
                  },
                  user.accountVerified ? (
                    <Iconify fontSize={20} icon={"check"} color={"green"} />
                  ) : (
                    <></>
                  ),
                  user.accountVerified
                )}
                {InputField(
                  "IFSC Number",
                  paymentInfoData.ifsc,
                  "Enter IFSC number",
                  (text) => {
                    setPaymentInfoData({
                      ...paymentInfoData,
                      ...{ ifsc: text },
                    });
                  },
                  user.accountVerified ? (
                    <Iconify fontSize={20} icon={"check"} color={"green"} />
                  ) : (
                    <></>
                  ),
                  user.accountVerified
                )}
                <CustomButton
                  style={{
                    width: "100%",
                    marginTop: 10,
                  }}
                  text="Save Payment Details"
                  onClick={() => {
                    saveAndVerifyPaymentDetails();
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentUpdateScreen;
