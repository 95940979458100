import React from "react";
import { css, cx, keyframes } from "@emotion/css";
import Logo from "./Logo";
import Iconify from "./Iconify";

const baseSectionStyle = css`
  flex-grow: 1;
  flex: 50%;
  padding: 1rem 2rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
`;
const sectionTitle =
  "bold  " +
  css`
    font-size: 16px;
    color: white;
  `;

const quickLinks = css`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0px;
`;
const link = css`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  /* padding-top: 10px; */
`;

const grey = "#ffffff7f";

const FooterLogo = () => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      `}
    >
      {<Logo width={140} height={65} />}
      <div
        className={
          css`
            color: ${grey};
          ` + " bold"
        }
      >
        Vaapas is a digital alternative investment platform enabling the
        opportunity for individuals to invest in city’s premium brands. Vaapas
        aims to democratize premium investment opportunities to all, previously
        only available to a select few of the society.
      </div>
    </div>
  );
};

const FooterAddress = () => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <div className={sectionTitle}>Address</div>
      <div
        style={{
          color: "rgba(255,255,255,.5)",
        }}
        className={"bold"}
      >
        Plot no: 55A,FF-2, Shirdi Sai Baba temple, Seethammadhara, Visakhapatnam
        - 530013, Andhra Pradesh
      </div>
      <div
        style={{
          color: "rgba(255,255,255,.5)",
        }}
        className={"bold"}
      >
        CIN: U72900AP2021PTC120210
      </div>
    </div>
  );
};

const FooterQuickLinks = () => {
  const linkMap = [
    {
      heading: "About",
      url: "https://vaapas.in",
    },
    {
      heading: "How it works?",
      url: "https://www.vaapas.in/#how_it_works",
    },
    {
      heading: "FAQs",
      url: "https://vaapas.in#faq",
    },
    {
      heading: "Terms & Conditions",
      url: "https://caset.notion.site/Terms-of-Use-2ee075c1f06d4f8a83f41bca8ceb6748",
    },
    {
      heading: "Privacy Policies",
      url: "https://caset.notion.site/Privacy-Policy-d96f5298e92e4f219c6d9518fe9ad3ae",
    },
  ];
  return (
    <div>
      <div className={sectionTitle} style={{ fontSize: 16 }}>
        Quick Links
      </div>
      <div className={quickLinks}>
        {linkMap.map((l, i) => {
          return (
            <a key={i} className={"bold " + link} href={l.url}>
              {l.heading}
            </a>
            // <div className={"link"}>
            // </div>
          );
        })}
      </div>
    </div>
  );
};

const FooterSocialLinks = () => {
  const socialLinkMap = [
    {
      icon: "akar-icons:instagram-fill",
      url: "https://www.instagram.com/caset_in/",
    },
    {
      icon: "akar-icons:linkedin-box-fill",
      url: "https://www.linkedin.com/company/caset/",
    },
    {
      icon: "akar-icons:facebook-fill",
      url: "https://www.facebook.com/Caset-104825232260574/",
    },
    {
      icon: "akar-icons:twitter-fill",
      url: "https://twitter.com/Caset28857932",
    },
  ];
  return (
    <div>
      <div className={sectionTitle}>Find Us</div>
      <div className="flex" style={{ gap: 10, marginTop: 10 }}>
        {socialLinkMap.map((s, i) => {
          return (
            <a key={i} href={s.url} target={"_blank"} rel="noreferrer">
              <Iconify icon={s.icon} width={25} height={25} color={grey} />
            </a>
          );
        })}
      </div>

      <div style={{ marginTop: 20 }}>
        <div className={sectionTitle}>Mail Us</div>
        <a
          className={"bold " + link}
          style={{ paddingTop: 10, display: "block" }}
          href={"mailto:support@vaapas.in"}
        >
          support@vaapas.in
        </a>
      </div>
    </div>
  );
};

const FooterTerms = () => {
  const termsLinks = [
    {
      title: "Terms & Conditions",
      url: "https://caset.notion.site/Terms-of-Use-2ee075c1f06d4f8a83f41bca8ceb6748",
    },
    {
      title: "Privacy Policies",
      url: "https://caset.notion.site/Privacy-Policy-d96f5298e92e4f219c6d9518fe9ad3ae",
    },
  ];

  return (
    <div className="flex" style={{ gap: 10 }}>
      {termsLinks.map((t, i) => {
        return (
          <a
            key={i}
            href={t.url}
            target={"_blank"}
            rel="noreferrer"
            className={"bold " + link}
          >
            {t.title}
          </a>
        );
      })}
    </div>
  );
};

const footerContainer = css`
  display: flex;
  gap: 1rem;
  padding: 0px 1rem;
  margin: 1rem 0px;
`;
const Footer = () => {
  return (
    <footer>
      <div className="divider"></div>

      <div className={footerContainer}>
        {/* left */}
        <div className={`${baseSectionStyle} `}>
          {/* Logo */}
          <FooterLogo />
          {/* Address */}
          <FooterAddress />
        </div>
        {/* right */}
        <div className={`${baseSectionStyle}  `} style={{ paddingTop: 45 }}>
          {/* Quick Links */}
          <FooterQuickLinks />
          {/* Social Media Links */}
          <FooterSocialLinks />
        </div>
      </div>
      <div className="divider"></div>
      <div
        className={
          footerContainer +
          " bold " +
          css`
            color: ${grey};
            text-align: justify;
          `
        }
        style={{ padding: "10px 3rem" }}
      >
        It is our endeavor to comply with all extant laws applicable to us in
        relation to the services offered through this platform (including our
        website and mobile application): (i) Caset is not, nor does it claim or
        portray to be, an NBFC or lender. Caset is not registered with the RBI.
        (ii) Caset is not a stock exchange recognized by the Securities and
        Exchange Board of India (SEBI) under the Securities Contract
        (Regulations) Act, 1956. Caset is not a fundraising platform. Caset,
        through the platform, merely facilitates the interaction between
        investors and brands in a private and restricted environment. No
        securities are offered by any company on the platform. Please read the
        terms of use prior to using this platform and any services offered
        herein.
        {/* Terms and privacy */}
        {/* <FooterTerms />
        <div className="grow" />
        <div
          className={
            sectionTitle +
            " " +
            css`
              color: rgba(255, 255, 255, 0.5);
            `
          }
        >
          Made with love in India
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
