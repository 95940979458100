import {
  removeTimeDataFromDate,
  formatDate,
  convertToIntlCurSys,
  formatter,
} from "../../util/helper";
import { IconProps } from "@iconify/react";
import { css } from "@emotion/css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Caset,
  Business,
  CasetWithUserCasetInfo,
  RoyaltyFeed,
  RoyaltyFeedAPIResp,
} from "../../interfaces/caset";
import Iconify from "../../components/Iconify";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Charges } from "../../interfaces/caset.charges";
import { CasetSalesStats } from "../../interfaces/caset.sales";
import { UserWallet } from "../../interfaces/wallet";
import { useAuth } from "../../context/auth";
import {
  VictoryChart,
  VictoryTheme,
  VictoryArea,
  VictoryVoronoiContainer,
  VictoryAxis,
} from "victory";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useGlobal } from "../../context/global";
import Loader from "../../components/Loader";
import theme from "../../util/theme";
import { screenHeight } from "../../layout/BaseLayout";
import { CustomTooltip } from "./ExploreCaset";
import FAQs from "../../components/FAQs";
import RiskFactors from "../../components/RiskFactors";
import SampleDocuments from "../../sections/SampleDocuments";
import PartnershipDocuments from "../../sections/PartnerDouments";

interface Props {
  navigation: any;
  route: any;
}

interface CashfreePaymentResponse {
  cftoken: string;
  order: {
    orderId: string;
    orderAmount: string;
    orderCurrency: string;
  };
  notifyUrl: string;
  env: string;
  order_note: string;
  appId: string;
}

interface CasetDetailElement {
  icon: IconProps;
  title: string;
  value: string;
}

const currentDate: Date = new Date();

const subtractMonthFromDate = (date: Date, months: number) => {
  const d = new Date(date);
  d.setMonth(d.getMonth() - months + 1);
  return d;
};

const monthEnds = (d: Date) => {
  d.setDate(1);
  removeTimeDataFromDate(d);
  const startDate = new Date(d);
  d.setMonth(d.getMonth() + 1);
  d.setDate(d.getDate() - 1);
  return {
    start: startDate,
    end: d,
  };
};

export const salesDataIntervals = [
  {
    title: formatDate(subtractMonthFromDate(currentDate, 1), "mmm"),
    id: 1,
    months: 1,
    ...monthEnds(subtractMonthFromDate(currentDate, 1)),
  },
  {
    title: formatDate(subtractMonthFromDate(currentDate, 2), "mmm"),
    id: 2,
    months: 2,
    ...monthEnds(subtractMonthFromDate(currentDate, 2)),
  },
  {
    title: formatDate(subtractMonthFromDate(currentDate, 3), "mmm"),
    id: 3,
    months: 3,

    ...monthEnds(subtractMonthFromDate(currentDate, 3)),
  },
  {
    title: formatDate(subtractMonthFromDate(currentDate, 4), "mmm"),
    id: 4,
    months: 4,
    ...monthEnds(subtractMonthFromDate(currentDate, 4)),
  },
];

const primary = css`
  font-weight: bold;
  color: ${theme.color.primary};
`;

const HistoryCaset = () => {
  const navigate = useNavigate();

  // const history = u
  const { state } = useLocation();
  const { authRequest, authData } = useAuth();
  const { isMobile } = useGlobal();
  const casetData: CasetWithUserCasetInfo = state as any;
  const misc = casetData.businessBranch.business.misc || {};
  const userCasetId = casetData.userCasets[0].id;
  const { id } = useParams();
  const [noOfMonths, SetNoOfMonths] = useState(1);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [casetSalesData, setCasetSalesData] = useState<CasetSalesStats | null>(
    null
  );
  const [yAxisOffset, setYAxisOffset] = useState(0);
  const [chargesData, setChargesData] = useState<Charges[]>([]);
  const [walletData, setWalletData] = useState<UserWallet | null>(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountWithCharges, setTotalAmountWithCharges] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { showToast } = useGlobal();
  const user = authData?.user;
  const [quantity, setQuantity] = useState("1");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalEarned, setTotalEarned] = useState(0);
  const [royalFeedData, setRoyalFeedData] = useState<RoyaltyFeed[]>([]);

  useEffect(() => {
    getPageData();
    var tq = 0;
    casetData.userCasets.forEach((uc) => {
      tq += uc.casetQuantity;
    });
    setTotalQuantity(tq);
  }, []);

  useEffect(() => {
    let ta = Number(quantity || 0) * casetData.unitPrice;
    let chargeAmount = 0;
    // chargesData.forEach((item: Charges) => {
    //   if (item.chargeType == 'percentage') {
    //     chargeAmount += Number((ta * (item.value / 100)).toFixed(2));
    //   } else if (item.chargeType == 'fixed') {
    //     chargeAmount += item.value;
    //   }
    // });
    setTotalAmount(ta);
    setTotalAmountWithCharges(ta + chargeAmount);
  }, [quantity, casetData, chargesData]);

  useEffect(() => {
    // getCasetSalesData();
  }, [noOfMonths]);

  if (!casetData) {
    navigate("/my_casets", { replace: true });
  }
  const business: Business = casetData.businessBranch.business;

  const getPageData = () => {
    const promises = [];
    promises.push(getRoyaltyFeed());
    // promises.push(getCasetSalesData());
    promises.push(
      getCasetSalesDataBetweenMonths(
        salesDataIntervals[0].start,
        salesDataIntervals[0].end
      )
    );
    // promises.push(getWalletData());
    setIsRefreshing(true);
    Promise.all(promises)
      .then(() => {
        setIsRefreshing(false);
      })
      .catch((_err) => {
        setIsRefreshing(false);
      });
  };

  const getRoyaltyFeed = () => {
    return authRequest(`user/caset/${userCasetId}/feed`, "GET", {})
      .then((data: RoyaltyFeedAPIResp) => {
        setTotalEarned(data.totalEarned);
        return setRoyalFeedData(data.royaltyFeed);
      })
      .catch((_err: any) => {});
  };

  const getWalletData = () => {
    return authRequest("user/wallet", "GET", {})
      .then((data: UserWallet) => {
        setWalletData(data);
      })
      .catch((err: any) => {});
  };

  const getCharges = () => {
    return authRequest("charges/caset", "GET", {})
      .then((data: Charges[]) => {
        setChargesData(data);
      })
      .catch((_err: any) => {});
  };

  const getCasetSalesData = (months = 1) => {
    setIsRefreshing(true);
    return authRequest(
      `caset/${casetData.id}/sales?months=${months || noOfMonths}`,
      "GET",
      {}
    )
      .then((data: CasetSalesStats) => {
        setCasetSalesData(data);
        let minAmount =
          data.current_month_stats && data.current_month_stats.length
            ? data.current_month_stats[0].amount
            : 0;
        (data.current_month_stats || []).forEach((v) => {
          if (v.amount < minAmount) {
            minAmount = v.amount;
          }
        });
        setYAxisOffset(minAmount * (80 / 100));
        setIsRefreshing(false);
      })
      .catch((_err: any) => {});
  };

  const getCasetSalesDataBetweenMonths = useCallback(
    (start: Date, end: Date) => {
      let s: Date = new Date(start);
      s.setMonth(start.getMonth() - 4);
      let e: Date = new Date(end.toDateString());
      e.setMonth(end.getMonth() - 4);
      setIsRefreshing(true);
      return authRequest(
        `caset/${
          casetData.id
        }/sales/dates?start=${s.toISOString()}&end=${e.toISOString()}`,
        "GET",
        {}
      )
        .then((data: CasetSalesStats) => {
          setCasetSalesData(data);
          let minAmount =
            data.current_month_stats && data.current_month_stats.length
              ? data.current_month_stats[0].amount
              : 0;
          (data.current_month_stats || []).forEach((v) => {
            if (v.amount < minAmount) {
              minAmount = v.amount;
            }
          });
          setYAxisOffset(minAmount * (80 / 100));
          setIsRefreshing(false);
        })
        .catch((_err: any) => {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [casetData.id]
  );

  const hidePaymentModal = () => {
    setShowPaymentModal(false);
  };

  const royaltyExtraInfoSection = useMemo(() => {
    return <></>;
    let totalUnitsBought = 0;
    casetData.userCasets.forEach((u) => {
      totalUnitsBought += u.casetQuantity;
    });
    return (
      <div className="flex">
        <div className="flex-col">
          <div className="key">My Royalty %</div>
          <div className="key">
            {(casetData.unitPercentReturns * totalUnitsBought).toFixed(2)}%
          </div>
        </div>
        <div className="grow" />
        <div className="flex-col">
          <div className="key">Invested Amt.</div>
          <div className="key">
            {formatter.format(
              casetData.userCasets.reduce((p, uc) => {
                return p + uc.payment.amount;
              }, 0)
            )}
          </div>
        </div>
      </div>
    );
  }, []);
  const renderBusinessSales = useMemo(() => {
    return (
      <>
        {/* Business sales data */}
        <div style={{ padding: "20px 0px" }}>
          <div style={{ paddingBottom: "30px" }}>Insights</div>
          <div className="flex" style={{ justifyContent: "space-evenly" }}>
            {salesDataIntervals.map((v, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    SetNoOfMonths(v.months);
                    getCasetSalesDataBetweenMonths(v.start, v.end);
                  }}
                  unselectable="on"
                  // onselectstart="return false;"
                  // onmousedown="return false;"
                  className={css`
                    background: ${noOfMonths === v.months
                      ? theme.color.primary
                      : "white"};
                    color: black;
                    padding: 5px 10px;
                    letter-spacing: 1px;
                    border-radius: 15px;
                    pointer-events: all;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    -o-user-select: none;
                    &:focus {
                      opacity: 0.7;
                    }
                  `}
                >
                  {v.title}
                </div>
              );
            })}
          </div>
        </div>
        {/* Business sales graph */}
        {casetSalesData &&
        casetSalesData.current_month_stats &&
        casetSalesData.current_month_stats.length ? (
          <div className="flex-col" style={{ gap: "2rem" }}>
            <div className="flex" style={{ justifyContent: "space-between" }}>
              {[
                {
                  value:
                    convertToIntlCurSys(
                      casetSalesData?.last_month_data.sale || 0
                    ) + "+",
                  key: "Bills",
                },
                {
                  value:
                    convertToIntlCurSys(
                      Number(
                        (
                          (casetSalesData?.last_month_data.amount || 0) /
                          (casetSalesData?.last_month_data.sale || 1)
                        ).toFixed(2)
                      )
                    ) + "+",
                  key: "Avg\nBill size",
                },
                {
                  value: (
                    <div className="flex" style={{ fontSize: 20 }}>
                      {convertToIntlCurSys(
                        casetSalesData?.last_month_data.amount || 0
                      ) + "+"}
                    </div>
                  ),
                  // key: `${casetData.businessBranch.rating.source} Rating`,
                  key: "Sales",
                },
              ].map((d, i) => {
                return (
                  <div
                    key={i}
                    className={
                      css`
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        background-color: #3a3a47;
                      ` + " flex-col center"
                    }
                  >
                    <div
                      className={`bold ${primary}`}
                      style={{ textAlign: "center", fontSize: 20 }}
                    >
                      {d.value}
                    </div>
                    <div
                      className={`bold ${primary}`}
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#AAAAAA",
                        textTransform: "capitalize",
                        whiteSpace: "pre",
                      }}
                    >
                      {d.key}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="bold" id="salesFeed">
              Sales Feed
            </div>
            {/* Chart */}
            <ResponsiveContainer width={"100%"} height={200}>
              <AreaChart
                width={350}
                height={200}
                data={casetSalesData.current_month_stats.map((d) => {
                  return {
                    name: d.proper_date,
                    uv: Number(d.amount.toFixed()),
                    pv: Number(d.amount.toFixed()),
                    // x: "",
                    value: Number(d.amount.toFixed()),
                    // label: `${d.proper_date}\n${Number(d.amount.toFixed())}`,
                  };
                })}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="name" tick={false} />
                <YAxis width={40} tick={{ fontSize: 8 }} />
                <Tooltip content={CustomTooltip} />
                <Area
                  type="monotone"
                  dataKey="uv"
                  // stroke="#00ff83"
                  // fill="rgba(20,105,81,0.3)"
                  stroke={theme.color.primary}
                  fill="#f472221a"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        ) : null}
      </>
    );
  }, [casetSalesData, getCasetSalesDataBetweenMonths, noOfMonths]);

  const [linkIds, setLinkIds] = useState<string[]>([]);

  useEffect(() => {
    const elements = linkIds.map((l) => {
      return document.querySelector(l);
    });

    const aLinks = document.querySelectorAll("#sideLinks a");
    const navbar = document.getElementById("navbar");
    let navbarHeight = 0;
    if (navbar) {
      navbarHeight = navbar.clientHeight;
    }
    const sideLink = document.getElementById("sideLinks");

    window.onscroll = (e) => {
      let isSideContainerOnTop = false;
      let currentElementId: string = "";
      elements.forEach((e) => {
        if (e) {
          const bounds = e.getBoundingClientRect();
          if (bounds.top < navbarHeight + 50) {
            isSideContainerOnTop = true;
          }
          if (bounds.top < navbarHeight) {
            currentElementId = e.id;
          }
        }
      });

      aLinks.forEach((e) => {
        if (e) {
          e.classList.remove("active");
        }
      });

      elements.forEach((e) => {
        if (e && e.id !== currentElementId) {
          e.classList.remove("active");
        }
      });

      const currentActive = document.querySelector(
        `#${currentElementId || "sr"}`
      );

      if (isSideContainerOnTop && currentElementId) {
        const link = document.querySelector(`a[href='#${currentElementId}']`);
        if (link) {
          link.classList.add("active");
        }
        if (currentActive) {
          currentActive.classList.add("active");
        }

        if (sideLink) {
          sideLink.classList.add("active");
          sideLink.style.position = "fixed";
          sideLink.style.top = navbarHeight + "px";
        }
      } else {
        if (sideLink) {
          sideLink.classList.remove("active");
          sideLink.style.position = "relative";
          sideLink.style.top = 0 + "px";
        }
      }
    };

    return () => {
      // elements.forEach((ele) => {
      //   if (ele) {
      //     observer.unobserve(ele);
      //   }
      // });
    };

    //   const observer = new IntersectionObserver(entries => {
    //     entries.forEach(entry => {
    //         if (entry.isIntersecting) {
    //             entry.target.classList.add('opacity');
    //             // entry.target.classList.add('animated');
    //             // entry.target.classList.add('fadeInRight');
    //             const target = entry.target;
    //             // console.log(target.dataset.index, sideParallaxDevice.style.backgroundImage, backgroundImageMap[target.dataset.index] );
    //             if (sideParallaxDevice.style.backgroundImage == backgroundImageMap[target.dataset.index]) {
    //                 console.log('same_background');
    //                 return;
    //             }
    //             const device = document.querySelector('.image-side-content-container .device-wrapper .screen');
    //             device.classList.remove('fadeInRight');
    //             device.classList.remove('animated');
    //             setTimeout(() => {
    //                 device.classList.add('animated');
    //                 device.classList.add('fadeInRight');
    //                 sideParallaxDevice.style.backgroundImage = backgroundImageMap[target.dataset.index];

    //             }, 500);
    //         } else {
    //             entry.target.classList.remove('opacity');
    //             entry.target.classList.remove('animated');
    //             entry.target.classList.remove('fadeInRight');
    //         }
    //     });
    // }, { threshold: 0.7 });
    // sideContents.forEach((card) => {
    //     observer.observe(card);
    // });
  }, [linkIds]);

  const renderSideLinks = useMemo(() => {
    const links: any = [];
    const misc = casetData.businessBranch.business.misc;
    const desc = misc.desc || "";
    const points = misc.points || [];
    const images = misc.images || [];
    // if (misc.desc || points.length || images.length) {
    //   links.push({
    //     title: "About",
    //     to: "#about",
    //   });
    // }
    const defaultLinks: any = [
      // {
      //   title: "Risk Factors",
      //   to: "#risk_factors",
      // },
      // {
      //   title: "FAQs",
      //   to: "#faq",
      // },
      // {
      //   title: "Partner Documents",
      //   to: "#partner_documents",
      // },
    ];

    const allLinks = [...links, ...defaultLinks];

    setLinkIds(allLinks.map((l) => l.to));

    return (
      <div
        id={"sideLinks"}
        className={css`
          display: flex;
          min-width: 220px;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem;
          margin-top: 1rem;
          transition: font-size ease-in-out 400ms;
          & a {
            text-decoration: none;
            color: white;
          }

          &.active {
            padding-top: 0px;
            margin-top: 0px;
          }

          & a.active {
            color: ${theme.color.primary};
            font-size: 20px;
          }
        `}
      >
        {allLinks.map((link, i) => {
          return (
            <a
              className="bold"
              href={link.to}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                var element = document.querySelector(link.to);
                if (!element) return;
                const navbar = document.getElementById("navbar");
                var headerOffset = navbar ? navbar.clientHeight : 100;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition =
                  elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                  top: offsetPosition,
                  behavior: "smooth",
                });
              }}
            >
              {link.title}
            </a>
          );
        })}
      </div>
    );
  }, [casetData.businessBranch.business.misc]);

  const renderCasetBusinessInfoSection = useMemo(() => {
    const misc = casetData.businessBranch.business.misc;
    const desc = misc.desc || "";
    const points = misc.points || [];
    const images = misc.images || [];
    if (!(misc.desc || points.length || images.length)) {
      return null;
    }
    return (
      <div
        id="about"
        className={css`
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          & .heading {
            /* font-size: 20px; */
            /* color: ${theme.color.primary}; */
          }

          &.active .heading {
            color: ${theme.color.primary};
          }
        `}
      >
        <div className="heading">
          {casetData.businessBranch.business.name
            ? `About ${casetData.businessBranch.business.name || ""}`
            : ""}
        </div>
        <div className="divider" style={{ transform: "scale(1)" }} />
        {desc ? <div className="desc">{desc}</div> : null}
        <div className="point">
          <ul>
            {points.map((p) => {
              return <li>{p}</li>;
            })}
          </ul>
        </div>
        <div
          className={css`
            display: flex;
            gap: 1rem;
            /* padding: 1rem; */
            justify-content: flex-start;
            flex-wrap: wrap;
          `}
        >
          {images.map((i, index) => {
            return <img src={i} alt={index + ""} width={200} />;
          })}
        </div>
      </div>
    );
  }, [
    casetData.businessBranch.business.misc,
    casetData.businessBranch.business.name,
  ]);

  const businessDataSection = useMemo(() => {
    return (
      <div
        style={{
          marginTop: isMobile ? 65 : 0,
        }}
      >
        <div className="flex center">
          <div className={"bold "} style={{ fontSize: 24 }}>
            {business.name}
          </div>
          <div className="grow"></div>
          <div style={{ fontSize: 16 }}>
            {casetData.expectedUpside ? "Exp. CAGR" : null}
          </div>
        </div>
        <div className="flex center">
          <div className="flex center">
            <div className={"bold " + primary} style={{ fontSize: 24 }}>
              {business && business.location && business.location.mapLink ? (
                <Iconify
                  icon={"bxs:map"}
                  color={theme.color.primary}
                  fontSize={18}
                />
              ) : null}
            </div>
            <div className={primary}>
              {casetData.businessBranch.subLocation}
            </div>
          </div>
          <div className="grow"></div>
          <div className={primary}>
            {casetData.expectedUpside ? (
              <div className="flex center" style={{ gap: 3 }}>
                <Iconify
                  icon={"bi:arrow-up-circle-fill"}
                  color={theme.color.primary}
                  fontSize={18}
                />{" "}
                <span>{casetData.expectedUpside}</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }, [
    business,
    casetData.businessBranch.subLocation,
    casetData.expectedUpside,
  ]);

  return (
    <div
      className={`bold ${css`
        padding: 20px 24px;
        color: white;
        height: ${isMobile ? screenHeight : "unset"};
        overflow-y: ${isMobile ? "scroll" : "unset"};
      `}`}
    >
      <Loader loading={isRefreshing} />
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          top: 0,
          marginBottom: "1rem",
        }}
        className={
          css`
            position: ${isMobile ? "fixed" : "relative"};
            padding-top: 1rem;
            z-index: 2;
            width: 100%;
          ` + " blur"
        }
      >
        <div
          style={{
            marginLeft: -10,
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <Iconify icon={"eva:arrow-ios-back-fill"} fontSize={30} />
        </div>
        <div className="flex-col" style={{}}>
          {misc.brandImage ? (
            <img
              src={misc.brandImage}
              width={63}
              style={{ borderRadius: "17%" }}
              alt="Brand"
            />
          ) : null}
        </div>
      </div>
      {/* business info section */}

      {isMobile ? (
        <>
          {businessDataSection}
          {/* Business sales data */}
          {renderBusinessSales}

          {/* Royalty Feed Section */}
          <div>{royaltyExtraInfoSection}</div>

          <div
            className="bold flex"
            style={{ paddingBottom: 10, paddingTop: 20 }}
          >
            <div>Royalty Feed</div>
            <div className="grow" />
            <div style={{ color: theme.color.primary }} className={"bold"}>
              {formatter.format(totalEarned)}
            </div>
          </div>
          {royalFeedData.map((d, i) => {
            return (
              <div
                className={`flex ${css`
                  padding: 10px 0px;
                  justify-content: center;
                `}`}
              >
                <span style={{ fontSize: 13 }}>
                  Credited
                  <span style={{ color: theme.color.primary }}>
                    {" " + formatter.format(d.amount) + " "}
                  </span>
                  to your wallet
                </span>
                <div className="grow" />
                <span style={{ fontSize: 8, color: "white" }}>
                  {formatDate(new Date(d.createdAt), "HH:MM, dS mmmm yy")}
                </span>
              </div>
            );
          })}
        </>
      ) : (
        <div
          className={css`
            display: flex;
            gap: 2rem;
          `}
        >
          <div
            className={css`
              flex: 50%;
            `}
          >
            {businessDataSection}
            {/* Business sales data */}
            {renderBusinessSales}
          </div>

          <div
            className={
              css`
                flex: 50%;
              ` + " grow"
            }
          >
            {royaltyExtraInfoSection}
            {/* Royalty Feed Section */}
            <div
              className="bold flex"
              style={{ paddingBottom: 10, paddingTop: 20 }}
            >
              <div>Royalty Feed</div>
              <div className="grow" />
              <div style={{ color: theme.color.primary }} className={"bold"}>
                {formatter.format(totalEarned)}
              </div>
            </div>
            {royalFeedData.map((d, i) => {
              return (
                <div
                  className={`flex ${css`
                    padding: 10px 0px;
                    justify-content: center;
                  `}`}
                >
                  <span style={{ fontSize: 13 }}>
                    Credited
                    <span style={{ color: theme.color.primary }}>
                      {" " + formatter.format(d.amount) + " "}
                    </span>
                    to your wallet
                  </span>
                  <div className="grow" />
                  <span style={{ fontSize: 8, color: "white" }}>
                    {formatDate(new Date(d.createdAt), "HH:MM, dS mmmm yy")}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div></div>

      {isMobile ? (
        <div
          className={css`
            padding-top: 2rem;
          `}
        >
          {/* Business and term Details */}
          {/* {renderCasetBusinessInfoSection} */}

          {/* Risk Factor */}
          {/* <RiskFactors /> */}

          {/* FAQs */}
          {/* <FAQs /> */}

          {/* <SampleDocuments /> */}
          {/* <PartnershipDocuments /> */}
        </div>
      ) : (
        <div
          className={css`
            display: flex;
            padding-top: 2rem;
          `}
        >
          <div
            style={{
              minWidth: 220,
              position: "relative",
            }}
          >
            {renderSideLinks}
          </div>
          <div className="grow">
            {/* Business and term Details */}
            {/* {renderCasetBusinessInfoSection} */}

            {/* Risk Factor */}
            {/* <RiskFactors /> */}

            {/* FAQs */}
            {/* <FAQs /> */}

            {/* <SampleDocuments /> */}
            {/* <PartnershipDocuments /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default HistoryCaset;
