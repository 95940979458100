import { css } from "@emotion/css";
import React from "react";
import { useNavigate } from "react-router-dom";
import Iconify from "./Iconify";
import theme from "../util/theme";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div
      className={css`
        display: flex;
      `}
    >
      <div
        onClick={() => {
          navigate(-1);
        }}
      >
        <Iconify
          icon={"eva:arrow-ios-back-fill"}
          fontSize={30}
          color={"white" || theme.color.primary}
        />
      </div>
    </div>
  );
};

export default BackButton;
