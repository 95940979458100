import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/auth";
import { GlobalProvider } from "./context/global";
import { HelmetProvider } from "react-helmet-async";
import Routes from "./routes";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";

function App() {
  const setVhProperty = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // const links = document.getElementsByTagName("link");
    // for (let i = 0; i < links.length; i++) {
    //   const link = links[i];
    //   if (link.rel === "stylesheet") {
    //     link.href += "";
    //   }
    // }

    // document.body.style.height = "calc(calc(var(--vh, 1vh) * 100)";
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setVhProperty();
    });
    setVhProperty();
  }, []);

  return (
    <HelmetProvider>
      <Toaster />
      <GlobalProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </AuthProvider>
      </GlobalProvider>
    </HelmetProvider>
  );
}

export default App;
