/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import { User } from "../interfaces/user";
import CustomButton from "../components/CustomButton";
import Iconify from "../components/Iconify";
import Loader from "../components/Loader";
import { useAuth } from "../context/auth";
import { useGlobal } from "../context/global";
import theme from "../util/theme";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

const steps = [
  {
    label: "Aadhaar Proof",
  },
  {
    label: "PAN Card",
  },
  {
    label: "Bank Details",
  },
];

const KYCUpdateScreen = () => {
  const navigate = useNavigate();
  const { authData, authRequest, updateUserData } = useAuth();
  const { showToast } = useGlobal();
  const user = authData?.user || {
    name: "",
    email: "",
    bankAccount: "",
    upiAddress: "",
    ifsc: "",
    accountVerified: false,
    kycJson: {
      aadhaarNo: "",
      aadhaarVerified: false,
      panNo: "",
      panVerified: false,
    },
  };

  const [userName, setUserName] = useState((user && user.name) || "");

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(user.email || "");
  const [emailOTPSent, setEmailOTPSent] = useState(false);
  const [emailOTP, setEmailOTP] = useState("");
  const [emailOTPSentTime, setEmailOTPSentTime] = useState<Date | null>(null);
  const [kycData, setKycData] = useState({
    aadhaarNo: "",
    panNo: "",
  });
  const [aadhaarNo, setAadhaarNo] = useState<string | null>(null);
  const [aadhaarVerified, setAadhaarVerified] = useState(false);
  const [panNo, setPANNo] = useState<string | null>(null);
  const [panVerified, setPANVerified] = useState(false);
  const [paymentInfoData, setPaymentInfoData] = useState({
    bankAccount: "",
    ifsc: "",
    upiAddress: "",
  });

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    getMe();
    setPaymentInfoData({
      bankAccount: user.bankAccount,
      ifsc: user.ifsc,
      upiAddress: user.upiAddress,
    });
  }, []);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setUserName(user.email);
      const kycJson = user.kycJson;
      if (panNo === null && kycJson.panNo) {
        setPANNo(kycJson.panNo);
      }
      if (aadhaarNo === null && kycJson.aadhaarNo) {
        setAadhaarNo(kycJson.aadhaarNo);
      }
      setAadhaarVerified(kycJson.aadhaarVerified);
      setPANVerified(kycJson.panVerified);
      if (
        user.accountVerified &&
        kycJson.aadhaarVerified &&
        kycJson.panVerified
      ) {
        setActiveStep(3);
        navigate("/my_profile", { replace: true });
      } else if (kycJson.panVerified) {
        setActiveStep(2);
      } else if (kycJson.aadhaarVerified) {
        setActiveStep(1);
      }
    }
  }, [user]);

  const getMe = () => {
    setIsLoading(true);
    return authRequest("user/me", "GET", {})
      .then((data: User) => {
        setIsLoading(false);
        // console.log
        updateUserData(data);
      })
      .catch((err: any) => {
        setIsLoading(false);
        showToast(err.message || "Error");
      });
  };

  const saveAndVerifyPaymentDetails = () => {
    setIsLoading(true);
    return authRequest("user/payment_details/verify", "POST", paymentInfoData)
      .then(async () => {
        setIsLoading(false);
        await getMe();
        // goBack(props.navigation);
      })
      .catch(async (err: any) => {
        setIsLoading(false);
        await getMe();
        toast.error(err.message || "Error");
      });
  };

  const saveAndVerifyAadhaar = () => {
    setIsLoading(true);
    authRequest("user/kyc/aadhaar/verify", "POST", {
      aadhaarNo: aadhaarNo,
    })
      .then(async () => {
        setIsLoading(false);
        getMe();
      })
      .catch(async (err: any) => {
        setIsLoading(false);
        getMe();
        toast.error(err.message || "Error");
      });
  };

  const saveAndVerifyPAN = () => {
    setIsLoading(true);
    authRequest("user/kyc/pan/verify", "POST", {
      panNo: panNo,
    })
      .then(async () => {
        setIsLoading(false);
        getMe();
      })
      .catch(async (err: { message?: string }) => {
        setIsLoading(false);
        getMe();
        toast.error((err.message || "Error").toUpperCase());
      });
  };
  // const saveAndVerifyKyc = () => {
  //   setIsLoading(true);
  //   authRequest("user/kyc/verify", "POST", kycData)
  //     .then(async () => {
  //       setIsLoading(false);
  //       getMe();
  //     })
  //     .catch(async (err: any) => {
  //       setIsLoading(false);
  //       getMe();
  //       showToast(err.message || "Error");
  //     });
  // };

  const InputField = (
    title = "",
    value = "",
    placeholder = "",
    onChange: ((text: string) => void) | undefined = undefined,
    endAdornment: JSX.Element | undefined = undefined,
    disable = false,
    startAdornment: JSX.Element | undefined = undefined
  ) => {
    return (
      <>
        {title ? (
          <span
            className={css`
              display: flex;
              @media only screen and (max-width: 500px) {
                font-size: 2rem !important;
              }
            `}
            style={{
              color: theme.color.primary,
              fontSize: activeStep != 2 ? "2.5rem" : "1.5rem",
              marginBottom: 8,
              fontWeight: "bold",
            }}
          >
            {title}
          </span>
        ) : null}
        <div
          className="flex center"
          style={{
            padding: "4px 10px",
            maxWidth: 500,
            height: 48,
            borderRadius: 4,
            backgroundColor: "white",
            borderWidth: 1,
            marginTop: activeStep != 2 ? "2rem" : "1rem",
            marginBottom: 8,
            opacity: disable ? 0.6 : 1,
          }}
        >
          {startAdornment || null}
          <input
            className={`bold grow  ${css`
              min-height: 48px;
              background-color: transparent;
              border-color: transparent;
              padding: 0 10px;
              flex-grow: 1;
              &:focus {
                outline: none;
              }
            `}`}
            style={{
              color: "black",
              fontSize: 16,
              // backgroundColor: 'white',
              // textAlignVertical: 'center',
              height: 40,
            }}
            // onChangespan={onChange}
            onChange={(e) => {
              if (onChange) onChange(e.target.value);
            }}
            value={value}
            disabled={disable}
            // onChangespan={setChangedValue}
            // placeholder={props.placeholder || ''}
            placeholder={placeholder}
            // placeholderspanColor={"#BFBFBF"}
          />
          {endAdornment || null}
        </div>
      </>
    );
  };

  if (!user) {
    return (
      <div
        style={{
          backgroundColor: theme.color.primary_bg_2,
          width: "100%",
          height: "100%",
        }}
      />
    );
  }

  return (
    <div
      className={css`
        display: flex;
        // justify-content: center;
      `}
    >
      <Loader loading={isLoading} />
      <div
        style={{
          backgroundColor: theme.color.primary_bg_2,
          // background: 'white',
          padding: "20px 16px",
          width: "100%",
          // maxWidth: 500,
          height: "100%",
        }}
      >
        <div>
          <div className="flex">
            <Iconify
              icon={"akar-icons:chevron-left"}
              style={{ marginLeft: 20 }}
              color={theme.color.primary}
              fontSize={30}
              onClick={() => {
                // goBack(props.navigation);
                navigate(-1);
                // navigate("/my_profile", { replace: true });
                // vaapasNavigation(props.navigation, SCREEN_MAP.PROFILE, {});
              }}
            />
            <span className="bold" style={{ color: "white", fontSize: 24 }}>
              Complete KYC
            </span>
          </div>
          {/* <div
            onClick={() => {
              // goBack(props.navigation);
              navigate(-1);
              // navigate("/my_profile", { replace: true });
              // vaapasNavigation(props.navigation, SCREEN_MAP.PROFILE, {});
            }}
            className={"flex-col"}
            style={{
              // paddingRight: 20,
              // paddingLeft: 2,
              // backgroundColor: 'red',
              height: 30,
              width: 30,
              borderRadius: 15,
              alignContent: "center",
              justifyContent: "center",
              // marginBottom: 20,
              // alignItems: 'center',
            }}
          >
            <Iconify
              icon={"akar-icons:chevron-left"}
              color={theme.color.primary}
              fontSize={24}
            />
          </div> */}
          {/* <span
            style={[
              GLOBAL_STYLES.bold,
              {color: theme.color.light, fontSize: 24, bottom: 8},
            ]}>
            Personal Information
          </span> */}
        </div>

        {user ? (
          <>
            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                padding: "5rem",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={css`
                @media only screen and (max-width: 575px) {
                  padding: 2rem !important;
                }
              `}
            >
              <div
                className={css`
                  margin-top: 2rem;
                  @media only screen and (max-width: 767px) {
                    display: flex;
                    justify-content: start;
                    margin-bottom: 2rem;
                  }
                  @media only screen and (min-width: 768px) {
                    display: none;
                  }
                `}
              >
                <Stepper activeStep={activeStep} orientation="horizontal">
                  {steps.map((step, index) => (
                    <Step
                      key={step.label}
                      sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "#00df9a", // circle color (COMPLETED)
                        },
                        "& .MuiStepLabel-root .Mui-completed  .MuiStepIcon-text":
                          {
                            color: "#00df9a", // circle color (COMPLETED)
                          },
                        "& .MuiStepLabel-root .Mui-disabled": {
                          color: "darkgrey", // circle color (COMPLETED)
                        },
                        "& .Mui-disabled > span > svg >  circle": {
                          color: "darkgrey", // circle color (COMPLETED)
                        },
                        "& .MuiStepIcon-text": {
                          color: "black !important", // circle color (COMPLETED)
                        },
                        "& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text":
                          {
                            color: "darkgrey", // circle color (COMPLETED)
                          },
                        "& .MuiStepLabel-root .Mui-active": {
                          color: "darkgrey", // circle color (ACTIVE)
                        },
                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                          fill: "white", // circle's number (ACTIVE)
                        },
                      }}
                    >
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>

              <div
                className={css`
                  @media only screen and (min-width: 768px) {
                    flex: 30%;
                  }
                  @media only screen and (max-width: 767px) {
                    display: none;
                  }
                `}
              >
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  style={{ height: "200px" }}
                  // connector={<div style={{width:1,height:10,background:"red"}}></div>}
                >
                  {steps.map((step, index) => (
                    <Step
                      key={step.label}
                      sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "#00df9a", // circle color (COMPLETED)
                        },
                        "& .MuiStepLabel-root .Mui-completed  .MuiStepIcon-text":
                          {
                            color: "#00df9a", // circle color (COMPLETED)
                          },
                        "& .MuiStepLabel-root .Mui-disabled": {
                          color: "darkgrey", // circle color (COMPLETED)
                        },
                        "& .Mui-disabled > span > svg >  circle": {
                          color: "darkgrey", // circle color (COMPLETED)
                        },
                        "& .MuiStepIcon-text": {
                          color: "black !important", // circle color (COMPLETED)
                        },
                        "& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text":
                          {
                            color: "darkgrey", // circle color (COMPLETED)
                          },
                        "& .MuiStepLabel-root .Mui-active": {
                          color: "darkgrey", // circle color (ACTIVE)
                        },
                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                          fill: "white", // circle's number (ACTIVE)
                        },
                      }}
                    >
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
              <div
                className={css`
                  @media only screen and (min-width: 768px) {
                    flex: 70%;
                  }
                  @media only screen and (max-width: 768px) {
                    flex: 100%;
                  }
                `}
              >
                {activeStep === 0 ? (
                  <>
                    {/* Aadhar Verify*/}
                    <div
                      className={css`
                        & > span {
                          display: block;
                        }
                      `}
                    >
                      {/* <span
                  className="bold"
                  style={{ color: "white", fontSize: 24, marginBottom: 8 }}
                >
                  Complete KYC
                </span> */}
                      <div>
                        {InputField(
                          "Aadhaar Number",
                          aadhaarNo || "",
                          "Enter aadhaar number",
                          (text) => {
                            setAadhaarNo(text);
                          },
                          aadhaarVerified ? (
                            <Iconify
                              fontSize={20}
                              icon={"akar-icons:circle-check-fill"}
                              color={"green"}
                            />
                          ) : (
                            <></>
                          ),
                          aadhaarVerified
                        )}
                        <CustomButton
                          style={{
                            width: "100%",
                            marginTop: "1rem",
                            marginBottom: 10,
                            maxWidth: 500,
                          }}
                          text="Verify & Next"
                          onClick={() => {
                            saveAndVerifyAadhaar();
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : activeStep === 1 ? (
                  <>
                    {/* PAN Details */}
                    {InputField(
                      "PAN Card",
                      panNo || "",
                      "Enter pan number",
                      (text) => {
                        setPANNo(text);
                      },
                      panVerified ? (
                        <Iconify
                          fontSize={20}
                          icon={"akar-icons:circle-check-fill"}
                          color={"green"}
                        />
                      ) : (
                        <></>
                      ),
                      panVerified
                    )}
                    {!panVerified ? (
                      <CustomButton
                        style={{
                          width: "100%",
                          maxWidth: 500,
                          marginTop: "1rem",
                        }}
                        text="Verify & Next"
                        onClick={() => {
                          saveAndVerifyPAN();
                        }}
                      />
                    ) : null}
                  </>
                ) : activeStep === 2 ? (
                  <>
                    {/* Payment Details */}
                    <div
                      className={css`
                        & > span {
                          display: block;
                        }
                      `}
                    >
                      <span
                        className={css`
                          @media only screen and (max-width: 767px) {
                            margin-top: 2.5rem;
                          }
                        `}
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "2.5rem",
                          marginBottom: "1rem",
                        }}
                      >
                        Bank Details
                      </span>
                      {InputField(
                        "Account Number",
                        paymentInfoData.bankAccount,
                        "Enter account number",
                        (text) => {
                          setPaymentInfoData({
                            ...paymentInfoData,
                            ...{ bankAccount: text },
                          });
                        },
                        user.accountVerified ? (
                          <Iconify
                            fontSize={20}
                            icon={"akar-icons:circle-check-fill"}
                            color={"green"}
                          />
                        ) : (
                          <></>
                        ),
                        user.accountVerified
                      )}
                      <div style={{ marginBottom: "2rem" }}></div>

                      {InputField(
                        "IFSC Number",
                        paymentInfoData.ifsc,
                        "Enter IFSC number",
                        (text) => {
                          setPaymentInfoData({
                            ...paymentInfoData,
                            ...{ ifsc: text },
                          });
                        },
                        user.accountVerified ? (
                          <Iconify
                            fontSize={20}
                            icon={"akar-icons:circle-check-fill"}
                            color={"green"}
                          />
                        ) : (
                          <></>
                        ),
                        user.accountVerified
                      )}
                      <CustomButton
                        style={{
                          width: "100%",
                          maxWidth: 500,
                          marginTop: 10,
                        }}
                        text="Verify & Next"
                        onClick={() => {
                          saveAndVerifyPaymentDetails();
                        }}
                      />
                      <div>
                        {/* {activeStep === 2 && user.accountVerified ? <Dialog
                              open={openModal}
                              onClose={handleCloseModal}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {"KYC Verification is completed."}
                              </DialogTitle>
                              <DialogActions>
                                <Button onClick={handleCloseModal} autoFocus>
                                  OK
                                </Button>
                              </DialogActions>
                            </Dialog> : <></>} */}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <a href="https://www.vaapas.in">Go TO HOMEPAGE</a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default KYCUpdateScreen;
