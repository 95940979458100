import { css } from "@emotion/css";
import { useEffect, useMemo, useState, useCallback } from "react";
import BottomBar from "../../components/BottomBar";
import Loader from "../../components/Loader";
import Logo from "../../components/Logo";
import { useAuth } from "../../context/auth";
import { Caset, CasetWithUserCasetInfo } from "../../interfaces/caset";
import theme from "../../util/theme";
import CasetItem from "./CasetItem";
import { Modal } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import CasetHistoryItem from "./CasetHistoryItem";
import { useGlobal } from "../../context/global";
import { Charges } from "../../interfaces/caset.charges";
import { WalletHistory, UserWallet } from "../../interfaces/wallet";
import { formatter } from "../../util/helper";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import Iconify from "../../components/Iconify";
import toast from "react-hot-toast";
import { screenHeight } from "../../layout/BaseLayout";

const Wallet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authRequest, authData } = useAuth();
  const { showToast, isMobile } = useGlobal();
  const user = authData?.user;
  const [chargesData, setChargesData] = useState<Charges>();
  const [amount, setAmount] = useState("");
  const [walletHistoryData, setWalletHistoryData] = useState<
    WalletHistory[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [walletData, setWalletData] = useState<UserWallet | null>(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const hidePaymentModal = () => {
    setShowPaymentModal(false);
  };
  // const textRef = createRef();

  const getWalletData = useCallback(() => {
    return authRequest("user/wallet", "GET", {})
      .then((data: UserWallet) => {
        setWalletData(data);
      })
      .catch((err: any) => {
      });
  }, [authRequest]);

  const getWalletHistory = useCallback(() => {
    return authRequest("user/wallet/history", "GET", {})
      .then((data: WalletHistory[]) => {
        setWalletHistoryData(data);
      })
      .catch((err: any) => {
      });
  }, [authRequest]);

  const getPageData = useCallback(() => {
    setIsLoading(true);

    const promises = [];
    promises.push(getWalletData());
    promises.push(getWalletHistory());

    // Minimum loading time
    // promises.push(
    //   new Promise<void>(s => {
    //     setTimeout(() => {
    //       s();
    //     }, 300);
    //   }),
    // );

    return Promise.all(promises)
      .then((_results) => {
        setIsLoading(false);
      })
      .catch((_err: any) => {
        setIsLoading(false);
      });
  }, [getWalletData, getWalletHistory]);

  useEffect(() => {
    getPageData();
  }, [getPageData, location.state]);

  useEffect(() => {
    // getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPaymentDetails = useCallback(() => {
    if (user) {
      if (!user.isKycVerified || !user.accountVerified) {
        navigate("/kyc");
      }
    }
    if (
      !(
        walletData?.bankAccount ||
        walletData?.ifsc ||
        walletData?.upiAddress ||
        !user?.isKycVerified
      )
    ) {
      navigate("/kyc");
      // vaapasNavigation(props.navigation, SCREEN_MAP.PAYMENT_DETAILS_UPDATE, {});
      return false;
    }
    return true;
  }, [
    navigate,
    user,
    walletData?.bankAccount,
    walletData?.ifsc,
    walletData?.upiAddress,
  ]);

  const makeWithdrawRequest = useCallback(() => {
    setIsLoading(true);
    return authRequest("user/withdraw", "POST", { amount: Number(amount) })
      .then(async () => {
        setIsLoading(false);
        return getPageData();
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error(
          err.message ||
            `Amount must be greater than 10 and less than ${formatter.format(
              walletData?.amount || 0
            )}`
        );
      });
  }, [amount, authRequest, getPageData, walletData?.amount]);

  const renderPayoutModal = useMemo(() => {
    return (
      <div
        className={css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          // ref={cashfreePayment}
          onClick={() => {
            setShowPaymentModal(false);
          }}
          className="grow"
        />
        <div
          className={css`
            & span {
              font-size: 20px;
            }
          `}
          style={{
            minHeight: 100,
            background: "#1C1C1C",
            padding: 20,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div className="flex" style={{ color: "white", fontWeight: "bold" }}>
            <span>Send From Wallet To Bank</span>
            <div className="grow" />{" "}
            <div
              onClick={() => {
                setShowPaymentModal(false);
              }}
            >
              <Iconify
                icon={"fa6-solid:circle-xmark"}
                color={theme.color.primary}
              />{" "}
            </div>
          </div>

          <div className="flex">
            <span className="medium" style={{ color: "white" }}>
              Cash to withdraw
            </span>
            <div className="grow" />
            <span className="medium bold" style={{ color: "white" }}>
              {formatter.format(Number(amount) || 0)}
            </span>
          </div>

          <div className="flex">
            <span className="medium" style={{ color: "white" }}>
              Convenience Fee
            </span>
            <div className="grow" />
            <span
              className="medium bold"
              style={{ color: "white", fontSize: 20 }}
            >
              ₹0.00
            </span>
          </div>

          <div className="flex">
            <span className="medium" style={{ color: "white" }}>
              Grand Total
            </span>
            <div className="grow" />
            <span className="medium bold" style={{ color: "white" }}>
              {formatter.format(Number(amount) || 0)}
            </span>
          </div>

          <div className="flex">
            <span className="medium" style={{ color: "white" }}>
              Remaining balance
            </span>
            <div className="grow" />
            <span className="medium bold" style={{ color: "white" }}>
              {formatter.format(
                (walletData?.amount || 0) - (Number(amount) || 0)
              )}
            </span>
          </div>

          <CustomButton
            text="Withdraw"
            onClick={() => {
              makeWithdrawRequest()
                .then((data) => {
                  hidePaymentModal();
                })
                .catch((_err) => {
                  // toast.error(_err.message);
                });
            }}
          />
        </div>
      </div>
    );
  }, [amount, makeWithdrawRequest, walletData?.amount]);

  //   useEffect(() => {
  //     setNumCols(Number((width / ((171 + 10) * 2)).toFixed()));
  //   }, [numCols]);

  const walletBalanceSection = useMemo(() => {
    return (
      <div className={"flex-col"} style={{ gap: "8px" }}>
        <div className="bold" style={{ fontSize: 24, color: "white" }}>
          Wallet
        </div>
        <div
          className="bold"
          style={{ fontSize: 25, color: theme.color.primary }}
        >
          {formatter.format(walletData ? walletData.amount : 0)}
        </div>
        <div className="bold" style={{ fontSize: 16, color: "white" }}>
          Available Balance
        </div>
      </div>
    );
  }, [walletData]);

  const widthDrawInputSection = useMemo(() => {
    return (
      <>
        <div
          style={{
            marginBottom: 10,
          }}
        >
          <CustomInput
            inputStyle={{
              color: "white",
            }}
            placeholder="Enter Amount"
            onChange={(text) => {
              if (text === "") {
                return setAmount("");
              }
              setAmount(text);
            }}
            background={"transparent"}
            value={amount}
          />
        </div>
        <div>
          <CustomButton
            text="Withdraw"
            style={{
              width: "100%",
            }}
            onClick={() => {
              if (!checkPaymentDetails()) {
                return;
              }
              let n = Number(amount);
              if (isNaN(n) || n < 0) {
                return toast.error("Invalid amount");
              }
              if (!(Number.isInteger(n) && n <= (walletData?.amount || 0))) {
                return toast.error("Invalid amount to withdraw");
              }
              try {
                if (Number(amount) > 0) {
                  setShowPaymentModal(true);
                } else {
                  toast.error("No amount to withdraw");
                }
              } catch (err) {}
            }}
          />
        </div>
      </>
    );
  }, [amount, checkPaymentDetails, walletData?.amount]);

  const debitHistorySection = useMemo(() => {
    return (
      <>
        <div
          className="bold"
          style={{
            color: "white",
            fontSize: isMobile ? "" : "1.5rem",
            marginBottom: "1rem",
          }}
        >
          Debit History
        </div>

        {/* Debit History */}
        <div
          style={{
            flexGrow: 1,
            overflowY: "scroll",
          }}
        >
          <div
            className={css`
              display: flex;
              flex-direction: column;
              gap: 1rem;
              padding-right: 10px;
              justify-content: center;
              padding-top: 10px;
            `}
          >
            {(walletHistoryData || []).length === 0 &&
            walletHistoryData !== null ? (
              <div
                className={
                  css`
                    display: flex;
                    flex-direction: column;
                    min-height: 200px;
                    justify-content: center;
                    align-items: center;
                    color: white;
                  ` + " bold"
                }
              >
                <Iconify
                  icon={"clarity:error-solid"}
                  fontSize={60}
                  color={theme.color.error}
                />{" "}
                No Transactions Found
              </div>
            ) : null}
            {(walletHistoryData || []).map((item, i) => {
              return (
                <div className="flex" style={{ marginTop: i === 0 ? 0 : 0 }}>
                  <div
                    className="bold"
                    style={{
                      fontSize: 16,
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {item.trnxType === "credit" ? "+" : "-"}{" "}
                    {formatter.format(item.amount)}
                  </div>
                  <div className="grow" />
                  <div
                    className="bold flex-col"
                    style={{
                      color: "white",
                      fontSize: 14,
                      gap: 10,
                      textAlign: "center",
                    }}
                  >
                    <span>{new Date(item.createdAt).toLocaleDateString()}</span>
                    <span>{new Date(item.createdAt).toLocaleTimeString()}</span>
                  </div>
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <Iconify
                      fontSize={24}
                      icon={
                        item.status === "rejected"
                          ? "fa6-solid:circle-xmark"
                          : item.status === "pending"
                          ? "ant-design:clock-circle-filled"
                          : item.trnxType === "credit"
                          ? "akar-icons:arrow-down-left"
                          : "akar-icons:arrow-up-right"
                      }
                      color={
                        item.status === "rejected"
                          ? "red"
                          : item.status === "pending"
                          ? "#d6cf09"
                          : item.trnxType === "credit"
                          ? theme.color.primary
                          : "#b92be0"
                      }
                    />
                  </div>
                  {/* <CasetHistoryItem key={item.id} item={item} /> */}
                </div>
              );
            })}
            {/* {(userCasets || []).map((item) => {
            return <CasetItem key={item.id} item={item} />;
          })} */}
          </div>
        </div>
      </>
    );
  }, [walletHistoryData]);

  return (
    <>
      <Loader loading={isLoading} />
      <Modal open={showPaymentModal}>
        {isMobile ? (
          renderPayoutModal
        ) : (
          <div
            className={
              css`
                position: fixed;
                gap: 1rem;
                flex-direction: column;
                width: 100vw;
                height: ${screenHeight};
                background: "black";
                left: 0;
                display: grid;
                z-index: 10;
                place-content: center;
              ` + ""
            }
          >
            <div style={{ width: 500 }}>{renderPayoutModal}</div>
          </div>
        )}
      </Modal>
      <BottomBar />

      <div
        style={{
          // maxHeight: "100vh",
          // height: "100vh",
          position: "relative",
          display: "flex",
          maxHeight: screenHeight,
          flexDirection: "column",
          // height: screenHeight,
          // height: "100%",
          overflow: "hidden",
          gap: "1rem",
          padding: "10px 16px 80px",
          paddingBottom: 60,
          width: "100%",
          // paddingTop: "2rem",
        }}
      >
        {isMobile ? (
          <>
            {walletBalanceSection}

            {widthDrawInputSection}

            {debitHistorySection}
          </>
        ) : (
          <div
            className={css`
              display: flex;
              gap: 2rem;
            `}
          >
            <div
              className={css`
                flex: 70%;
              `}
            >
              {debitHistorySection}
            </div>
            <div
              className={css`
                flex: 30%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
              `}
            >
              <div>{walletBalanceSection}</div>
              <div>{widthDrawInputSection}</div>
            </div>
          </div>
        )}

        {/* <p className="bold">Explore</p> */}
      </div>
    </>
  );
};

export default Wallet;
