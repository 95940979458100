import { css } from "@emotion/css";
import React from "react";
import { useGlobal } from "../context/global";
import theme from "../util/theme";

const faq = [
  {
    question: "When will I start receiving my returns?",
    answer:
      "Returns start one day after the live date mentioned in the caset details. Returns are typically distributed to investors every day. However, there may be investment options with different payment cycles. However, this would be mentioned in detail on the ‘Caset Details’ Page.",
  },
  {
    question: "Can I exit my investment?",
    answer: "Currently you can not exit investment before the tenure is over.",
  },
  {
    question: "What’s the due diligence process?",
    answer:
      `Financial Health : We collect all required information such as sales data, balance sheet, annual reports etc. of at atleast the past 12 months and analyze the data to assess the current financial health of the business.\nProfile: We inquire about the professional backgrounds of founders/ CXOs, shareholders, ongoing litigation as well as conduct reference calls with key customers, vendors, employees and other stakeholders.\nBusiness Performance: We review industry trends, competitive edge, assets quality etc. to estimate the  future performance of the business`.split(
        "\n"
      ),
  },
  {
    question: "How do you ensure genuinity of sales data?",
    answer:
      "We integrate directly with their POS system and collect the sales data without any intermediary intervention. We also do regular audits as well as put in necessary penalties on fraud detection under the terms and conditions the business is obliged to before listing on the platform.",
  },
];
const FAQs = () => {
  const { isMobile } = useGlobal();
  return (
    <div
      id="faq"
      className={css`
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        & .heading {
          font-size: 20px;
          /* color: ${theme.color.primary}; */
          color: ${isMobile ? theme.color.primary : "unset"};
        }
        &.active .heading {
          color: ${theme.color.primary};
        }

        & .point {
          display: flex;
          flex-direction: column;

          & .faq {
            margin: 10px 0px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            & .question {
              font-size: unset;
            }
          }

          & .answer {
            white-space: pre-wrap;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            /* font-size: 14px; */
            color: #cbcbcb;
          }

          & .answer li {
            line-height: 24px;
          }
        }
      `}
    >
      <div className="heading">FAQs</div>
      <div className="divider" style={{ transform: "scale(1)" }} />
      <div className="point">
        {faq.map((f, i) => {
          return (
            <div key={i} className="faq">
              <div className="question">{f.question}</div>
              <ul className="answer">
                {typeof f.answer === "string" ? (
                  <li className="regular">{f.answer} </li>
                ) : (
                  f.answer.map((l, i) => (
                    <li key={i} className="regular">
                      {l}
                    </li>
                  ))
                )}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FAQs;
