import { css } from "@emotion/css";
import { Icon } from "@iconify/react";
import { Modal } from "@mui/material";
import theme from "../util/theme";
interface Props {
  loading: boolean;
}

const Loader = (props: Props) => {
  return (
    <Modal
      open={props.loading}
      sx={{
        display: "grid",
        placeContent: "center",
      }}
    >
      <Icon icon={"line-md:loading-twotone-loop"} color={theme.color.primary} fontSize={60} />
    </Modal>
  );
};

export default Loader;
