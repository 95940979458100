import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../components/BackButton";
import BottomBar from "../components/BottomBar";
import Iconify from "../components/Iconify";
import Loader from "../components/Loader";
import { useAuth } from "../context/auth";
import { useGlobal } from "../context/global";
import {
  CasetWithUserCasetInfo,
  UserCaset,
  UserOrderResp,
} from "../interfaces/caset";
import { screenHeight } from "../layout/BaseLayout";
import { formatter } from "../util/helper";
import theme from "../util/theme";
import CasetHistoryItem from "./Home/CasetHistoryItem";

const OrdersPage = () => {
  const navigate = useNavigate();
  const { authRequest, authData } = useAuth();
  const { showToast, isMobile } = useGlobal();
  const [refreshing, setRefreshing] = useState(false);
  const [page, setPage] = useState(1);
  const [finalPageReached, setFinalPageReached] = useState(false);
  const [ordersFetching, setOrdersFetching] = useState(false);
  const [ordersData, setOrdersData] = useState<UserCaset[]>([]);

  const user = authData?.user || {
    name: "",
  };

  const getOrders = (pageNo: number) => {
    if (finalPageReached || ordersFetching) {
      return new Promise<void>((s) => {
        s();
      });
    }
    setOrdersFetching(true);
    setRefreshing(true);
    const limit = 10;
    return authRequest(
      `user/orders?limit=${limit}&offset=${(pageNo - 1) * limit}`,
      "GET",
      {}
    )
      .then((data: UserOrderResp) => {
        const userCasets = data.results;
        setOrdersData([...ordersData, ...userCasets]);
        if (userCasets.length === 0 || userCasets.length !== limit) {
          setFinalPageReached(true);
        } else {
          setPage(pageNo + 1);
        }
        setRefreshing(false);
        setOrdersFetching(false);
        // showToast('Loaded');
      })
      .catch((_err) => {
        setOrdersFetching(false);
        setRefreshing(false);
        // showToast('Error');
      });
  };

  useEffect(() => {
    getOrders(page);
  }, []);

  const handleRefresh = () => {
    getOrders(page);
  };

  const getUserNameContent = () => {
    const icon = (
      <Iconify icon={"bxs:user"} fontSize={20} color={theme.color.primary} />
    );
    if (user && user.name) {
      const formattedName: string = user.name.replace(/\s\s/gi, " ");
      const nameParts = formattedName.split(" ");
      if (nameParts.length) {
        if (nameParts.length === 1) {
          return nameParts[0][0] || icon;
        }
        if (nameParts.length >= 2) {
          return nameParts[0][0] + nameParts[1][0];
        }
      }
    }

    return icon;
  };

  return (
    <>
      <Loader loading={refreshing} />
      <div
        style={{
          height: "100vh",
          paddingBottom: 30,
          paddingLeft: 16,
          paddingRight: 16,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "2rem",
        }}
      >
        <div
          className="flex center gap"
          style={{
            width: "100%",
          }}
        >
          <div
            className={css`
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <div>
              <BackButton />
            </div>
            <div
              className={css`
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                /* background-color: rgba(0, 223, 152, 0.419); */
                backdrop-filter: blur(4px);
                /* border: 1px solid ${theme.color.primary}; */
                border: 1px solid white;

                align-items: center;
              `}
            >
              <div
                className={
                  css`
                    color: ${theme.color.primary};
                    font-size: 18px;
                  ` + " bold"
                }
              >
                {getUserNameContent()}
              </div>
            </div>
          </div>
          <div
            className={css`
              width: 0.6px;
              background-color: rgba(255, 255, 255, 0.75);
              transform: scaleY(0.9);
              height: 60px;
              color: white;
            `}
          />
          <span
            className="bold grow"
            style={{ fontSize: 24, color: "white" }}
          >{`Hi${" " + (user.name || "")}!`}</span>
        </div>
        {/* <BottomBar /> */}
        <div className="flex">
          <div
            className="bold"
            style={{
              color: "white",
              fontSize: 24,
            }}
          >
            Orders
          </div>
          <div className="grow" />
          <div
            onClick={() => {
              window.open("tel:+916302315854", "_blank");
            }}
          >
            <Iconify
              icon={"bi:headset"}
              fontSize={25}
              color={theme.color.primary}
            />
          </div>
        </div>

        <div
          style={{
            flexGrow: 1,
            overflowY: "scroll",
          }}
        >
          {/* List of casets */}
          <div
            onScroll={(e: any) => {
              if (
                Math.abs(
                  e.target.scrollHeight -
                    e.target.scrollTop -
                    e.target.clientHeight
                ) < 200
              ) {
                getOrders(page);
              }
            }}
            className={css`
              /* height: ${screenHeight}; */
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              gap: 1rem;
              justify-content: flex-start;
            `}
          >
            {(ordersData || []).map((item, index) => {
              return (
                <div
                  key={item.id}
                  className={
                    "flex center " +
                    css`
                      &:hover {
                        border: 1px solid ${theme.color.primary} !important;
                      }
                      & span {
                        font-size: ${isMobile ? "14px" : "18px"} !important;
                        font-family: "DMSans" !important;
                      }
                    `
                  }
                  style={{
                    ...styles.orderContainer,
                    ...{
                      paddingVertical: theme.size._r * 2,
                      width: "100%",
                      gap: 10,
                    },
                  }}
                >
                  <div style={{ padding: "0px 5px" }}>
                    {item.status === "rejected" ||
                    item.payment.status === "rejected" ? (
                      <Iconify
                        icon={"fa6-regular:circle-xmark"}
                        color={"red"}
                        fontSize={20}
                      />
                    ) : // <FontAwesomeIcon icon={"xmark"} color={"red"} size={20} />
                    item.status === "pending" &&
                      item.payment.status === "pending" ? (
                      <Iconify
                        icon={"ant-design:clock-circle-filled"}
                        color={"#d6cf09"}
                        fontSize={20}
                      />
                    ) : (
                      <Iconify
                        icon={"bxs:check-circle"}
                        color={theme.color.primary}
                        fontSize={20}
                      />
                      // <FontAwesomeIcon
                      //   icon={"check-circle"}
                      //   color={theme.color.primary}
                      //   size={20}
                      // />
                    )}
                  </div>
                  <div
                    className={"grow " + css``}
                    style={{ padding: "0px 0px" }}
                  >
                    <div className=" flex-col">
                      <span
                        className="bold"
                        style={{ ...{ color: "white" }, ...styles.stepText }}
                      >
                        Order Id :
                        {" " + item.paymentId.substring(0, 8).toUpperCase()}
                      </span>
                      <span
                        className="bold"
                        style={{ ...{ color: "white" }, ...styles.stepText }}
                      >
                        {item.caset.businessBranch.business.name}{" "}
                        {/* {item.casetQuantity}{' '}
                      {formatter.format(item.payment.amount).split('.')[0]} */}
                      </span>
                      <span
                        className="bold"
                        style={{
                          ...{ color: "white" },
                          ...styles.stepText,
                        }}
                      >
                        {/* {item.caset.businessBranch.business.name}{' '} */}
                        {item.casetQuantity}
                        {" X "}
                        {formatter.format(item.caset.unitPrice).split(".")[0]}
                      </span>
                      {item.payment.status === "rejected" ? (
                        <span
                          className="bold"
                          style={{ ...{ color: "white" }, ...styles.stepText }}
                        >
                          {item.payment.reason}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <span
                      className="bold"
                      style={{
                        ...{
                          color: "white",
                          textAlign: "right",
                          whiteSpace: "pre",
                        },
                        ...styles.stepText,
                      }}
                    >
                      {new Date(item.createdAt).toLocaleDateString()}
                      {"\n"}
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </span>
                  </div>
                  {/* <span style={[GLOBAL_STYLES.bold, GLOBAL_STYLES.fontWhite]}>
                    Quantity : {item.casetQuantity}
                  </span>
                  <span style={[GLOBAL_STYLES.bold, GLOBAL_STYLES.fontWhite]}>
                    Amount : {formatter.format(item.payment.amount).split('.')[0]}
                  </span> */}
                </div>
              );
            })}
            {/* {(userCasets || []).map((item) => {
            return (
              <div style={{ width: 165 }}>
                <CasetHistoryItem key={item.id} item={item} />
              </div>
            );
          })} */}
            {/* {(userCasets || []).map((item) => {
            return <CasetItem key={item.id} item={item} />;
          })} */}
          </div>
        </div>

        {/* <p className="bold">Explore</p> */}
      </div>
    </>
  );
};

const styles = {
  container: {
    backgroundColor: theme.color.primary_bg_2,
    width: "100%",
    height: "100%",
    padding: 20,
    paddingBottom: 60,
  },
  orderContainer: {
    borderRadius: 10,
    padding: 10,
    paddingHorizontal: 15,
    backgroundColor: theme.color.bg_4,
  },
  stepContainer: {
    paddingVertical: 15,
  },
  stepText: {
    fontSize: 16,
    paddingVertical: 2,
  },
};

export default OrdersPage;
