import React, { useState, useEffect } from "react";
import Iconify from "../../components/Iconify";
import Loader from "../../components/Loader";
import { useAuth } from "../../context/auth";
import { UserVpa } from "../../interfaces/user";
import { UserWallet } from "../../interfaces/wallet";
import { copyTextToClipboard, formatter } from "../../util/helper";
import theme from "../../util/theme";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import CustomButton from "../../components/CustomButton";

const VPADetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { bankAccount, ifsc, quantity, casetId, amount } = location.state as {
    amount: number;
    quantity: number;
    bankAccount: string;
    ifsc: string;
    casetId: string;
  };
  const { authRequest, authData } = useAuth();
  const user = authData?.user;
  //   const { width, height } = useWindowDimensions();

  const [showBankTransferModal, setShowBankTransferModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [walletData, setWalletData] = useState<UserWallet | null>(null);
  const [vpaData, setVpaData] = useState({
    bankAccount: "",
    ifsc: "",
    upiAddress: "",
  });

  useEffect(() => {
    // getWalletData();
    getUserVPA();
  }, []);

  const placeOrder = () => {
    setOrderPlaced(true);
    if (!orderPlaced) {
      return authRequest("caset/checkout/cashfree", "POST", {
        casetId: casetId,
        quantity: quantity,
        totalAmount: amount,
      })
        .then(() => {})
        .catch((_err) => {});
    }
  };

  if (!user) {
    return (
      <div>
        <div style={styles.container} />
      </div>
    );
  }

  const getUserVPA = () => {
    setIsLoading(true);
    return authRequest("user/vpa/cashfree", "GET", {})
      .then((data: UserVpa) => {
        setIsLoading(false);
        setVpaData(data);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  return (
    <div style={{ padding: "0px 20px" }}>
      <Loader loading={isLoading} />

      <div style={styles.container}>
        <div className={`flex-col`} style={{ paddingTop: 20 }}>
          <div
            onClick={() => {
              //   goBack(props.navigation);
              navigate(-1);
            }}
            className={`flex-col`}
            style={{
              // paddingRight: 20,
              // paddingLeft: 2,
              // backgroundColor: 'red',
              height: 30,
              width: 30,
              borderRadius: 15,
              alignContent: "center",
              justifyContent: "center",
              // alignItems: 'center',
            }}
          >
            <Iconify
              icon={"akar-icons:chevron-left"}
              color={theme.color.primary}
              fontSize={24}
            />
          </div>
          <span
          // style={[
          //   GLOBAL_STYLES.bold,
          //   GLOBAL_STYLES.heading,
          //   GLOBAL_STYLES.fontWhite,
          // ]}
          >
            {/* Confirm */}
          </span>
          <span
            className="bold white"
            style={{ ...styles.paddingTop, ...styles.text }}
          >
            Depositing{" "}
            <span style={{ color: theme.color.primary }}>
              {formatter.format(amount)}
            </span>
          </span>

          {/* Bank Transfer */}
          <div
            className="flex-col"
            style={{
              paddingTop: 20,
            }}
          >
            <div className="flex space-between" style={{ padding: "10px 0px" }}>
              <div className="flex" style={{ flex: 1, alignItems: "center" }}>
                <span
                  className="medium"
                  style={{ color: "white", marginLeft: 3, fontSize: 16 }}
                >
                  Fund transfer bank details
                </span>
              </div>
              <div className="flex-col" style={{ alignItems: "flex-end" }}>
                {/* <Pressable
                  onClick={() => {
                    setShowBankTransferModal(false);
                    vaapasNavigation(props.navigation, SCREEN_MAP.EXPLORE, {});
                  }}>
                  <Iconify
                    icon={'xmark'}
                    color={theme.color.primary}
                    size={20}
                  />
                </Pressable> */}
              </div>
            </div>

            <div className="flex space-between" style={{ padding: "10px 0px" }}>
              <div className="flex" style={{ flex: 1, alignItems: "center" }}>
                <span
                  className="medium"
                  style={{ color: "white", marginLeft: 3, fontSize: 16 }}
                >
                  Bank Account
                </span>
              </div>
              <div className="flex-col" style={{ alignItems: "flex-end" }}>
                <div>
                  <span
                  // style={[
                  //   GLOBAL_STYLES.medium,
                  //   { color: "white", marginLeft: 3, fontSize: 16 },
                  // ]}
                  >
                    {/* {bankAccount} */}
                  </span>
                </div>
              </div>
            </div>

            <div
              className="flex space-between"
              style={{
                padding: "10px 10px",
                borderRadius: 4,
                backgroundColor: theme.color.secondary_bg,
              }}
            >
              <div className="flex" style={{ flex: 1, alignItems: "center" }}>
                <span
                  className="bold"
                  style={{ color: "white", marginLeft: 3, fontSize: 16 }}
                >
                  {bankAccount}{" "}
                </span>
              </div>
              <div className="flex-col" style={{ alignItems: "flex-end" }}>
                <div>
                  <span
                    onClick={() => {
                      //   Clipboard.setString(bankAccount);
                      copyTextToClipboard(bankAccount);
                      toast.success("Bank account copied to clipboard");
                    }}
                    className={"medium"}
                    style={{ color: "white", marginLeft: 3, fontSize: 16 }}
                  >
                    {/* {bankAccount} */}
                    <Iconify
                      icon={"fa-solid:clipboard"}
                      color={theme.color.primary}
                      fontSize={20}
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="flex space-between" style={{ padding: "10px 0px" }}>
              <div className="flex" style={{ flex: 1, alignItems: "center" }}>
                <span
                  className="medium"
                  style={{ color: "white", marginLeft: 3, fontSize: 16 }}
                >
                  IFSC
                </span>
              </div>
              <div className="flex-col" style={{ alignItems: "flex-end" }}>
                <div>
                  <span
                  // style={[
                  //   GLOBAL_STYLES.medium,
                  //   { color: "white", marginLeft: 3, fontSize: 16 },
                  // ]}
                  >
                    {/* {ifsc} */}
                  </span>
                </div>
              </div>
            </div>

            <div
              className="flex space-between"
              style={{
                padding: "10px 10px",
                borderRadius: 4,
                backgroundColor: theme.color.secondary_bg,
              }}
            >
              <div className="flex" style={{ flex: 1, alignItems: "center" }}>
                <span
                  className="bold"
                  style={{ color: "white", marginLeft: 3, fontSize: 16 }}
                >
                  {ifsc}{" "}
                </span>
              </div>
              <div className="flex-col" style={{ alignItems: "flex-end" }}>
                <div>
                  <span
                    onClick={() => {
                      //   Clipboard.setString(ifsc);
                      copyTextToClipboard(ifsc);
                      toast.success("IFSC copied to clipboard");
                    }}
                    className={"medium"}
                    style={{ color: "white", marginLeft: 3, fontSize: 16 }}
                  >
                    <Iconify
                      icon={"fa-solid:clipboard"}
                      color={theme.color.primary}
                      fontSize={20}
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="flex space-between" style={{ padding: "10px 0px" }}>
              <div className="flex" style={{ flex: 1, alignItems: "center" }}>
                <span
                  className="medium"
                  style={{ color: "white", marginLeft: 3, fontSize: 16 }}
                >
                  Note: Transfer amount using bank account provided in profile
                </span>
              </div>
            </div>
            <div className="flex space-between" style={{ padding: "10px 0px" }}>
              <div className="flex" style={{ flex: 1, alignItems: "center" }}>
                <span
                  className="medium"
                  style={{ color: "white", marginLeft: 3, fontSize: 16 }}
                >
                  Please confirm if payment is initiated
                </span>
              </div>
            </div>

            <div
              className="flex"
              style={{
                height: 48,
                marginBottom: 30,
                marginTop: 10,
                justifyContent: "space-evenly",
              }}
            >
              <div style={{ width: 120 }}>
                <CustomButton
                  style={{
                    width: 100,
                  }}
                  text="Yes"
                  onClick={async () => {
                    // hidePaymentModal();
                    //   setShowBankTransferModal(false);
                    await placeOrder();
                    navigate("/explore", { replace: true });
                    // vaapasNavigation(props.navigation, SCREEN_MAP.EXPLORE, {});
                  }}
                />
              </div>
              <div style={{ width: 120 }}>
                <CustomButton
                  style={{
                    width: 100,
                  }}
                  text="No"
                  onClick={() => {
                    // hidePaymentModal();
                    //   setShowBankTransferModal(false);
                    navigate("/explore", { replace: true });
                    // vaapasNavigation(props.navigation, SCREEN_MAP.EXPLORE, {});
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.color.primary_bg_2,
    paddingHorizontal: 20,
  },
  paddingTop: {
    paddingTop: 10,
  },
  text: {
    fontSize: 16,
  },
  sectionContainer: {
    // paddingVertical: 20,
    padding: "20px 0px",
  },
  section: {
    backgroundColor: "#000000E0" || theme.color.primary_bg || "white",
    // padding: 5,
    // paddingHorizontal: 15,
    marginVertical: 10,
    margin: "10px 0px",
    // paddingVertical: 15,
    padding: "15px 15px",
    borderRadius: 5,
  },
  helperspan: {
    // color: theme.color.grey,
    color: "#aaa",
    // fontFamily: 16
    fontSize: 14,
  },
  sectionTitle: {
    fontSize: 20,
  },
};

export default VPADetails;
