import { css } from "@emotion/css";
import { useEffect, useState, useMemo, useCallback } from "react";
import BottomBar from "../../components/BottomBar";
import Loader from "../../components/Loader";
import Logo from "../../components/Logo";
import { useAuth } from "../../context/auth";
import { Caset, CasetWithUserCasetInfo } from "../../interfaces/caset";
import theme from "../../util/theme";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { User } from "../../interfaces/user";
import Iconify from "../../components/Iconify";
import Accordion from "../../components/Accordion";
import EditInput from "../../components/EditInput";
import { screenHeight } from "../../layout/BaseLayout";
import { useGlobal } from "../../context/global";
import BackButton from "../../components/BackButton";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FieldProp {
  title: string;
  key: string;
  value: string;
  placeholder?: string;
  notEditable?: boolean;
}

interface AccordionRowData {
  isExpanded?: true | false;
  hideExpand?: true | false;
  accordionClick?(): void;
  moveToScreen?: string;
  icon: string;
  title: string;
  fields?: FieldProp[];
  custom?: React.ReactNode;
}

const AccountInformation = () => {
  const navigate = useNavigate();
  const { isMobile } = useGlobal();
  const { signOut, authData, authRequest, updateUserData } = useAuth();
  const [selectedProfileItem, setSelectedProfileItem] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState(authData?.user);

  useEffect(() => {
    if (!user && authData?.user) {
      setUser(authData?.user);
    }
  }, [authData?.user, user]);

  if (!user) {
    return (
      <div>
        <span>Not available</span>
      </div>
    );
  }

  //   useEffect(() => {
  //     setNumCols(Number((width / ((171 + 10) * 2)).toFixed()));
  //   }, [numCols]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ProfileAccordionList: AccordionRowData[] = [
    {
      title: "Personal Information",
      icon: "bxs:user",
      fields: [
        {
          title: "Name",
          key: "name",
          placeholder: "Enter your name",
          value: user.name || "",
        },
        {
          title: "Email",
          key: "email",
          placeholder: "Enter your email",
          value: user.email || "",
          notEditable: true,
        },
        {
          title: "Phone",
          key: "phoneNumber",
          value: user?.phoneNumber || "",
          notEditable: true,
        },
      ],
    },
    user && user.isKycVerified && user.accountVerified
      ? {
          title: "KYC",
          icon: "bi:person-badge",
          fields: [
            // {
            //   title: 'UPI',
            //   key: 'upiAddress',
            //   placeholder: 'Enter your upi',
            //   value: user.upiAddress || '',
            //   notEditable: true,
            // },
            {
              title: "Aadhaar Number",
              key: "aadharNo",
              placeholder: "Your aadhaar number",
              value: user.kycJson?.aadhaarNo || "",
              notEditable: true,
            },
            {
              title: "PAN Number",
              key: "panNo",
              placeholder: "Your pan number",
              value: user.kycJson?.panNo || "",
              notEditable: true,
            },
          ],
        }
      : {
          title: "KYC",
          icon: "bi:person-badge",
          hideExpand: true,
          accordionClick: () => {
            navigate("/kyc");
          },
          // fields: [
          //   // {
          //   //   title: 'UPI',
          //   //   key: 'upiAddress',
          //   //   placeholder: 'Enter your upi',
          //   //   value: user.upiAddress || '',
          //   //   notEditable: true,
          //   // },
          //   {
          //     title: "Aadhaar Number",
          //     key: "aadharNo",
          //     placeholder: "Your aadhaar number",
          //     value: user.kycJson?.aadhaarNo || "",
          //     notEditable: true,
          //   },
          //   {
          //     title: "PAN Number",
          //     key: "panNo",
          //     placeholder: "Your pan number",
          //     value: user.kycJson?.panNo || "",
          //     notEditable: true,
          //   },
          // ],
        },
    {
      title: "Bank Details",
      icon: "fa-solid:credit-card",
      fields: [
        // {
        //   title: 'UPI',
        //   key: 'upiAddress',
        //   placeholder: 'Enter your upi',
        //   value: user.upiAddress || '',
        //   notEditable: true,
        // },
        {
          title: "Bank Account",
          key: "bankAccount",
          placeholder: "Enter your bank account",
          value: user.bankAccount || "",
          notEditable: true,
        },
        {
          title: "IFSC code",
          key: "ifsc",
          placeholder: "Enter IFSC code",
          value: user.ifsc || "",
          notEditable: true,
        },
      ],
    },
    {
      title: "Support",
      icon: "fa-solid:phone",
      custom: (
        <div style={{ marginTop: 10 }}>
          <span className="bold" style={{ color: "white" }}>
            Write to us at:
          </span>
          {/* <span
            style={[
              GLOBAL_STYLES.bold,
              {marginTop: 10, color: theme.color.primary},
            ]}>
            Email{'\n'}
            <span
              selectable
              onClick={async () => {
                await Linking.openURL('mailto:support@vaapas.in');
              }}
              style={[{color: 'white', fontSize: 14}]}>
              support@vaapas.in
            </span>
          </span>
          <span
            style={[
              GLOBAL_STYLES.bold,
              {marginTop: 10, color: theme.color.primary},
            ]}>
            Whatsapp{'\n'}
            <span style={[{fontSize: 20}]}> </span>
            <span
              selectable
              onClick={async () => {
                await Linking.openURL('tel:+916302315854');
              }}>
              <WHATSAPP_LOGO width={20} height={20} />
            </span>
          </span> */}
          <div className="flex" style={{ padding: "10px 0px", gap: "5px" }}>
            <span
              onClick={async () => {
                window.open("mailto:support@vaapas.in", "_blank");
                // await Linking.openURL("mailto:support@vaapas.in");
              }}
              style={{ color: "white", fontSize: 30 }}
            >
              <Iconify
                icon={"codicon:mail"}
                color={theme.color.primary}
                fontSize={30}
              />
              {/* <FontAwesomeIcon
                icon={"envelope-square"}
                color={theme.color.primary}
                size={30}
              /> */}
            </span>
            <span>{"   "}</span>
            <span
              onClick={async () => {
                window.open(
                  `whatsapp://send?text=${""}&phone=+916302315854`,
                  "_blank"
                );
              }}
            >
              <Iconify
                icon={"akar-icons:whatsapp-fill"}
                color={theme.color.primary}
                fontSize={25}
              />
              {/* <WHATSAPP_LOGO width={30} height={30} /> */}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Orders",
      icon: "fa-solid:box",
      hideExpand: true,
      accordionClick: () => {
        // const message = `Hi! Check out this amazing app with which we can invest in your favourite brands and get returns on every sale\nDownload the app: https://play.google.com/store/apps/details?id=com.caset.android&hl=en&gl=in`;
        // const url = `whatsapp://send?text=${message}&phone`;
        // Linking.canOpenURL(url)
        //   .then(() => {
        //     Linking.openURL(url);
        //   })
        //   .catch(_err => {
        //     Share.share({
        //       message,
        //       title: 'Sample title',
        //       url: 'https://vaapas.in',
        //     });
        //   });
        navigate("/orders");
        // vaapasNavigation(props.navigation, SCREEN_MAP.ORDERS_PAGE, {});
        // vaapasNavigation(props.navigation, SCREEN_MAP.WEB_PAGE, {
        //   link: 'https://caset.notion.site/Privacy-Policy-d96f5298e92e4f219c6d9518fe9ad3ae',
        // });
      },
    },
    {
      title: "Invite your friends",
      icon: "fa6-solid:user-group",
      hideExpand: true,
      accordionClick: () => {
        const message = `Hi! Check out this amazing app with which we can invest in your favourite brands and get returns on every sale\nDownload the app: https://play.google.com/store/apps/details?id=com.caset.android&hl=en&gl=in`;
        const url = `whatsapp://send?text=${message}&phone`;
        window.open(url, "_blank");
        // Linking.canOpenURL(url)
        //   .then(() => {
        //     Linking.openURL(url);
        //   })
        //   .catch((_err) => {
        //     Share.share({
        //       message,
        //       title: "Sample title",
        //       url: "https://vaapas.in",
        //     });
        //   });
        // vaapasNavigation(props.navigation, SCREEN_MAP.WEB_PAGE, {
        //   link: 'https://caset.notion.site/Privacy-Policy-d96f5298e92e4f219c6d9518fe9ad3ae',
        // });
      },
    },
    {
      title: "Delete Account",
      icon: "fa6-solid:user-xmark",
      custom: (
        <div>
          <div className={"flex"}>
            <span className="medium white">
              For account deletion and any other queries, please write to us on{" "}
              <a
                href={"mailto:support@vaapas.in"}
                style={{
                  textDecoration: "none",
                  color: theme.color.primary,
                }}
              >
                support@vaapas.in
              </a>
            </span>
          </div>
        </div>
      ),
    },
    // {
    //   title: "Privacy Policy",
    //   icon: "fa6-solid:file-lines",
    //   hideExpand: true,
    //   accordionClick: () => {
    //     window.open(
    //       "https://caset.notion.site/Privacy-Policy-d96f5298e92e4f219c6d9518fe9ad3ae",
    //       "_blank"
    //     );
    //     // vaapasNavigation(props.navigation, SCREEN_MAP.WEB_PAGE, {
    //     //   link: "https://caset.notion.site/Privacy-Policy-d96f5298e92e4f219c6d9518fe9ad3ae",
    //     // });
    //   },
    // },
    // {
    //   title: "Terms of use",
    //   icon: "fa6-solid:file-lines",
    //   hideExpand: true,
    //   accordionClick: () => {
    //     window.open(
    //       "https://caset.notion.site/Terms-of-Use-2ee075c1f06d4f8a83f41bca8ceb6748",
    //       "_blank"
    //     );
    //     // vaapasNavigation(props.navigation, SCREEN_MAP.WEB_PAGE, {
    //     //   link: "https://caset.notion.site/Terms-of-Use-2ee075c1f06d4f8a83f41bca8ceb6748",
    //     // });
    //   },
    // },
    {
      title: "Logout",
      icon: "fa6-solid:arrow-right-from-bracket",
      hideExpand: true,
      accordionClick: () => {
        signOut("https://vaapas.in");
      },
    },
  ];

  const MobileProfileAccordionList: AccordionRowData[] = [
    {
      title: "Personal Information",
      icon: "bxs:user",
      hideExpand: true,
      isExpanded: true,
      fields: [
        {
          title: "Name",
          key: "name",
          placeholder: "Enter your name",
          value: user.name || "",
        },
        {
          title: "Email",
          key: "email",
          placeholder: "Enter your email",
          value: user.email || "",
          notEditable: true,
        },
        {
          title: "Phone",
          key: "phoneNumber",
          value: user?.phoneNumber || "",
          notEditable: true,
        },
      ],
    },
    user && user.isKycVerified && user.accountVerified
      ? {
          title: "KYC",
          icon: "bi:person-badge",
          hideExpand: true,
          isExpanded: true,
          fields: [
            {
              title: "Aadhaar Number",
              key: "aadharNo",
              placeholder: "Your aadhaar number",
              value: user.kycJson?.aadhaarNo || "",
              notEditable: true,
            },
            {
              title: "PAN Number",
              key: "panNo",
              placeholder: "Your pan number",
              value: user.kycJson?.panNo || "",
              notEditable: true,
            },
          ],
        }
      : {
          title: "KYC",
          icon: "bi:person-badge",
          hideExpand: true,
          accordionClick: () => {
            navigate("/kyc");
          },
        },
    {
      title: "Bank Details",
      icon: "fa-solid:credit-card",
      hideExpand: true,
      isExpanded: true,
      fields: [
        {
          title: "Bank Account",
          key: "bankAccount",
          placeholder: "Enter your bank account",
          value: user.bankAccount || "",
          notEditable: true,
        },
        {
          title: "IFSC code",
          key: "ifsc",
          placeholder: "Enter IFSC code",
          value: user.ifsc || "",
          notEditable: true,
        },
      ],
    },
    {
      title: "Support",
      icon: "fa-solid:phone",
      hideExpand: true,
      custom: (
        <div style={{ marginTop: 10 }}>
          <span className="bold" style={{ color: "white" }}>
            Write to us at:
          </span>
          <div className="flex" style={{ padding: "10px 0px", gap: "5px" }}>
            <span
              onClick={async () => {
                window.open("mailto:support@vaapas.in", "_blank");
                // await Linking.openURL("mailto:support@vaapas.in");
              }}
              style={{ color: "white", fontSize: 30 }}
            >
              <Iconify
                icon={"codicon:mail"}
                color={theme.color.primary}
                fontSize={30}
              />
              {/* <FontAwesomeIcon
                icon={"envelope-square"}
                color={theme.color.primary}
                size={30}
              /> */}
            </span>
            <span>{"   "}</span>
            <span
              onClick={async () => {
                window.open(
                  `whatsapp://send?text=${""}&phone=+916302315854`,
                  "_blank"
                );
              }}
            >
              <Iconify
                icon={"akar-icons:whatsapp-fill"}
                color={theme.color.primary}
                fontSize={25}
              />
              {/* <WHATSAPP_LOGO width={30} height={30} /> */}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Orders",
      icon: "fa-solid:box",
      hideExpand: true,
      accordionClick: () => {
        navigate("/orders");
      },
    },
    {
      title: "Invite your friends",
      icon: "fa6-solid:user-group",
      hideExpand: true,
      accordionClick: () => {
        const message = `Hi! Check out this amazing app with which we can invest in your favourite brands and get returns on every sale\nDownload the app: https://play.google.com/store/apps/details?id=com.caset.android&hl=en&gl=in`;
        const url = `whatsapp://send?text=${message}&phone`;
        window.open(url, "_blank");
      },
    },
    {
      title: "Privacy Policy",
      icon: "fa6-solid:file-lines",
      hideExpand: true,
      accordionClick: () => {
        window.open(
          "https://caset.notion.site/Privacy-Policy-d96f5298e92e4f219c6d9518fe9ad3ae",
          "_blank"
        );
      },
    },
    {
      title: "Terms of use",
      icon: "fa6-solid:file-lines",
      hideExpand: true,
      accordionClick: () => {
        window.open(
          "https://caset.notion.site/Terms-of-Use-2ee075c1f06d4f8a83f41bca8ceb6748",
          "_blank"
        );
      },
    },
    {
      title: "Delete Account",
      icon: "fa6-solid:user-xmark",
      hideExpand: true,
      isExpanded: false,
      custom: (
        <div>
          <div className={"flex"}>
            <span className="medium white">
              For account deletion and any other queries, please write to us on{" "}
              <a
                href={"mailto:support@vaapas.in"}
                style={{
                  textDecoration: "none",
                  color: theme.color.primary,
                }}
              >
                support@vaapas.in
              </a>
            </span>
          </div>
        </div>
      ),
    },

    {
      title: "Logout",
      icon: "fa6-solid:arrow-right-from-bracket",
      hideExpand: true,
      accordionClick: () => {
        signOut("https://vaapas.in");
      },
    },
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleFieldUpdate = (key: string, value: string) => {
    setIsLoading(true);
    authRequest("user", "PATCH", {
      [key]: value,
    })
      .then((data: User) => {
        // updateUserData(data);
        setIsLoading(false);
        // user[key] = value;
        setUser({ ...user, [key]: value });
      })
      .catch((err: any) => {
        console.warn(err.message);
        if (authData) updateUserData({ ...authData.user });
        setIsLoading(false);
      });
  };

  const getUserNameContent = () => {
    const icon = (
      <Iconify icon={"bxs:user"} fontSize={20} color={theme.color.primary} />
    );
    if (user && user.name) {
      const formattedName: string = user.name.replace(/\s\s/gi, " ");
      const nameParts = formattedName.split(" ");
      
      if (nameParts.length) {
        if (nameParts.length === 1) {
          return nameParts[0][0] || icon;
        }
        if (nameParts.length >= 2) {
          return nameParts[0][0] + nameParts[1][0];
        }
      }
    }

    return icon;
  };

  return (
    <>
      <Loader loading={isLoading} />
      <div
        className={css`
          display: flex;
          width: 100%;
          justify-content: flex-start;
        `}
      >
        <div
          className={css`
            height: ${isMobile ? screenHeight : "unset"};
            width: 100%;
            /* max-width: 500px; */
            overflow-y: ${isMobile ? "scroll" : "unset"};
            padding-bottom: 20px;
          `}
        >
          {isMobile ? (
            <>
              <div
                className="bold"
                style={{
                  color: "white",
                  fontSize: 24,
                  // paddingVertical: 20,
                  // paddingHorizontal: 20,
                  // padding: 20,
                  padding: "30px 16px",
                  paddingTop: 30,
                  paddingBottom: 30,
                }}
              >
                <div className="flex center gap">
                  <div
                    className={css`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    `}
                  >
                    <div>
                      <BackButton />
                    </div>
                    <div
                      className={css`
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        /* background-color: rgba(0, 223, 152, 0.419); */
                        backdrop-filter: blur(4px);
                        /* border: 1px solid ${theme.color.primary}; */
                        border: 1px solid white;

                        align-items: center;
                      `}
                    >
                      <div
                        className={
                          css`
                            color: ${theme.color.primary};
                            font-size: 18px;
                          ` + " bold"
                        }
                      >
                        {getUserNameContent()}
                      </div>
                    </div>
                  </div>
                  <div
                    className={css`
                      width: 0.6px;
                      background-color: rgba(255, 255, 255, 0.75);
                      transform: scaleY(0.9);
                      height: 60px;
                    `}
                  />
                  <span className="bold grow" style={{ fontSize: 24 }}>{`Hi${
                    " " + (user.name || "")
                  }!`}</span>
                </div>

                {user ? (
                  !(user.accountVerified || user.isKycVerified) ? (
                    <span
                      className="bold"
                      style={{
                        fontSize: 13,
                        display: "block",
                        color: "yellow",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/kyc");
                      }}
                    >
                      Complete your kyc
                    </span>
                  ) : null
                ) : null}
              </div>
              <div
                style={{
                  position: "relative",
                  padding: "0px 8px",
                  paddingBottom: 60,
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  className={css`
                    height: unset;
                    /* height: 450px; */
                    /* transform: scale(0.6); */
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 1rem;
                    justify-content: center;
                    /* padding-bottom: 20px; */
                    /* padding: 0px 20px; */
                  `}
                >
                  {isMobile
                    ? MobileProfileAccordionList.map((d, i) => {
                        const validTitles = ["KYC", "Bank Details"];
                        if (validTitles.indexOf(d.title) === -1) {
                          return null;
                        }
                        return (
                          <Accordion
                            title={d.title}
                            icon={d.icon}
                            titleColor={theme.color.light}
                            key={i}
                            addPaddingToChildren
                            accordionClick={d.accordionClick}
                            isExpanded={d.isExpanded}
                            hideExpand={d.hideExpand}
                          >
                            {d.fields ? (
                              <>
                                {d.fields.map((f, i) => {
                                  return (
                                    <EditInput
                                      key={i}
                                      title={f.title}
                                      value={f.value}
                                      updateKey={f.key}
                                      placeholder={f.placeholder || "Enter"}
                                      handleUpdate={(key, value) => {
                                        handleFieldUpdate(key, value);
                                      }}
                                      uneditable={f.notEditable}
                                    />
                                  );
                                })}
                              </>
                            ) : d.custom ? (
                              d.custom
                            ) : (
                              <span style={{ color: "white" }}>{d.title}</span>
                            )}
                          </Accordion>
                        );
                      })
                    : null}
                </div>

                {/* <p className="bold">Explore</p> */}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AccountInformation;
