import { css } from "@emotion/css";
import { useEffect, useMemo, useState } from "react";
import BottomBar from "../../components/BottomBar";
import Loader from "../../components/Loader";
import Logo from "../../components/Logo";
import { useAuth } from "../../context/auth";
import { Caset } from "../../interfaces/caset";
import theme from "../../util/theme";
import CasetItem from "./CasetItem";
import { useNavigate } from "react-router-dom";
import { screenHeight } from "../../layout/BaseLayout";
import Footer from "../../components/Footer";
import { useGlobal } from "../../context/global";
import Iconify from "../../components/Iconify";

const Explore = () => {
  const navigate = useNavigate();
  const { authRequest } = useAuth();
  const { isMobile } = useGlobal();
  const [casets, setCasets] = useState<Caset[] | null>(null);
  const [flatListCasets, setFlatListCasets] = useState<Caset[] | null>([]);
  const [searchString, setSearchString] = useState("");
  const [searchBounce, setSearchBounce] = useState<any>(undefined);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const getCasets = (s: string) => {
    // return;
    // if (!s) setIsRefreshing(true);
    setIsRefreshing(true);
    const promises = [];
    promises.push(
      authRequest("caset?s=" + s, "GET", {})
        .then((data: Caset[]) => {
          setCasets(data);
          // if (!s) setIsRefreshing(false);
          setIsRefreshing(false);

          //   moveToIndex(0);
        })
        .catch((err: any) => {
          setIsRefreshing(false);
          // if (!s) setIsRefreshing(false);p
        })
    );
    promises.push(
      new Promise<void>((s) => {
        setTimeout(() => {
          s();
        }, 400);
      })
    );

    Promise.all(promises)
      .then(() => {
        setIsRefreshing(false);
      })
      .catch((err) => {
        setIsRefreshing(false);
      });
  };

  useEffect(() => {
    getCasets(searchString);
  }, [searchString]);

  const renderExploreCasets = useMemo(() => {
    return (
      <div
        className={css`
          /* max-height: 300px; */
          /* overflow-x: scroll;

    height: 450px; */
          height: ${isMobile ? "unset" : "unset"};
          overflow-y: ${isMobile ? "scroll" : "unset"};
          transform: scale(0.9);
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          flex-grow: 1;
          /* flex: 70%; */
          /* width: 100%; */
          gap: 3rem;
        `}
      >
        {(casets || []).length === 0 && casets !== null ? (
          <div
            className={
              css`
                display: flex;
                flex-direction: column;
                min-height: 200px;
                justify-content: center;
                align-items: center;
                color: white;
              ` + " bold"
            }
          >
            <Iconify
              icon={"clarity:error-solid"}
              fontSize={60}
              color={theme.color.error}
            />{" "}
            No Casets Found
          </div>
        ) : null}
        {(casets || []).map((item) => {
          return <CasetItem key={item.id} item={item} />;
        })}
        {/* {(casets || []).map((item) => {
    return <CasetItem key={item.id} item={item} />;
  })}
  {(casets || []).map((item) => {
    return <CasetItem key={item.id} item={item} />;
  })}
  {(casets || []).map((item) => {
    return <CasetItem key={item.id} item={item} />;
  })}
  {(casets || []).map((item) => {
    return <CasetItem key={item.id} item={item} />;
  })} */}
      </div>
    );
  }, [casets, isMobile]);

  return (
    <>
      <Loader loading={isRefreshing} />
      <BottomBar />

      <div
        style={{
          // width: "calc(100vw - 32px)",
          height: isMobile ? screenHeight : "unset",
          // overflowY: "scroll",
          // position: "relative",
          paddingBottom: 60,
          padding: "0px 16px",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          overflowY: isMobile ? "scroll" : "unset",
        }}
      >
        {/* Logo section */}
        {isMobile ? (
          <div style={{ marginBottom: isMobile ? 0 : 20 }}>
            <Logo width={130} height={60} />
            {/* <div
              className="bold"
              style={{ color: "#CAE531", fontSize: 18, marginTop: -8 }}
            >
              marketplace
            </div> */}
          </div>
        ) : null}

        <div>
          <div
            className="bold"
            style={{
              color: "white",
              fontSize: isMobile ? "1.7rem" : "2.4rem",
              // display: isMobile ? "none" : "unset",
            }}
          >
            Explore Brands
          </div>
        </div>
        {/* Search section */}
        <div
          className={css`
            padding: 5px 10px;
            border: 1px solid white;
            border-radius: 6px;
          `}
        >
          <input
            type={"text"}
            onChange={(e) => {
              //   props.onChange(e.target.value);
              setSearchString(e.target.value);
            }}
            // value={props.value}
            value={searchString}
            className={
              css`
                background-color: transparent;
                border-color: transparent;
                /* font-weight: 600; */
                width: 100%;
                /* font-family: "DMSans"; */
                color: white;
                font-size: 20px;
                padding: 3px 10px;

                flex-grow: 1;

                &:focus {
                  outline: none;
                }

                &::placeholder {
                  color: #aaaaaa;
                }
              ` + " bold"
            }
            placeholder={"Search for business"}
          />
        </div>

        {/* List of casets */}

        {isMobile ? (
          <div
            className={
              "flex grow " +
              css`
                overflow-y: scroll;
                padding-bottom: ${(document.getElementById("bottom_nav")
                  ?.scrollHeight || 40) + "px"};
              `
            }
          >
            {renderExploreCasets}
          </div>
        ) : (
          <div
            className="flex"
            style={{ width: "100%", justifyContent: "center" }}
          >
            {/* <div style={{ flex: "30%" }}></div> */}
            {renderExploreCasets}
          </div>
        )}
        {/* <p className="bold">Explore</p> */}
      </div>
    </>
  );
};

export default Explore;
