/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import CustomButton from "../components/CustomButton";
import Iconify from "../components/Iconify";
import Loader from "../components/Loader";
import { useAuth } from "../context/auth";
import { useGlobal } from "../context/global";
import { User } from "../interfaces/user";
import theme from "../util/theme";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
interface Props {
  style?: { [key: string]: any };
  // navigation: any;
}

// {
//     bankAccount: '',
//     ifsc: '',
//     upiAddress: '',
//   }

const EmailVerification = (props: Props) => {
  const navigate = useNavigate();
  const { authData, authRequest, updateUserData } = useAuth();
  const { showToast } = useGlobal();
  const user = authData?.user || {
    email: "",
    name: "",
    emailVerified: false,
    kycJson: {
      aadhaarNo: "",
      panNo: "",
    },
  };

  const [userName, setUserName] = useState((user && user.name) || "");

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<string>(user.email || "");
  const [emailOTPSent, setEmailOTPSent] = useState(false);
  const [emailOTP, setEmailOTP] = useState("");
  const [emailOTPSentTime, setEmailOTPSentTime] = useState<Date | null>(null);
  const [kycData, setKycData] = useState({
    aadhaarNo: "",
    panNo: "",
  });
  const [paymentInfoData, setPaymentInfoData] = useState({
    bankAccount: "",
    ifsc: "",
    upiAddress: "",
  });

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (user && user.kycJson && user) {
      setEmail(user.email);
      setUserName(user.name);
      // setPaymentInfoData({
      //   bankAccount: user.bankAccount,
      //   ifsc: user.ifsc,
      //   upiAddress: user.upiAddress,
      // });
      setKycData({
        aadhaarNo: user?.kycJson.aadhaarNo || "",
        panNo: user?.kycJson.panNo || "",
      });
    }
  }, [user]);

  const sendEmailOTP = () => {
    if (!email || !userName) return toast.error("Name and email is required");
    setIsLoading(true);
    authRequest("user/email/sendOTP", "POST", {
      email: email.trim(),
      name: userName,
    })
      .then(async () => {
        setIsLoading(false);
        // setEmailOTPSent(true);
        // setEmailOTPSentTime(new Date());
        await getMe();
        navigate("/explore");
      })
      .catch((err: any) => {
        setIsLoading(false);
        showToast(err.message || "Error");
      });
  };

  const getMe = () => {
    setIsLoading(true);
    return authRequest("user/me", "GET", {})
      .then((data: User) => {
        setIsLoading(false);
        updateUserData(data);
      })
      .catch((err: any) => {
        setIsLoading(false);
        showToast(err.message || "Error");
      });
  };

  const verifyEmailOTP = () => {
    setIsLoading(true);
    authRequest("user/email/verifyOTP", "POST", {
      email: email,
      OTP: emailOTP,
      name: userName,
    })
      .then(async () => {
        setIsLoading(false);
        await getMe();
        navigate("/explore");
      })
      .catch(async (err: any) => {
        await setIsLoading(false);
        await getMe();

        showToast(err.message || "Error");
      });
  };

  const saveAndVerifyPaymentDetails = () => {
    setIsLoading(true);
    authRequest("user/payment_details/verify", "POST", paymentInfoData)
      .then(async () => {
        setIsLoading(false);
        getMe();
      })
      .catch(async (err: any) => {
        setIsLoading(false);
        getMe();
        showToast(err.message || "Error");
      });
  };

  const saveAndVerifyKyc = () => {
    setIsLoading(true);
    authRequest("user/kyc/verify", "POST", kycData)
      .then(async () => {
        setIsLoading(false);
        getMe();
      })
      .catch(async (err: any) => {
        setIsLoading(false);
        getMe();
        showToast(err.message || "Error");
      });
  };

  const InputField = (
    title = "",
    value = "",
    placeholder = "",
    onChange: ((text: string) => void) | undefined = undefined,
    endAdornment: JSX.Element | undefined = undefined,
    disable = false,
    startAdornment: JSX.Element | undefined = undefined
  ) => {
    return (
      <>
        {title ? (
          <div
            className="bold"
            style={{
              ...{ color: theme.color.primary, fontSize: 18, marginBottom: 8 },
            }}
          >
            {title}
          </div>
        ) : null}
        <div
          className="flex center"
          style={{
            height: 48,
            borderRadius: 4,
            backgroundColor: "white",
            padding: "4px 10px",
            borderWidth: 1,
            marginTop: 4,
            marginBottom: 8,
          }}
        >
          {startAdornment || null}
          <input
            className={`bold grow ${css`
              border-color: transparent;
              &:focus {
                outline: none;
              }
            `}`}
            style={{
              color: "black",
              fontSize: 16,
              // backgroundColor: 'white',
              // spanAlignVertical: 'center',
              height: 40,
            }}
            onChange={(e) => {
              if (onChange) onChange(e.target.value);
            }}
            value={value}
            disabled={disable}
            // editable={!disable}
            // onChangespan={setChangedValue}
            // placeholder={props.placeholder || ''}
            placeholder={placeholder}
            // placeholderspanColor={"#BFBFBF"}
          />
          {endAdornment || null}
        </div>
      </>
    );
  };

  if (user && user.emailVerified) {
    navigate("/explore");
  }

  if (!user) {
    return (
      <div
        style={{
          backgroundColor: theme.color.primary_bg_2,
          width: "100%",
          height: "100%",
        }}
      />
    );
  }

  return (
    <>
      <Loader loading={isLoading} />
      <div
        style={{
          position: "relative",
          paddingBottom: 60,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "2rem",
        }}
      >
        <div>
          <span
            className="bold"
            style={{ color: theme.color.light, fontSize: 24, bottom: 8 }}
          >
            {!user.emailVerified || !user?.accountVerified || !user?.vpaVerified
              ? "Personal Details"
              : "KYC"}
          </span>
        </div>
        <div style={{ paddingTop: 4 }}>
          {user ? (
            <>
              {!user.emailVerified ? (
                <>
                  {/* Email Verification */}
                  <div>
                    {InputField(
                      "Name",
                      userName,
                      "Enter username",
                      (span) => {
                        setUserName(span);
                      },
                      undefined
                    )}
                  </div>
                  <div>
                    {InputField(
                      "Email",
                      email,
                      "Enter email",
                      (span) => {
                        setEmail(span);
                      },
                      user.emailVerified ? (
                        <Iconify
                          fontSize={20}
                          icon={"bxs:check-circle"}
                          color={"green"}
                        />
                      ) : (
                        <></>
                      ),
                      user.emailVerified
                    )}

                    {emailOTPSent && !user.emailVerified ? (
                      <>
                        {InputField("", emailOTP, "Enter OTP", (span) => {
                          setEmailOTP(span);
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                    {!user.emailVerified ? (
                      <CustomButton
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          if (emailOTPSent) {
                            // setEmailOTPSent(false);
                            verifyEmailOTP();
                          } else {
                            sendEmailOTP();
                            // setTimeout(() => {
                            //   setEmailOTPSent(false);
                            // }, 2000);
                          }
                        }}
                        // disabled={emailOTPSent}
                        // text={`${emailOTPSent ? "Verify" : "Send"} OTP`}
                        text={"Save"}
                      />
                    ) : null}
                  </div>
                </>
              ) : (
                <>
                  {/* Payment Details */}
                  {/* <div style={{marginTop: 20}}>
                    <span
                      style={[
                        GLOBAL_STYLES.bold,
                        {color: 'white', fontSize: 24, marginBottom: 8},
                      ]}>
                      Payment Details
                    </span>
                    {InputField(
                      'Account Number',
                      paymentInfoData.bankAccount,
                      'Enter account number',
                      span => {
                        setPaymentInfoData({
                          ...paymentInfoData,
                          ...{bankAccount: span},
                        });
                      },
                      user.accountVerified ? (
                        <FontAwesomeIcon
                          size={20}
                          icon={'check'}
                          color={'green'}
                        />
                      ) : (
                        <></>
                      ),
                      user.accountVerified,
                    )}
                    {InputField(
                      'IFSC Number',
                      paymentInfoData.ifsc,
                      'Enter IFSC number',
                      span => {
                        setPaymentInfoData({
                          ...paymentInfoData,
                          ...{ifsc: span},
                        });
                      },
                      user.accountVerified ? (
                        <FontAwesomeIcon
                          size={20}
                          icon={'check'}
                          color={'green'}
                        />
                      ) : (
                        <></>
                      ),
                      user.accountVerified,
                    )}
                    {InputField(
                      'UPI Address',
                      paymentInfoData.upiAddress,
                      'Enter UPI address',
                      span => {
                        setPaymentInfoData({
                          ...paymentInfoData,
                          ...{upiAddress: span},
                        });
                      },
                      user.accountVerified ? (
                        <FontAwesomeIcon
                          size={20}
                          icon={'check'}
                          color={'green'}
                        />
                      ) : (
                        <></>
                      ),
                      user.vpaVerified,
                    )}
                    <CustomButton
                      span="Save Payment Details"
                      onClick={() => {
                        saveAndVerifyPaymentDetails();
                      }}
                    />
                  </div> */}
                  {/* KYC  */}
                  {/* <div>
                    {InputField(
                      'Aadhaar No.',
                      kycData.aadhaarNo,
                      'Enter Aadhaar Number',
                      span => {
                        setKycData({
                          ...kycData,
                          ...{aadhaarNo: span},
                        });
                      },
                      user.isKycVerified ? (
                        <FontAwesomeIcon
                          size={20}
                          icon={'check'}
                          color={'green'}
                        />
                      ) : (
                        <></>
                      ),
                    )}
                    {InputField(
                      'PAN No.',
                      kycData.panNo,
                      'Enter PAN Number',
                      span => {
                        setKycData({
                          ...kycData,
                          ...{panNo: span},
                        });
                      },
                      user.isKycVerified ? (
                        <FontAwesomeIcon
                          size={20}
                          icon={'check'}
                          color={'green'}
                        />
                      ) : (
                        <></>
                      ),
                    )}
                    <CustomButton
                      span="Save"
                      onClick={() => {
                        // saveAndVerifyPaymentDetails();
                        saveAndVerifyKyc();
                      }}
                    />
                  </div> */}
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
