import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import { useAuth } from "../context/auth";
import { useGlobal } from "../context/global";
import theme from "../util/theme";
import { rootStyle } from "./Login";
import { css } from "@emotion/css";
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

const ConfirmOTP = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { request, signIn } = useAuth();
  const { showToast, isMobile } = useGlobal();
  const state: any = location.state || { phoneNo: "" };
  const phoneNumber = state.phoneNo;
  const [otp, setOtp] = useState<string>("");
  const [requestFetching, setRequestFetching] = useState(false);

  const handleLogin = () => {
    // vaapasNavigation(props.navigation, SCREEN_MAP.WAIT_LIST, {});
    // return;
    const OTP = otp;
    if (OTP.length !== 6) {
      showToast("Invalid OTP");
      return;
    }
    setRequestFetching(true);
    request("auth/verifyOTP", "POST", {
      phoneNumber: phoneNumber,
      OTP: OTP,
    })
      .then((data: { token: string }) => {
        setRequestFetching(false);
        let newData = {
          ...data,
        };
        signIn(newData);
      })
      .catch((err: any) => {
        setRequestFetching(false);
        showToast(err.message || "Please try again after sometime");
      });
  };

  return (
    <div className={rootStyle} style={{ justifyContent: "flex-start" }}>
      <Loader loading={requestFetching} />
      <div
        style={{
          display: "flex",
          gap: "1.35rem",
          flexDirection: "column",
          padding: "2rem 1rem",
          maxWidth: "100vw",
          width: isMobile ? "100vw" : "500px",
          flexGrow: 1,
          justifyContent: isMobile ? "unset" : "center",
          alignSelf: isMobile ? "unset" : "center",
        }}
      >
        {!isMobile ? (
          <div
            className={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
            `}
          >
            {<Logo width={250} height={250} />}
          </div>
        ) : (
          ""
        )}
        {/* Confirm Otp section */}
        <div className="bold" style={{ fontSize: 20 }}>
          Confirm your number
        </div>
        {/* <p className="bold">{state.phoneNo}</p> */}
        <CustomInput
          inputStyle={{
            width: 270,
          }}
          value={otp}
          placeholder={"Enter OTP"}
          onChange={(text) => {
            setOtp(text);
          }}
          onEnter={handleLogin}
        />
        <div className="flex bold">
          <div>
            OTP sent to{" "}
            <span style={{ color: theme.color.primary }}>
              +91 {state.phoneNo}
            </span>
          </div>
          <div className="grow" />
          <div
            onClick={() => {
              navigate(-1);
            }}
          >
            Change
          </div>
        </div>
        <CustomButton
          text="Verify"
          style={{
            marginTop: 10,
          }}
          onClick={() => {
            // showToast("Test");
            handleLogin();
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmOTP;
